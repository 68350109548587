import React from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

interface Props {
  navigateToEdit: () => void;
}

const EditButton: React.FC<Props> = ({ navigateToEdit }) => {
  return <ModeEditOutlineOutlinedIcon
  sx={{ pl: '3px', cursor: 'pointer' }} onClick={navigateToEdit} />;
};

export default EditButton;
