import {
  IconButton,
  Typography,
  TextField,
  Grid,
  Button,
  Modal,
  Box,
} from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CircularProgress } from '@mui/material';
import { ResetPassWordValidationScheme } from '../utils/validations';
import { useDispatch } from 'react-redux';
import { resetPasswordAction } from '../store/auth/action';
import { useSelector } from 'react-redux';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

export default function BasicModal(props: any) {
  const { handleClose, openModal } = props;
  const dispatch = useDispatch();
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    setNewPassWord: '',
  };
  const passwordLoader = useSelector((state: any) => state?.auth?.loading);
  const handleSubmitResetPassword = (values: any) => {
    dispatch(
      resetPasswordAction({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
    );
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitResetPassword}
          validationSchema={ResetPassWordValidationScheme}
        >
          {(props) => (
            <Form>
              <Box sx={style}>
                <div>
                  <IconButton
                    aria-label="close"
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  ></IconButton>
                </div>
                <Typography
                  variant="h5"
                  sx={{ color: '#9155FD', marginBottom: '25px' }}
                >
                  Reset Password
                </Typography>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="oldPassword"
                      type="password"
                      label="Enter your old password"
                      error={props?.touched?.oldPassword && Boolean(props?.errors?.oldPassword)}
                      helperText={<ErrorMessage name="oldPassword" />}
                      sx={{
                        color: 'red',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: props?.touched?.oldPassword && props?.errors?.oldPassword ? 'red' : undefined,
                        },
                        '& .MuiFormHelperText-root': {
                          color: 'red',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="newPassword"
                      type="password"
                      label="Enter your New password"
                      error={props?.touched?.newPassword && Boolean(props?.errors?.newPassword)}
                      helperText={<ErrorMessage name="newPassword" />}
                      sx={{
                        color: 'red','& .MuiOutlinedInput-notchedOutline': {
                          borderColor: props?.touched?.newPassword && props?.errors?.newPassword ? 'red' : undefined,
                        },
                        '& .MuiFormHelperText-root': {
                          color: 'red',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="setNewPassWord"
                      type="password"
                      label="Re-Enter your New password"
                      error={props?.touched?.setNewPassWord && Boolean(props?.errors?.setNewPassWord)}
                      helperText={<ErrorMessage name="setNewPassWord" />}
                      sx={{
                        color: 'red','& .MuiOutlinedInput-notchedOutline': {
                          borderColor: props?.touched?.setNewPassWord && props?.errors?.setNewPassWord ? 'red' : undefined,
                        }, 
                        '& .MuiFormHelperText-root': {
                          color: 'red',
                        },
                      }}
                    />
                    <Box
                      display="flex"
                      columnGap={2}
                      sx={{ mt: 2, justifyContent: 'center' }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          placeSelf: 'center',
                          backgroundColor: '#9155FD !important',
                        }}
                        type="submit"
                      >
                        {passwordLoader ? (
                          <CircularProgress
                            color="inherit"
                            style={{
                              color: '#fff',
                              height: '25px',
                              width: '25px',
                            }}
                          />
                        ) : (
                          'Submit'
                        )}
                      </Button>

                      <Button
                        variant="outlined"
                        sx={{ placeSelf: 'center' }}
                        onClick={handleClose}
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
