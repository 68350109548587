import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { getMesurementList } from '../store/mesurement/action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import RowAndColumnSpacing from './MeasurementSearchdata';
import AddEditMeasurement from '../pages/Measurement/AddEditMeasurement';

export default function MeasurementSearchModal(props: any) {

  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.mesurementList?.loading);
  const mesurementList = useSelector(
    (state: any) => state.mesurementList?.measurementData?.data
  );
  const [showDetails, setShowDetails] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [searchData, setSearchData] = React.useState('');
  const [addData, setAddData] = React.useState(false);
  const [isAddModal, setIsModal] = React.useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = React.useState(true);
  const isLoader = useSelector(
    (state: any) => state?.mesurementList.isLoading
  );
  React.useEffect(() => {
    if (!isLoader) {
      setIsModal(false);
      setAddData(true)

    }
  }, [!isLoader]);

  React.useEffect(() => {
    if (!props.openSearch ) {
      setSearchData('');
    }
  }, [props.openSearch, props.handleCloseSearch]);
  React.useEffect(() => {
    if (searchData && !isLoader) {
      setSearchData('');
    }
  }, [props.openSearch,!isLoader]);

  React.useEffect(() => {
    if (!loading && searchData.length !== 0 && mesurementList.length !== 0) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [!loading, searchData?.length]);
  return (
    <React.Fragment>
      <Dialog
         open={props.openSearch}
         onClose={props.handleCloseSearch}
         PaperProps={{
           component: 'form',
           onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
             event.preventDefault();
             const formData = new FormData(event.currentTarget);
             const formJson = Object.fromEntries((formData as any).entries());
             const searchTerm = formJson.number;
             if (formJson.number.length >= 10) {
               dispatch(getMesurementList({ searchTerm }));
               setSearchData(searchTerm);
               setValidPhoneNumber(true);
               setSearched(true);
             } else {
               setValidPhoneNumber(false); 
             }
           },
         }}
      >


        <Box
          sx={{
            width: 500,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DialogContent sx={{ position: 'relative' }}>
            <IconButton
              aria-label='close'
              onClick={props.handleCloseSearch}
              sx={{
                position: 'absolute',
                right: 12,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 100,
                marginTop: 4,
              }}
            >
              <DialogContentText>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'start',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  Search for customer measurement:
                </Typography>
              </DialogContentText>
              <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems:'center' }}>
                <Box sx={{ width: '78%' }}>
                  <TextField
                    autoFocus
                    name='number'
                    margin='dense'
                    label='Contact Number'
                    type='number'
                    fullWidth
                    error={!validPhoneNumber} 
                    helperText={!validPhoneNumber ? "Please enter a valid phone number" : ""} 
                  />
                </Box>
                <Box sx={{ width: '20%',...(validPhoneNumber ? { textAlign: 'center' } : { marginBottom: '15px' })}}>
                  {!loading ? (
                    <Button
                      variant='contained'
                      type='submit'
                      sx={{ backgroundColor: '#9155FD !important' }}
                    >
                      Search
                    </Button>
                  ) : (
                    <Button size='small' type='submit'>
                      <CircularProgress
                        style={{
                          color: '#9155FD',
                          height: '25px',
                          width: '25px',
                        }}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            {searched && !loading && mesurementList.length === 0 && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                No measurement data found.
              </Typography>
            )}
            {showDetails && (
              <Container sx={{ marginTop: 2, ml: '-5%' }}>
                <RowAndColumnSpacing
                  mesurementList={mesurementList}
                  handleCloseSearch={props.handleCloseSearch}
                  handleSubmitCallback={props.handleSubmitCallback}
                  addData={addData}
                  setAddData={setAddData}
                />
              </Container>
            )}
            {!loading && !showDetails && (
              <Button
                size='small'
                variant='outlined'
                sx={{ fontSize: 15, fontWeight: 'bold', mt: validPhoneNumber ? 2 : 4 }}
                style={{ borderColor: '#9155FD', color: '#9155FD' }}
                autoFocus
                onClick={() => {
                  setIsModal(true);
                  props.handleCloseSearch(true)
                }}
              >
                  Add New Measurement
              </Button>
            )}
          </DialogContent>
        </Box>
       
      </Dialog>
      <AddEditMeasurement
          open={isAddModal}
          setSearchData={setSearchData}
          handleSubmitCallback={props.handleSubmitCallback}
          onClose={() => {
            setIsModal(false);
          }}
          
        />
    </React.Fragment>
  );
}
