import {
  IconButton,
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { resetPasswordAction } from '../../store/auth/action';
import { useEffect } from 'react';
import { ResetPassWordValidationScheme } from '../../utils/validations';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

const ResetUserPassword = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.data);
  const navigate = useNavigate();
  const passwordLoader = useSelector((state: any) => state?.auth?.loading);
  const isPasswordChanged = useSelector(
    (state: any) => state.auth.data.isPasswordChanged
  );
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    setNewPassWord: '',
  };
  useEffect(() => {
    if (isPasswordChanged) {
      navigate('/dashboard');
    }
  });
  const handleSubmitResetPassword = (values: any) => {
    dispatch(
      resetPasswordAction({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
    );
  };

  useEffect(()=>{
    if(window.location.pathname === "/reset/password"){
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event){
         window.history.pushState(null, document.title,  window.location.href);
      });
    }    
      },[])

  return (
    <div>
       {user.role !== 1 && (
      <Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmitResetPassword}
          validationSchema={ResetPassWordValidationScheme}
        >
          {(props) => (
            <Form>
              <Box sx={style}>
                <div>
                  <IconButton
                    aria-label='close'
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  ></IconButton>
                </div>
                <Typography
                  variant='h5'
                  sx={{ color: '#9155FD', marginBottom: '25px' }}
                >
                  Reset Password
                </Typography>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                      as={TextField}
                      fullWidth
                      name='oldPassword'
                      type='password'
                      label='Enter your old password'
                      helperText={<ErrorMessage name='oldPassword' />}
                      sx={{'& .MuiFormHelperText-root': {
                        color:'red',
                       }, }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                      as={TextField}
                      fullWidth
                      name='newPassword'
                      type='password'
                      label='Enter your New password'
                      helperText={<ErrorMessage name='newPassword' />}
                      sx={{ 
                      '& .MuiFormHelperText-root': {
                        color:'red',
                       },
                    }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Field
                      as={TextField}
                      fullWidth
                      name='setNewPassWord'
                      type='password'
                      label='Re-Enter your New password'
                      helperText={<ErrorMessage name='setNewPassWord' />}
                      sx={{
                      '& .MuiFormHelperText-root': {
                        color:'red',
                       },
                    }}
                    />
                    <Box
                      display='flex'
                      columnGap={4}
                      sx={{ mt: 2, justifyContent: 'center' }}
                    >
                      <Button
                        variant='contained'
                        sx={{ placeSelf: 'center', backgroundColor: '#9155FD !important' }}
                        type='submit'
                      >
                        {passwordLoader ? (
                        <CircularProgress
                          color='inherit'
                          style={{
                            color: '#fff',
                            height: '25px',
                            width: '25px',
                          }}
                        />
                      ) : (
                        'Submit'
                      )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
       )}
    </div>
  );
};

export default ResetUserPassword;
