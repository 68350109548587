import {
  SEND_ENVOICE_BEGIN,
  SEND_ENVOICE_FAILURE,
  SEND_ENVOICE_SUCCESS
} from '../constant';

const initialState: any = {
  loading: false,
  data: [],
  error: null
};

const envoiceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SEND_ENVOICE_BEGIN:
      return {
        ...state,
        loading: true
      };
    case SEND_ENVOICE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case SEND_ENVOICE_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default envoiceReducer;
