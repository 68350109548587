import {
  GET_ORDER_BEGIN,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  IS_LOADING
} from '../constant';

const initialState: any = {
  loading: false,
  orderListData: [],
  error: null,
  isLoading: false
};

const orderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ORDER_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_ORDER_SUCCESS:
      return { ...state, loading: false, orderListData:action.payload};
    case GET_ORDER_FAILURE:
      return { ...state,loading: false, error: action.payload };
    case IS_LOADING:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default orderReducer;
