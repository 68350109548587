 import { apiClient, apiClientOrder } from './apiClient';

export const loginUser = async (data: any) => {
  const config = {
    method: 'POST',
    url: 'user/login',
    data,
  };
  const res = await apiClient(config);
  return res;
};
export const forgotPassword = async (data: any) => {
  const config = {
    method: 'PUT',
    url: 'user/forgot-password',
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const resetPassword = async (data: any) => {
  const config = {
    method: 'PUT',
    url: 'user/reset-password',
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const getShop = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `shop/list?offset=${payload.page - 1}&limit=${
      payload.rowsPerPage
    }&search=${payload.searchTerm}`,
  };
  const res = await apiClient(config);
  return res;
};

export const addShop = async (data: any) => {
  const config = {
    method: 'POST',
    url: 'shop/add',
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const editShop = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `shop/edit/${data.id}`,
    data: data.editData,
  };
  const res = await apiClient(config);
  return res;
};

export const editShopStatus = async (data: any) => {
  const config = {
    method: 'PUT',
    url: `shop/status?shopId=${data.shopId}`,
    data:{status:data.status},
  };
  const res = await apiClientOrder(config);
  return res;
};

export const deleteShop = async (data: any) => {
  const config = {
    method: 'DELETE',
    url: `shop/delete/${data?.id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getUsersApi = async () => {
  const config = {
    method: 'GET',
    url: `user/profile`,
  };
  const res = await apiClient(config);
  return res;
};

export const addUserApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `shop/add`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const editUserApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `user/edit`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteUserApi = async ({ id }: { id: string }) => {
  const config = {
    method: 'DELETE',
    url: `shop/delete/${id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getOrderApi = async (payload: any) => {

  const config = {
    method: 'GET',
    url: `order/list?offset=${payload.page - 1}&limit=${
      payload.rowsPerPage
    }&search=${payload.searchTerm}&paymentMode=${payload.paymentMode}&status=${payload.status}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const addOrderApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `order/add`,
    data,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const editOrderApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `order/edit/${data?._id}`,
    data:data.editData,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const editOrderStatusApi = async (data: any) => {
  const config = {
    method: 'PUT',
    url: `order/status?orderId=${data?.orderId}`,
    data: { status: data.status },
  };
  const res = await apiClientOrder(config);
  return res;
};

export const deleteOrder = async (data: any) => {
  const config = {
    method: 'DELETE',
    url: `order/delete/${data.id}`,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const getTransactionApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `transaction/list/${payload.id}?per_page=${payload.rowsPerPage}&current_page=${payload.page}`,
  };
  const res = await apiClient(config);
  return res;
};

export const addTransactionApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `transaction/add`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const editTransactionApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `transaction/edit/${data?._id}`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteTransaction = async (data: any) => {
  const config = {
    method: 'DELETE',
    url: `transaction/delete/${data?.id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getEmployeesApi = async (payload: any) => {

  const config = {
    method: 'GET',
    url: `shop/employee/list?offset=${payload.page - 1}&limit=${
      payload.rowsPerPage
    }&search=${payload.searchTerm}&paymentMode=${payload.paymentMode}&status=${payload.status}`,
  };
  const res = await apiClient(config);
  return res;
};

export const addEmployeeApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `shop/employee/add`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const editEmployeeApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `shop/employee/edit/${data.id}`,
    data: data.emp,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteEmployeeApi = async ({ id }: { id: string }) => {
  const config = {
    method: 'DELETE',
    url: `shop/employee/delete/${id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const editEmployeeStatusApi = async (item: any) => {
  const config = {
    method: 'PUT',
    url: `shop/employee/status?employeeId=${item.id}`,
    data: { status: item.status },
  };
  const res = await apiClientOrder(config);
  return res;
};

export const sendEnvoiceApi = async (item: any) => {
  const config = {
    method: 'POST',
    url: `order/invoice/download`,
    data: item,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const addExpenseApi = async (item: any) => {
  const config = {
    method: 'POST',
    url: `order/expense`,
    data: item,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const getExpenseApi = async (item: any) => {
  const config = {
    method: 'GET',
    url: `order/expense/${item}`,
    data: item,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const getTechnicianApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `shop/technician/list?offset=${payload.page - 1}&limit=${
      payload.rowsPerPage
    }&search=${payload.searchTerm}`,
  };
  const res = await apiClient(config);
  return res;
};

export const addTechnicianApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `shop/technician/create`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const editTechnicianApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `shop/technician/edit/${data.id}`,
    data: data.technician,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteTechnicianApi = async ({ id }: { id: string }) => {
  const config = {
    method: 'DELETE',
    url: `shop/technician/delete/${id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const editTechnicianStatusApi = async (item: any) => {
  const config = {
    method: 'PUT',
    url: `shop/technician/status/${item.id}`,
    data: { status: item.status },
  };
  const res = await apiClientOrder(config);
  return res;
};

export const getTechnicianNamesApi = async () => {
  const config = {
    method: 'GET',
    url: `shop/technician/names`,
  };
  const res = await apiClient(config);
  return res;
};

export const getExpenseOptionApi = async () => {
  const config = {
    method: 'GET',
    url: `order/expense_options`,
  };
  const res = await apiClient(config);
  return res;
};

export const getExpenseListApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `shop/expense/list?offset=${payload.page - 1}&limit=${
      payload.rowsPerPage
    }&search=${payload.searchTerm}&minExpense =${payload.minExpense}&maxExpense=${payload.maxExpense}&status=${payload.status}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const changeExpenseStatus = async (data: any) => {
  const config = {
    method: 'PUT',
    url: `order/expense/status`,
    data,
  };
  const res = await apiClientOrder(config);
  return res;
};

export const addCustomerPaymentApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `order/customer/payment`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const getOrderDetail = async (data: any) => {

  const config = {
    method: 'GET',
    url: `order/details/${data?._id}`,
    data,
  };
  const res = await apiClientOrder(config);
  return res;
}

export const addPaymentApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `order/customer/payment`,
    data,
  };
  const res = await apiClientOrder(config);
  return res;
};


export const editPaymentApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `order/customer/payment/edit`,
    data: data,
  };
  const res = await apiClient(config);
  return res;
};

export const deletePaymentApi = async ({ id }: { id: string }) => {
  const config = {
    method: 'DELETE',
    url: `order/customer/payment/${id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const addTechnicianPaymentApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `order/technician/payment`,
    data,
  };
  const res = await apiClientOrder(config);
  return res;
};
export const userDasboardApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `user/dashboard`,
    data,
  };
  const res = await apiClientOrder(config);
  return res;
};


export const editTechnicianPaymentApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `order/technician/payment/edit`,
    data: data,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteTechnicianPaymentApi = async ({ id }: { id: string }) => {
  const config = {
    method: 'DELETE',
    url: `order/technician/payment/${id}`,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteOrderExpenseApi = async ({ id }: { id: string }) => {
  const config = {
    method: 'DELETE',
    url: `order/expense/delete/${id}`,
  };
  const res = await apiClient(config);
  return res;
};


export const deleteImageApi=async(data:any)=>{
  const config = {
    method: 'DELETE',
    url: `user/profile-pic`,
    data:data
  };
  const res = await apiClient(config);
  return res;
}


export const deleteOrderImageApi=async(data:any)=>{
  const config = {
    method: 'DELETE',
    url: `order/image/delete`,
    data:data
  };
  const res = await apiClient(config);
  return res;
}


export const deleteShopImageApi=async(data:any)=>{
  const config = {
    method: 'DELETE',
    url: `shop/shop-logo`,
    data:data
  };
  const res = await apiClient(config);
  return res;
}

export const addMeasurementApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `measurement/add`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const getMesurementDetail = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `measurement/list?offset=${payload.page - 1}&limit=${
      payload.rowsPerPage
    }&search=${payload.searchTerm}`
  };
  const res = await apiClientOrder(config);
  return res;
}

export const editMeasurementApi = async (data: any) => {
  const config = {
    method: 'PATCH',
    url: `measurement/edit`,
    data: data,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteMeasurementApi = async (data:any) => {
  const config = {
    method: 'DELETE',
    url: `measurement/delete`,
    data:data
  };
  const res = await apiClient(config);
  return res;
};




