import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Sidebar from '../../components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '@mui/material';
import { getExpenseList } from '../../store/expenses/action';
import SearchBar from '../../components/SearchBar';
import ExpenseListTable from './TableItem';
import FilterModal from '../../components/FilterModal';

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});

const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA'
});

const SearchBarWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'start',
    marginTop: '10px',
    justifyContent: 'space-between'  },
});

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  marginTop: '12px',
  fontWeight: 'bold',
});

const expenseHeader = [
  'Order ID',
  'Technician Name',
  'Description',
  'Amount (₹)',
  'Advance (₹)',
  'Remaining (₹)',
  'Delivery Date',
  'Status',
];

const ExpenseList = () => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dispatch = useDispatch();
  const orderDataPagination = useSelector(
    (state: any) => state?.expenseList?.expenseData
  );
  const auth = useSelector((state: any) => state.auth);
  const expenseList = useSelector((state: any) => state?.expenseList);

  const count = Math.ceil(orderDataPagination?.total / 10);
  const expenseLoading = expenseList?.loading;

  
 

  const handleChange = (event: any, value: any) => {
    setPage(value);
  };
  const getExpense = (searchTerm: string): any => {
    setPage(1);
    dispatch(
      getExpenseList({ id: auth.data._id, page: 1, searchTerm, rowsPerPage: 10 })
    );
    setSearchTerm(searchTerm);
  };
  useEffect(() => {
if(expenseList?.expenseData?.length===0){
    dispatch(
      getExpenseList({
        id: auth.data._id, page, searchTerm, rowsPerPage: 10, status: '', minExpense: '', maxExpense: '', fromDate: '',
        toDate: '',
      })
    );
 }
  }, [page]);

  

  const handleFilter = (filterData: any) => {
    let fromDate = filterData.fromDate;
    let toDate = filterData.toDate;

    if (filterData.fromDate === null || filterData.toDate === null) {
      dispatch(
        getExpenseList({
          id: auth.data._id,
          page,
          searchTerm,
          rowsPerPage: 5,
          status: filterData.status,
          minExpense: filterData.minExpense,
          maxExpense: filterData.maxExpense,
          fromDate: fromDate,
          toDate: toDate,
        })
      );
    } else {
      dispatch(
        getExpenseList({
          id: auth.data._id,
          page,
          searchTerm,
          rowsPerPage: 5,
          status: filterData.status,
          minExpense: filterData.minExpense,
          maxExpense: filterData.maxExpense,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
        })
      );
    }
  };

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Typography
          variant="h5"
          sx={{ color: '#9155FD', fontWeight: 'bold' }}
        >
          Expenses List
        </Typography>
        <SearchBarWrapper
        >
          <SearchBar onSearch={getExpense} />
          <FilterModal parentCallback={handleFilter} />
        </SearchBarWrapper>
        <ExpenseListTable
          expenseList={expenseList.expenseData.data}
          expenseListHeader={expenseHeader}
          expenseDataLoader={expenseLoading}
        />
        <PaginationWrapper>
          <Pagination
          sx={{
            '& .Mui-selected': {
              backgroundColor: '#9155FD !important',
              color:'white !important',
             },
            '& .Mui-selected:hover': {
              backgroundColor: '#9155FD !important',
              color:'white',
             },
          }}
          count={count} page={page} onChange={handleChange} />
        </PaginationWrapper>
      </WrapperItem>
    </Container>
  );
};

export default ExpenseList;
