import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Sidebar from '../../components/Sidebar';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addShopAction, editShopAction } from '../../store/shop/action';
import { Form, FormikProvider, useFormik } from 'formik';
import { AddEditShopValidationSchema } from '../../utils/validations';
import DefaultAvatar from '../../assets/images/avtar.png';
import { CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DropdownModal from '../../components/ConfirmationModal';
import { deleteProfileImageAction, shopImageDeleteAction } from '../../store/image/action';


const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});

const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA'
});
const BackButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const Orderheading = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const StyledTableContainer = styled(Card)(({ theme }) => ({
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: theme.spacing(1),
  marginTop: '16px',
  padding: theme.spacing(3),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
 // width: '100%',
 width: '135px',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  position: 'relative',
}));

const Image = styled('img')(({ theme }) => ({
  height: 120,
  width: 120,
  borderRadius: theme.spacing(1),
  border: '1px solid lightgray',
  objectFit: 'contain',
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
}));

const AddEditShop = () => {
  const [profileImage, setProfileImage] = useState<{file: any; preview: string;}>({ file: null, preview: '' });
  const [shopImage, setShopImage] = useState<{file: any; preview: string;}>({ file: null, preview: '' });

  const dispatch = useDispatch();
  const location = useLocation();
  const isLoader = useSelector((state: any) => state?.shop.isLoading);
  const [showImage,setShowImage]=useState(false)
  const [showShopImage,setShowShopImage]=useState(false)
  const [showProfileImageModal,setShowProfileImageModal]=useState(false)
  const [showProfileImage,setShowProfileImage]=useState(false)
  // const [shopAdmin]
  const auth = useSelector((state: any) => state.auth);
  const deleteImageModal = useSelector((state: any) => state.deleteImage?.loading);
  const deleteShopImageModal = useSelector((state: any) => state.deleteShopImage?.loading);

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      const preview = URL.createObjectURL(file!);
      setProfileImage({ file, preview });
      setShowProfileImageModal(true)
    }
  };

  const handleShopImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      const preview = URL.createObjectURL(file!);
      setShopImage({ file, preview });
      setShowImage(true)
    }
  };

     
  useEffect(() => {
    if(!deleteImageModal){
      setShowProfileImage(false)
    }
   }, [!deleteImageModal]);


    
  useEffect(() => {
    if(!deleteShopImageModal){
      setShowShopImage(false)
    }
   }, [!deleteShopImageModal]);

   const hadleCloseShopImage=()=>{
    setShowShopImage(false)
   }
   
   const hadleCloseProfileImage=()=>{
    setShowProfileImage(false)
   } 

   const handleDeleteShopModalYes=()=>{
    dispatch(shopImageDeleteAction({shopId:location.state._id}));
    setShopImage({ file: null, preview: '' })
   }

   const handleDeleteProfileModalYes=()=>{
    dispatch(deleteProfileImageAction());
    setProfileImage({ file: null, preview: '' })
   }
     
   const removeShopImage=()=>{
    if(!showImage){
      setShowShopImage(true)
       }else{
      setShowShopImage(false)
      setShopImage({ file: null, preview: '' })
    }
    }
   const removeImage=()=>{
    if(!showProfileImageModal){
      setShowProfileImage(true)
       }else{
        setShowProfileImage(false)
      setProfileImage({ file: null, preview: '' })
    }
    }
 
  const formik = useFormik({
    initialValues: {
      shopLogo: '',
      shopName: '',
      shopAddress: '',
      shopCity: '',
      shopEmail: '',
      shopNumber: '',
      profilePic: '',
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      address: '',
      city: '',
    },
    validationSchema: AddEditShopValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (location.state) {
        if (
          !location?.state?.shopLogo &&
          !location?.state?.shopAdmin[0].profilePic &&
          !profileImage.file &&
          !shopImage.file
        ) {
          const formData = new FormData();
          formData.append('shopLogo', shopImage.file);
          formData.append('shopName', values.shopName);
          formData.append('shopAddress', values.shopAddress);
          formData.append('shopCity', values.shopCity);
          formData.append('shopEmail', values.shopEmail);
          formData.append('shopNumber', values.shopNumber);
          formData.append('profilePic', profileImage.file);
          formData.append('firstName', values.firstName);
          formData.append('lastName', values.lastName);
          formData.append('email', values.email);
          formData.append('mobileNumber', values.mobileNumber);
          formData.append('address', values.address);
          formData.append('city', values.city);
           dispatch(editShopAction(formData,location.state._id));
        } else {
          const formData = new FormData();
          formData.append('shopLogo', shopImage.file);
          formData.append('shopName', values.shopName);
          formData.append('shopAddress', values.shopAddress);
          formData.append('shopCity', values.shopCity);
          formData.append('shopEmail', values.shopEmail);
          formData.append('shopNumber', values.shopNumber);
          formData.append('profilePic', profileImage.file);
          formData.append('firstName', values.firstName);
          formData.append('lastName', values.lastName);
          formData.append('email', values.email);
          formData.append('mobileNumber', values.mobileNumber);
          formData.append('address', values.address);
          formData.append('city', values.city);
           dispatch(editShopAction(formData,location.state._id));
        }
      } else {
        const formData = new FormData();
        formData.append('shopLogo', shopImage.file);
        formData.append('shopName', values.shopName);
        formData.append('shopAddress', values.shopAddress);
        formData.append('shopCity', values.shopCity);
        formData.append('shopEmail', values.shopEmail);
        formData.append('shopNumber', values.shopNumber);
        formData.append('profilePic', profileImage.file);
        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('email', values.email);
        formData.append('mobileNumber', values.mobileNumber);
        formData.append('address', values.address);
        formData.append('city', values.city);
        dispatch(addShopAction({payload:formData,id:auth.data._id}));
      }
    },
  });

  const { touched, errors, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  useEffect(() => {
    if (location.state) {
      const { state } = location;
      setShopImage({file:null,preview:state.shopLogo
      })
      setProfileImage({file:null,preview:state.shopAdmin[0].profilePic})
      setFieldValue('shopLogo', shopImage.file);
      setFieldValue('shopName', state.shopName);
      setFieldValue('shopEmail', state.shopEmail);
      setFieldValue('shopAddress', state.shopAddress);
      setFieldValue('shopCity', state.shopCity);
      setFieldValue('shopNumber', state.shopNumber);
      setFieldValue('profilePic', profileImage.file);
      setFieldValue('firstName', state.shopAdmin[0].firstName);
      setFieldValue('lastName', state.shopAdmin[0].lastName);
      setFieldValue('email', state.shopAdmin[0].email);
      setFieldValue('mobileNumber', state.shopAdmin[0].mobileNumber);
      setFieldValue('address', state.shopAdmin[0].address);
      setFieldValue('city', state.shopAdmin[0].city);
    }
  }, []);

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
      <Orderheading>
      <BackButtonWrapper>
            <Link to='/shops'>
              <ArrowBackRoundedIcon sx={{ fontSize: 30, marginRight: '20px',color: 'black' }} />
            </Link>
          </BackButtonWrapper>
        <Typography variant='h5' sx={{ color: '#9155FD',fontWeight:600 }}>
          {location.state ? 'Edit Shop Detail' : 'Add Shop Detail'}
        </Typography>
        </Orderheading>
        <StyledTableContainer>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <Grid item xs={6}>
                <Typography variant='h5' sx={{ color: '#9155FD' }}>
                  Shop Detail :
                </Typography>
              </Grid>
              <Grid container rowSpacing={1} columnSpacing={{ sm: 2, md: 5 }}>
                <Grid item xs={12} md={12} container>
                  <ImageContainer>
                  {shopImage.preview&&                 
                    <CancelIcon
                      onClick={removeShopImage}
                       style={{
                        position: 'absolute',
                        zIndex: 1,
                        right: '2px',
                        marginTop: '-7px',
                        color: '#7149C6',
                        cursor: 'pointer',
                      }}
                    />}
                    <Image
                      src={
                        shopImage.preview
                          ? shopImage.preview
                            : DefaultAvatar
                      }
                    />
                    <Stack direction='row' gap={2} mt={2}>
                      <Button
                        variant='contained'
                        sx={{ backgroundColor: '#9155FD !important' }}
                        component='label'
                      >
                        Upload
                        <input
                          hidden
                          accept='image/*'
                          type='file'
                          onChange={handleShopImageUpload}
                        />
                      </Button>
                    </Stack>
                  </ImageContainer>
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Shop Name'
                    size='small'
                    {...getFieldProps('shopName')}
                    required
                    error={Boolean(touched.shopName && errors.shopName)}
                    helperText={touched.shopName && errors.shopName}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Shop Address'
                    size='small'
                    required
                    multiline
                    rows={2}
                    {...getFieldProps('shopAddress')}
                    error={Boolean(touched.shopAddress && errors.shopAddress)}
                    helperText={touched.shopAddress && errors.shopAddress}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='City'
                    size='small'
                    {...getFieldProps('shopCity')}
                    required
                    error={Boolean(touched.shopCity && errors.shopCity)}
                    helperText={touched.shopCity && errors.shopCity}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Shop Email'
                    size='small'
                    {...getFieldProps('shopEmail')}
                    required
                    error={Boolean(touched.shopEmail && errors.shopEmail)}
                    helperText={touched.shopEmail && errors.shopEmail}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Shop Phone Number'
                    type='number'
                    size='small'
                    required
                    {...getFieldProps('shopNumber')}
                    error={Boolean(touched.shopNumber && errors.shopNumber)}
                    helperText={touched.shopNumber && errors.shopNumber}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' sx={{ color: '#9155FD' }}>
                  Shop Admin Detail :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} container>
             
                <ImageContainer>
                {profileImage.preview&&                 
                    <CancelIcon
                      onClick={removeImage}
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        right: '2px',
                        marginTop: '-7px',
                        color: '#7149C6',
                        cursor: 'pointer',
                      }}
                    />}
                  <Image
                    src={
                      profileImage?.preview
                        ? profileImage?.preview
                          : DefaultAvatar
                      
                    }
                  />
                  <Stack direction='row' gap={2} mt={2}>
                    <Button
                      variant='contained'
                      sx={{ backgroundColor: '#9155FD !important' }}
                      component='label'
                    >
                      Upload
                      <input
                        hidden
                        accept='image/*'
                        type='file'
                        onChange={handleProfileImageUpload}
                      />
                    </Button>
                  </Stack>
                </ImageContainer>
              </Grid>
              <Grid container rowSpacing={1} columnSpacing={{ sm: 2, md: 5 }}>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='First Name'
                    size='small'
                    {...getFieldProps('firstName')}
                    required
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Last Name'
                    size='small'
                    {...getFieldProps('lastName')}
                    required
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Address'
                    size='small'
                    required
                    multiline
                    rows={2}
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='City'
                    size='small'
                    {...getFieldProps('city')}
                    required
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Email'
                    size='small'
                    {...getFieldProps('email')}
                    required
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6} container mb={2}>
                  <TextField
                    fullWidth
                    label='Mobile Number'
                    type='number'
                    size='small'
                    required
                    {...getFieldProps('mobileNumber')}
                    error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                    helperText={touched.mobileNumber && errors.mobileNumber}
                  />
                </Grid>
              </Grid>
              <ButtonWrapper>
                <Button
                  size='medium'
                  sx={{
                    placeSelf: 'center',
                    backgroundColor: '#9155FD !important',
                    marginRight: '8px',
                    width: '80px',
                  }}
                  disabled={isLoader}
                  variant='contained'
                  type='submit'
                >
                  {isLoader ? (
                    <CircularProgress
                      color='inherit'
                      style={{
                        color: '#FFFFFF',
                        height: '25px',
                        width: '25px',
                      }}
                    />
                  ) : location.state ? (
                    'UPDATE'
                  ) : (
                    'ADD'
                  )}
                </Button>
                <Link to='/shops'>
                  <Button size='medium' color='info' variant='outlined'>
                    CANCEL
                  </Button>
                </Link>
              </ButtonWrapper>
            </Form>
          </FormikProvider>
        </StyledTableContainer>
      </WrapperItem>
      <DropdownModal
      loading={deleteImageModal}
        title="Are you sure you want to delete image?"
        isOpen={showProfileImage}
        handleClose={hadleCloseProfileImage}
        handleClickYes={handleDeleteProfileModalYes}
      />
       <DropdownModal
      loading={deleteShopImageModal}
        title="Are you sure you want to delete image?"
         isOpen={showShopImage}
         handleClose={hadleCloseShopImage}
         handleClickYes={handleDeleteShopModalYes}
      />
    </Container>
  );
};

export default AddEditShop;
