import { all, takeLatest } from 'redux-saga/effects';
import {
  loginUserSaga,
  forgotPasswordSaga,
  resetPasswordSaga,
} from './auth/loginSaga';
import {
  addEmployeeSaga,
  deleteEmployeeSaga,
  editEmployeeSaga,
  editEmployeeStatusSaga,
  getEmployeesSaga,
} from './employee/employeesSaga';
import {
  ADD_LOGIN_BEGIN,
  ADD_ORDER_BEGIN,
  ADD_SHOP_BEGIN,
  ADD_TRANSACTION_BEGIN,
  ADD_USERS_BEGIN,
  ADD_EMPLOYEE_BEGIN,
  DELETE_ORDER_BEGIN,
  DELETE_SHOP_BEGIN,
  DELETE_TRANSACTION_BEGIN,
  DELETE_USERS_BEGIN,
  DELETE_EMPLOYEE_BEGIN,
  EDIT_ORDER_BEGIN,
  EDIT_SHOP_BEGIN,
  EDIT_SHOP_STATUS_BEGIN,
  EDIT_TRANSACTION_BEGIN,
  EDIT_USERS_BEGIN,
  EDIT_EMPLOYEE_BEGIN,
  EDIT_EMPLOYEE_STATUS_BEGIN,
  EDIT_USERS_STATUS_BEGIN,
  GET_ORDER_BEGIN,
  GET_SHOP_BEGIN,
  GET_TRANSACTION_BEGIN,
  GET_USERS_BEGIN,
  GET_EMPLOYEE_BEGIN,
  FORGOT_PASSWORD_BEGIN,
  EDIT_ORDER_STATUS_BEGIN,
  RESET_PASSWORD_BEGIN,
  SEND_ENVOICE_BEGIN,
  ADD_EXPENSES_BEGIN,
  GET_EXPENSES_BEGIN,
  GET_TECHNICIAN_BEGIN,
  ADD_TECHNICIAN_BEGIN,
  EDIT_TECHNICIAN_BEGIN,
  DELETE_TECHNICIAN_BEGIN,
  EDIT_TECHNICIAN_STATUS_BEGIN,
  GET_TECHNICIAN_NAMES_BEGIN,
  GET_EXPENSE_OPTION_BEGIN,
  GET_EXPENSE_LIST_BEGIN,
  EXPENSE_STATUS_BEGIN,
  ADD_CUSTOMER_PAYMENT_BEGIN,
  ORDER_DETAIL_BEGIN,
  DELETE_CUSTOMER_PAYMENT_BEGIN,
  EDIT_CUSTOMER_PAYMENT_BEGIN,
  EDIT_CUSTOMER_ORDER_BEGIN,
  ADD_TECHNICIAN_PAYMENT_BEGIN,
  EDIT_TECHNICIAN_PAYMENT_BEGIN,
  DELETE_TECHNICIAN_PAYMENT_BEGIN,
  DELETE_ORDER_EXPENSE_BEGIN,
  USER_DASHBOARD_BEGIN,
  DELETE_IMAGE_BEGIN,
  DELETE_ORDER_IMAGE_BEGIN,
  SHOP_DELETE_IMAGE_BEGIN,
  GET_MEASUREMENT_BEGIN,
  CREATE_MEASUREMENT_BEGIN,
  EDIT_MEASUREMENT_BEGIN,
  DELETE_MEASUREMENT_BEGIN
} from './constant';
import {
  addOrderSaga,
  addPaymentSaga,
  deleteOrderSaga,
  deletePaymentSaga,
  editCustomerOrderSaga,
  editOrderSaga,
  editOrderStatusSaga,
  editPaymentSaga,
  getOrderDetailSaga,
  getOrderSaga,
} from './order/orderSaga';
import {
  addShopSaga,
  deleteShopSaga,
  editShopSaga,
  editShopStatusSaga,
  getShopSaga,
} from './shop/shopSaga';
import {
  UserDashboardSaga,
  addUserSaga,
  deleteUserSaga,
  editUserSaga,
  editUserStatusSaga,
  getUsersSaga,
} from './users/usersSaga';
import {
  addTransactionSaga,
  deleteTransactionSaga,
  editTransactionSaga,
  getTransactionSaga,
} from './transaction/transactionSaga';

import { envoiceSaga } from './receipt/envoiceSaga';
import {
  addExpenseSaga,
  deleteOrderExpenseSaga,
  expenseStatusSaga,
  getExpenseListSaga,
  getExpenseOptionSaga,
  getExpenseSaga,
} from './expenses/expenseSaga';
import {
  addTechnicianPaymentSaga,
  addTechnicianSaga,
  deleteTechnicianPaymentSaga,
  deleteTechnicianSaga,
  editTechnicianPaymentSaga,
  editTechnicianSaga,
  editTechnicianStatusSaga,
  getTechnicianNames,
  getTechnicianSaga,
} from './technician/technicianSaga';
import { deleteImageSaga, deleteOrderImageSaga, deleteShopImageSaga } from './image/imageSaga';
import { createMeasurementSaga, deleteMeasurementSaga, editMeasurementSaga, getMeasurementSaga } from './mesurement/saga';

export function* rootSaga() {
  yield all([
    takeLatest(ADD_LOGIN_BEGIN, loginUserSaga),
    takeLatest(FORGOT_PASSWORD_BEGIN, forgotPasswordSaga),
    takeLatest(RESET_PASSWORD_BEGIN, resetPasswordSaga),

    takeLatest(GET_SHOP_BEGIN, getShopSaga),
    takeLatest(ADD_SHOP_BEGIN, addShopSaga),
    takeLatest(EDIT_SHOP_BEGIN, editShopSaga),
    takeLatest(EDIT_SHOP_STATUS_BEGIN, editShopStatusSaga),
    takeLatest(DELETE_SHOP_BEGIN, deleteShopSaga),

    takeLatest(GET_USERS_BEGIN, getUsersSaga),
    takeLatest(USER_DASHBOARD_BEGIN, UserDashboardSaga),
    takeLatest(ADD_USERS_BEGIN, addUserSaga),
    takeLatest(EDIT_USERS_BEGIN, editUserSaga),
    takeLatest(EDIT_USERS_STATUS_BEGIN, editUserStatusSaga),
    takeLatest(DELETE_USERS_BEGIN, deleteUserSaga),

    takeLatest(GET_EMPLOYEE_BEGIN, getEmployeesSaga),
    takeLatest(ADD_EMPLOYEE_BEGIN, addEmployeeSaga),
    takeLatest(EDIT_EMPLOYEE_BEGIN, editEmployeeSaga),
    takeLatest(EDIT_EMPLOYEE_STATUS_BEGIN, editEmployeeStatusSaga),
    takeLatest(DELETE_EMPLOYEE_BEGIN, deleteEmployeeSaga),

    takeLatest(GET_ORDER_BEGIN, getOrderSaga),
    takeLatest(ADD_ORDER_BEGIN, addOrderSaga),
    takeLatest(EDIT_ORDER_BEGIN, editOrderSaga),
    takeLatest(EDIT_ORDER_STATUS_BEGIN, editOrderStatusSaga),
    takeLatest(DELETE_ORDER_BEGIN, deleteOrderSaga),

    takeLatest(GET_TRANSACTION_BEGIN, getTransactionSaga),
    takeLatest(ADD_TRANSACTION_BEGIN, addTransactionSaga),
    takeLatest(EDIT_TRANSACTION_BEGIN, editTransactionSaga),
    takeLatest(DELETE_TRANSACTION_BEGIN, deleteTransactionSaga),

    takeLatest(SEND_ENVOICE_BEGIN, envoiceSaga),
    takeLatest(ADD_EXPENSES_BEGIN, addExpenseSaga),
    takeLatest(GET_EXPENSES_BEGIN, getExpenseSaga),

    takeLatest(GET_TECHNICIAN_BEGIN, getTechnicianSaga),

    takeLatest(ADD_TECHNICIAN_BEGIN, addTechnicianSaga),
    takeLatest(EDIT_TECHNICIAN_BEGIN, editTechnicianSaga),
    takeLatest(DELETE_TECHNICIAN_BEGIN, deleteTechnicianSaga),
    takeLatest(EDIT_TECHNICIAN_STATUS_BEGIN, editTechnicianStatusSaga),
    takeLatest(GET_EXPENSE_OPTION_BEGIN, getExpenseOptionSaga),
    takeLatest(GET_EXPENSE_LIST_BEGIN, getExpenseListSaga),
    takeLatest(EXPENSE_STATUS_BEGIN, expenseStatusSaga),
    takeLatest(GET_TECHNICIAN_NAMES_BEGIN, getTechnicianNames),
    takeLatest(ADD_CUSTOMER_PAYMENT_BEGIN, addPaymentSaga),
    takeLatest(ORDER_DETAIL_BEGIN, getOrderDetailSaga),
    takeLatest(DELETE_CUSTOMER_PAYMENT_BEGIN, deletePaymentSaga),
    takeLatest(EDIT_CUSTOMER_PAYMENT_BEGIN, editPaymentSaga),
    takeLatest(EDIT_CUSTOMER_ORDER_BEGIN, editCustomerOrderSaga),
    takeLatest(ADD_TECHNICIAN_PAYMENT_BEGIN, addTechnicianPaymentSaga),
    takeLatest(EDIT_TECHNICIAN_PAYMENT_BEGIN, editTechnicianPaymentSaga),
    takeLatest(DELETE_TECHNICIAN_PAYMENT_BEGIN, deleteTechnicianPaymentSaga),
    takeLatest(DELETE_ORDER_EXPENSE_BEGIN, deleteOrderExpenseSaga),
    takeLatest(DELETE_IMAGE_BEGIN, deleteImageSaga),
    takeLatest(DELETE_ORDER_IMAGE_BEGIN, deleteOrderImageSaga),
    takeLatest(SHOP_DELETE_IMAGE_BEGIN, deleteShopImageSaga),
    takeLatest(GET_MEASUREMENT_BEGIN,getMeasurementSaga),
    takeLatest(CREATE_MEASUREMENT_BEGIN,createMeasurementSaga),
    takeLatest(EDIT_MEASUREMENT_BEGIN,editMeasurementSaga),
    takeLatest(DELETE_MEASUREMENT_BEGIN,deleteMeasurementSaga)
    ]);
}
