import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Sidebar from '../../components/Sidebar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteShopAction, getShopAction } from '../../store/shop/action';
import Pagination from '@mui/material/Pagination';
import SearchBar from '../../components/SearchBar';
import { CircularProgress } from '@mui/material';
import ShopListTable from './TableItem';

const shopHeader = [
  'Logo',
  'Name',
  'Email',
  'City',
  'Address',
  'Number',
  'Status',
  'Action',
];

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
  '@media (max-width: 820px)': {
    width: '100%',
  },
});

const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA',
  '@media (max-width: 820px)': {
    padding: '20px 10px 20px 260px',
  },
});

const SearchBarWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  '@media (max-width: 1076px)': {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'start',
    marginTop: '10px',
    justifyContent: 'space-between'  },
});

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  marginTop: '12px',
  fontWeight: 'bold',
});

const ShopList = () => {
  const [page, setPage] = useState(1);
  const [shopListData, setShopListData] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dispatch = useDispatch();
  const searchData = useSelector((state: any) => state?.shop.data.data);
  const shopListLoader = useSelector((state: any) => state.shop.loading);
  const shopPagination = useSelector((state: any) => state.shop.shopData);
  const shops = useSelector((state: any) => state?.shop);
  const auth = useSelector((state: any) => state.auth);

  const count = Math.ceil(shopPagination.total / 10);
  const handleChange = (event: any, value: any) => {
    dispatch(
      getShopAction({ id: auth.data._id, page:value, searchTerm, rowsPerPage: 10 })
    );
    setPage(value);
  };
  const getShopList = (searchTerm: string): any => {
    setPage(1);
    dispatch(
      getShopAction({ id: auth.data._id, page: 1, searchTerm, rowsPerPage: 10 })
    );
    setSearchTerm(searchTerm);
  };

  useEffect(() => {
     if(shops?.shopData?.length===0){
    dispatch(
      getShopAction({ id: auth.data._id, page, searchTerm, rowsPerPage: 10 })
    );
     }
    setSearchTerm(searchTerm);
  }, [page]);

  useEffect(() => {
    if (shops?.shopData?.data) {
      setShopListData(shops?.shopData?.data)
    }
  }, [shops?.shopData?.data])

  const handleClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId('');
  };

  const handleDeleteModalYes = () => {
    dispatch(deleteShopAction({payload:
      {
       id: deleteItemId,
       authId:auth.data._id
    } }));
  };

  const handleOpenDeleteModal = (deleteId: string) => {
    setDeleteItemId(deleteId);
    setShowDeleteModal(true);
  };
  
  const deleteShopLoader = useSelector(
    (state: any) => state.deleteShopData.loading
  );
  
  useEffect(() => {
    if (!deleteShopLoader) {
      setShowDeleteModal(false);
      setPage(1)
    }
  }, [!deleteShopLoader]);

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Typography variant='h5' sx={{ color: '#9155FD',fontWeight: 600 }}>
          Shop List Table
        </Typography>
        <SearchBarWrapper>
          <SearchBar onSearch={getShopList} />
          <Link to='/shop/add'>
            <Button
              size='medium'
              sx={{ backgroundColor: '#9155FD !important', fontSize: '16px', '@media (max-width: 1076px)': { marginTop:'10px' }}}
              variant='contained'
            >
              Create
            </Button>
          </Link>
        </SearchBarWrapper>
        <ShopListTable
        deleteShopLoading={deleteShopLoader}
          shopHeader={shopHeader}
          shopListData={shopListData}
          shopListLoader={shopListLoader}
          showDeleteModal={showDeleteModal}
          handleClose={handleClose}
          handleDeleteModalYes={handleDeleteModalYes}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
        {searchData?.loading && <CircularProgress /> && 'No Data Found'}{' '}
        <PaginationWrapper>
          <Pagination
          sx={{
            '& .Mui-selected': {
              backgroundColor: '#9155FD !important',
              color:'white !important',
             },
            '& .Mui-selected:hover': {
              backgroundColor: '#9155FD !important',
              color:'white !important',
             },
          }}
          count={count} page={page} onChange={handleChange} />
        </PaginationWrapper>
      </WrapperItem>
    </Container>
  );
};

export default ShopList;
