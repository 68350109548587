import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    height: '40%',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0, 0),
    paddingLeft: '10px',
    border: 'none',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
export interface ConfirmationModalProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  dropdownModalValues?: { e: any; item: any };
  loading?: boolean;
  message: string;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
      }}
      {...other}
      style={{ outline: 'none' }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CompletedModal = ({
  title,
  isOpen,
  handleClose,
  dropdownModalValues,
  loading,
  message,
}: ConfirmationModalProps) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Fragment>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
        open={isOpen}
      >
        <Box sx={{ width: 400 }}>
          <StyledDialogTitle
            id="confirmation-dialog-title"
            onClose={handleClose}
          >
            <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}></Typography>
            <Typography
              gutterBottom
              sx={{
                fontSize: 18,
                color: '#1c2026',
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontSize: 15,
                color: 'red',
                textAlign: 'left',
              }}
            >
              {message}
            </Typography>
          </StyledDialogTitle>
        </Box>
      </StyledDialog>
    </Fragment>
  );
};
export default CompletedModal;
