import { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  TextField,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DatePickers from '../../../../components/DatePickers';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { editCustomerOrder } from '../../../../store/order/action';
import { EditCustomerOrderValidationSchema } from '../../../../utils/validations';

const EditOrderDetailsView = styled(Box)(({ theme }) => ({
  position: 'relative' as 'relative',
  marginTop: '10px',
  backgroundColor: 'white',
  border: '2px solid #FFFFFF !important',
  borderRadius: theme.spacing(1),
  padding: '50px 30px 70px 30px',
  height: '70px !important',
  '@media (max-width: 1200px)': {
    height: '30% !important',
    padding: '30px 30px 70px 30px',
  },
}));

const StyledGrid = {
  marginLeft: '0px !important',
  width: '100% !important',
};

const CustomButton = styled(Button)({
  backgroundColor: '#9155FD !important',
  '@media (max-width: 1024px)': {
    marginLeft: '20px',
  },
});

const ButtonWrapper = styled(Box)({
  position: 'absolute',
  right: 0,
  top: 0,
  marginTop: '50px',
  padding: '20px',
  '@media (max-width: 890px)': {
    left: 0,
    marginTop: '170px',
  },
  '@media (max-width: 1200px)': {
    left: 0,
    marginTop: '170px',
  },
});

const Edit = ({
  setOpenEditForm,
  orderEditData,
  setEditedValues,
  updatedData,
  editedValues,
}: any) => {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state: any) => state.auth);
  const isUpdateLoader = useSelector(
    (state: any) => state?.editcustomerOrderDetails?.loading
  );

  const formik = useFormik({
    initialValues: {
      customerName: '',
      customerNumber: '',
      customerEmail: '',
      customerAddress: '',
      deliveryDate: new Date(),
      orderDate: new Date(),
    },
    validationSchema: EditCustomerOrderValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setEditedValues(values);
    
      if (location.state) {
        const formData = new FormData();
        formData.append('customerName', values.customerName);
        formData.append('customerNumber', values.customerNumber);
        formData.append('customerEmail', values.customerEmail);
        formData.append('customerAddress', values.customerAddress);
        formData.append('deliveryDate', values.deliveryDate.toString());
        formData.append('orderDate', values.orderDate.toString());
        dispatch(editCustomerOrder(formData,auth.data._id, location.state));
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  useEffect(() => {
    
    if (!editedValues) {
      setFieldValue('customerName', orderEditData.customerName);
      setFieldValue('customerNumber', orderEditData.customerNumber);
      if(orderEditData.customerEmail === ''){
        setFieldValue('customerEmail', 'NA');
      }
      else{
        setFieldValue('customerEmail', orderEditData.customerEmail);
      }
      setFieldValue('customerAddress', orderEditData.customerAddress);
      setFieldValue('deliveryDate', orderEditData.deliveryDate);
      setFieldValue('orderDate', orderEditData.orderDate);
    } else if (editedValues) {
      setFieldValue('customerName', editedValues.customerName);
      setFieldValue('customerNumber', editedValues.customerNumber);
      if(editedValues.customerEmail === ''){
        setFieldValue('customerEmail', 'NA');
      }
      else{
        setFieldValue('customerEmail', editedValues.customerEmail);
      }
      setFieldValue('customerAddress', editedValues.customerAddress);
      setFieldValue('deliveryDate', editedValues.deliveryDate);
      setFieldValue('orderDate', editedValues.orderDate);
    }
  }, [orderEditData, updatedData]);

  const handleSave = () => {
    setIsEditing(!isEditing);
    setOpenEditForm(false);
  };

  return (
    <EditOrderDetailsView>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton
              onClick={handleSave}
              sx={{
                position: 'absolute',
                right: 10,
                top: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
            <ButtonWrapper>
              <CustomButton variant='contained' type='submit'>
                {isUpdateLoader ? (
                  <CircularProgress
                    color='inherit'
                    style={{
                      color: '#FFFFFF',
                      height: '25px',
                      width: '25px',
                    }}
                  />
                ) : location.state ? (
                  'Update'
                ) : (
                  'Save'
                )}
              </CustomButton>
            </ButtonWrapper>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 20 }}
              rowGap={2}
              columnGap={0}
              sx={StyledGrid}
            >
              <Grid md={6} sm={6} lg={4} container>
                <Grid item md={11}>
                  <TextField
                    fullWidth
                    size='small'
                    placeholder='Name'
                    variant='outlined'
                    {...getFieldProps('customerName')}
                    error={Boolean(touched.customerName && errors.customerName)}
                    helperText={touched.customerName && errors.customerName}
                  />
                </Grid>
              </Grid>
              <Grid md={6} sm={6} lg={4} container>
                <Grid item md={11}>
                  <TextField
                    fullWidth
                    size='small'
                    placeholder='Number'
                    type='number'
                    variant='outlined'
                    {...getFieldProps('customerNumber')}
                    error={Boolean(
                      touched.customerNumber && errors.customerNumber
                    )}
                    helperText={touched.customerNumber && errors.customerNumber}
                  />
                </Grid>
              </Grid>
              <Grid md={6} sm={6} lg={4} container>
                <Grid item md={11} lg={9}>
                  <TextField
                    fullWidth
                    size='small'
                    placeholder='Email'
                    variant='outlined'
                    {...getFieldProps('customerEmail')}
                    error={Boolean(
                      touched.customerEmail && errors.customerEmail
                    )}
                    helperText={touched.customerEmail && errors.customerEmail}
                  />
                </Grid>
              </Grid>
              <Grid md={6} sm={6} lg={4} container>
                <Grid item md={11}>
                  <TextField
                    fullWidth
                    size='small'
                    placeholder='Address'
                    variant='outlined'
                    {...getFieldProps('customerAddress')}
                    error={Boolean(
                      touched.customerAddress && errors.customerAddress
                    )}
                    helperText={
                      touched.customerAddress && errors.customerAddress
                    }
                  />
                </Grid>
              </Grid>
              <Grid md={6} sm={6} lg={4} container>
                <Grid item md={11}>
                  <DatePickers
                    placeholder={'Order Date'}
                    value={formik.values.orderDate}
                    onChange={(value: any) => {
                      formik.setFieldValue('orderDate', value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid md={6} sm={6} lg={4} container>
                <Grid item md={11} lg={9}>
                  <DatePickers
                    placeholder={'Delivery Date'}
                    value={formik.values.deliveryDate}
                    onChange={(value: any) => {
                      formik.setFieldValue('deliveryDate', value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </EditOrderDetailsView>
  );
};
export default Edit;
