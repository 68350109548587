import {
  DELETE_ORDER_EXPENSE_BEGIN,
  DELETE_ORDER_EXPENSE_SUCCESS,
  DELETE_ORDER_EXPENSE_FAILURE,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
deleteOrderExpenseData:[],
   error: null,
   isLoading: false,
 };
 
 const deleteOrderExpenseReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case DELETE_ORDER_EXPENSE_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case DELETE_ORDER_EXPENSE_SUCCESS:
       return { ...state, loading: false, deleteOrderExpenseData:action.payload};

     case DELETE_ORDER_EXPENSE_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default deleteOrderExpenseReducer;