import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography, Grid, DialogContent, DialogTitle, DialogActions, Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import DefaultAvatar from '../../assets/images/avtar.png';
import { styled } from '@mui/material/styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #ffffff !important',
  borderRadius: '10px',
  boxShadow: 24,
  width: '25%',
};
const Shopadmindialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const Shopadminmodal = styled(DialogContent)({
  display: 'flex',
});
const DialogTitlebox = styled(DialogTitle)({
  padding: '8px 20px 8px 20px',
  marginLeft: '20px',
  marginTop: '7px',
  color: '#9155FD',
  marginBottom: '7px',
});
const detailStyle = {
  width: 240,
  height: 240,
  mb: 3,
  marginTop: '5px',
  alignItems:'center',
  borderRadius: '200px',
};


export interface ConfirmationModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  onClose: any;
  shopAdminData: any;
}
const ShopAdminModal = ({
  isOpen,
  handleClose,
  onClose,
  shopAdminData,
}: ConfirmationModalProps) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Box sx={style}>
      <Shopadmindialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <DialogTitlebox id="customized-dialog-title">
          <Typography
            variant='h5'
            sx={{ fontWeight: 600 }}
          >
            Shop Admin Details
          </Typography>
        </DialogTitlebox>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Shopadminmodal dividers>
          <Box>
            <Box>
              <Grid
                container
                rowSpacing={2}
                width={'65%'}
                rowGap={3}
              >
                <Grid
                  rowSpacing={1}
                  container
                >
                  <Grid md={12} container alignItems={'center'}>
                    <Typography display='inline' sx={{ fontWeight: 'bold', fontSize: 20 }}>
                      Name
                    </Typography>
                  </Grid>
                  <Grid md={12} container alignItems={'center'} sx={{ fontSize: 18, color: '#57677a' }}>
                    {shopAdminData[0].firstName} &nbsp;
                    {shopAdminData[0].lastName}
                  </Grid>
                </Grid>
                <Grid
                  rowSpacing={1}
                  container
                >
                  <Grid md={12} container alignItems={'center'}>
                    <Typography display='inline' sx={{ fontWeight: 'bold', fontSize: 20 }}>
                      Email
                    </Typography>
                  </Grid>
                  <Grid md={12} container alignItems={'center'} sx={{ fontSize: 18, color: '#57677a' }}>
                    {shopAdminData[0].email}
                  </Grid>
                </Grid>
                <Grid
                  rowSpacing={1}
                  container
                >
                  <Grid md={12} container alignItems={'center'}>
                    <Typography display='inline' sx={{ fontWeight: 'bold', fontSize: 20 }}>
                      Contact
                    </Typography>
                  </Grid>
                  <Grid md={12} container alignItems={'center'} sx={{ fontSize: 18, color: '#57677a' }}>
                    {shopAdminData[0].mobileNumber}
                  </Grid>
                </Grid>
                <Grid
                  rowSpacing={1}
                  container
                >
                  <Grid md={12} container alignItems={'center'}>
                    <Typography display='inline' sx={{ fontWeight: 'bold', fontSize: 20 }}>
                      City
                    </Typography>
                  </Grid>
                  <Grid md={12} container alignItems={'center'} sx={{ fontSize: 18, color: '#57677a' }}>
                    {shopAdminData[0].city}
                  </Grid>
                </Grid>
                <Grid
                  rowSpacing={1}
                  container
                >
                  <Grid md={12} container alignItems={'center'}>
                    <Typography display='inline' sx={{ fontWeight: 'bold', fontSize: 20 }}>
                      Address
                    </Typography>
                  </Grid>
                  <Grid md={12} container alignItems={'center'} sx={{ fontSize: 18, color: '#57677a' }}>
                    {shopAdminData[0].address}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={detailStyle}
            component='img'
            src={
              shopAdminData[0]?.profilePic
                ? shopAdminData[0]?.profilePic
                : DefaultAvatar
            }>
          </Box>
        </Shopadminmodal>
        <DialogActions>
        </DialogActions>
      </Shopadmindialog>
    </Box>
  );
};
export default ShopAdminModal;
