import {
    USER_DASHBOARD_BEGIN,
    USER_DASHBOARD_SUCCESS,
    USER_DASHBOARD_FAILURE,
    IS_LOADING
  } from '../constant';
  
  const initialState: any = {
    loading: false,
    dashboardData: [],
    error: null,
    isLoading: false
  };
  
  const dashboardReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case USER_DASHBOARD_BEGIN:
        return {
          ...state,
          loading: true
        };
      case USER_DASHBOARD_SUCCESS:
        return { ...state, loading: false, dashboardData: action.payload };

      case USER_DASHBOARD_FAILURE:
        return { ...state,loading: false, error: action.payload };
  
        case IS_LOADING:
          return {
            ...state,
            loading: false,
            data: action.payload,
            isLoading: action.payload
          };
      default:
        return state;
    }
  };
  
  export default dashboardReducer;
  