import {
  ADD_CUSTOMER_PAYMENT_BEGIN,
  ADD_ORDER_BEGIN,
  DELETE_CUSTOMER_PAYMENT_BEGIN,
  DELETE_ORDER_BEGIN,
  EDIT_CUSTOMER_ORDER_BEGIN,
  EDIT_CUSTOMER_PAYMENT_BEGIN,
  EDIT_ORDER_BEGIN,
  EDIT_ORDER_STATUS_BEGIN,
  GET_ORDER_BEGIN,
  IS_LOADING,
  ORDER_DETAIL_BEGIN,
} from '../constant';

export const getOrder = (payload: any) => ({
  type: GET_ORDER_BEGIN,
  payload,
});

export const addOrder = (payload: any) => ({
  type: ADD_ORDER_BEGIN,
  payload,
});

export const editOrder = (editData: any,id:any,_id:any) => (
    {
  type: EDIT_ORDER_BEGIN,
  payload:{editData,id,_id }
});

export const editCustomerOrder = (editData: any,id:any,_id:any) => (
  {
  type: EDIT_CUSTOMER_ORDER_BEGIN,
  payload:{editData,id,_id }
});

export const editOrderStatus = (payload: any) => ({
  type: EDIT_ORDER_STATUS_BEGIN,
  payload,
});

export const deleteOrder = (payload: any) => ({
  type: DELETE_ORDER_BEGIN,
  payload,
});

export const isLoading = (payload: any) => ({
  type: IS_LOADING,
  payload,
});

export const getOrderDetailAction = (payload: any) => ({
  type: ORDER_DETAIL_BEGIN,
  payload,
});


export const addPayment = (payload: any) => ({
  type: ADD_CUSTOMER_PAYMENT_BEGIN,
  payload,
});

export const deletePayment=(payload:any)=>({
  type:DELETE_CUSTOMER_PAYMENT_BEGIN,
  payload
})


export const editPayment=(payload:any)=>({
  type:EDIT_CUSTOMER_PAYMENT_BEGIN,
  payload
})






