import {
  SHOP_DELETE_IMAGE_BEGIN,
  SHOP_DELETE_IMAGE_SUCCESS,
  SHOP_DELETE_IMAGE_FAILURE,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
deleteImageShopData:[],
   error: null,
   isLoading: false,
 };
 
 const deleteShopImageReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case SHOP_DELETE_IMAGE_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case SHOP_DELETE_IMAGE_SUCCESS:
       return { ...state, loading: false, deleteImageShopData:action.payload};

     case SHOP_DELETE_IMAGE_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default deleteShopImageReducer;