import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useFormik, FormikProvider } from 'formik';
import {
  Box,
  Card,
  TextField,
  Typography,
  Button,
  Stack,
  Grid,
} from '@mui/material';
import Sidebar from '../../components/Sidebar';
import { AddEditUserValidationSchema } from '../../utils/validations';
import DefaultAvatar from '../../assets/images/avtar.png';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { editUser, getUsers } from '../../store/users/action';
import ResetPassword from '../../components/ResetPassword';
import CancelIcon from '@mui/icons-material/Cancel';
import DropdownModal from '../../components/ConfirmationModal';
import { deleteProfileImageAction } from '../../store/image/action';

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});

const ProfileWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '78vh',
});

const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA'
});

const StyledTableContainer = styled(Card)(({ theme }) => ({
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: theme.spacing(1),
  marginTop: '16px',
  overflowY: 'auto !important' as 'auto',
  height: '77vh',
  padding: theme.spacing(3),
}));

const Image = styled('img')(({ theme }) => ({
  height: 120,
  width: 120,
  borderRadius: theme.spacing(1),
  border: '1px solid lightgray',
  objectFit: 'contain',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '135px',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  position: 'relative',
}));

export default function Profile() {
  const [openModal, setOpenModal] = useState(false);
  const [profileImage, setProfileImage] = useState<{ file: any; preview: any; }>({ file: null, preview: '' });
  const [showImageModal,setShowImageModal]=useState(false)
const [showImage,setShowImage]=useState(false)
  const dispatch = useDispatch();
  const profileLoader = useSelector((state: any) => state?.users?.isLoading);
  const auth = useSelector((state: any) => state.auth);
  const users = useSelector((state: any) => state?.users);
  const deleteImageModal = useSelector((state: any) => state.deleteImage?.loading);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const formik = useFormik({
    initialValues: {
      profilePic: profileImage.preview,
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      address: '',
      city: '',
    },
    validationSchema: AddEditUserValidationSchema,
    onSubmit: async (values) => {
      const form = new FormData();
      form.append('profilePic', profileImage.file);
      form.append('firstName', values.firstName);
      form.append('lastName', values.lastName);
      form.append('email', values.email);
      form.append('mobileNumber', values.mobileNumber);
      form.append('address', values.address);
      form.append('city', values.city);
      if (auth?.data) {
        dispatch(editUser(form));
      }
    },
  });

  useEffect(() => {
    dispatch(getUsers());
  }, [])

  const { touched, errors, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      const preview = URL.createObjectURL(file!);
      setProfileImage({ file, preview });
      setFieldValue('profilePicture', preview);
      setShowImage(true)
    }
  };

  const hadleCloseImage=()=>{
    setShowImageModal(false)
   }  
  
    const handleDeleteModalYes=()=>{
    dispatch(deleteProfileImageAction());
    setProfileImage({ file: null, preview: '' })
   }
  
   const removeImage=()=>{
    if(!showImage){
      setShowImageModal(true)
       }else{
      setShowImageModal(false)
      setProfileImage({ file: null, preview: '' })
    }
    }

    useEffect(() => {
      if(!deleteImageModal){
   setShowImageModal(false)
      }
     }, [!deleteImageModal]);
   
   

  useEffect(() => {
    if (users?.userData?.data) {
      setProfileImage({file:null,preview:users?.userData?.data?.profilePic})
      setFieldValue('profilePic', users?.userData?.data?.profilePic);
      setFieldValue('firstName', users?.userData?.data?.firstName);
      setFieldValue('lastName', users?.userData?.data?.lastName);
      setFieldValue('email', users?.userData?.data?.email);
      setFieldValue('mobileNumber', users?.userData?.data?.mobileNumber);
      setFieldValue('address', users?.userData?.data?.address);
      setFieldValue('city', users?.userData?.data?.city);
    }
  }, [users?.userData?.data]);

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Typography variant='h5' sx={{ color: '#9155FD',fontWeight:600 }}>
          Profile Details
        </Typography>
        <StyledTableContainer>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              {users?.loading ? <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  position: 'absolute',
                  alignItems: 'center',
                  height: '60%',
                  width: '-webkit-fill-available',
                }}
              >
                <CircularProgress color='inherit' />
              </Box> :
                <ProfileWrapper>
                  <Box>
                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                    <ImageContainer>
                    {profileImage.preview&&                 
                    <CancelIcon
                      onClick={removeImage}
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        right: '2px',
                        marginTop: '-7px',
                        color: '#7149C6',
                        cursor: 'pointer',
                      }}
                    />}
                      <Image
                        src={
                          profileImage.preview
                            ? profileImage.preview
                              : DefaultAvatar
                        }
                      />
                      <Stack
                        direction='row'
                        gap={2}
                        mt={2}
                        sx={{
                          display: 'flex',
                          width: 'inherit',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Button
                            variant='contained'
                            sx={{ backgroundColor: '#9155FD !important', mr: 2 }}
                            component='label'
                          >
                            Upload
                            <input
                              hidden
                              type='file'
                              onChange={handleProfileImageUpload}
                            />
                          </Button>
                        </Box>
                      
                      </Stack>
                    </ImageContainer>
                    <Button
                          onClick={() => setOpenModal(true)}
                          variant='contained'
                          sx={{
                            height:'40px',
                            mt:'15px',
                            backgroundColor: '#9155FD !important',
                          }}
                          component='label'
                        >
                          Reset Password
                          {openModal === true ? (
                            <>
                              <ResetPassword
                                openModal={openModal}
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                              />
                            </>
                          ) : (
                            ''
                          )}
                        </Button>
                    </Box>
                    
                    {openModal && (
                      <ResetPassword
                        openModal={openModal}
                        handleClose={handleClose}
                      />
                    )}
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ sm: 2, md: 5 }}
                    >
                      <Grid container item xs={12} md={6} mb={2}>
                        <Grid item xs={3} alignSelf='center'>
                          <Typography>First Name :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size='small'
                            placeholder='John'
                            variant='outlined'
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} md={6} mb={2}>
                        <Grid item xs={3} alignSelf='center'>
                          <Typography>Last Name :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size='small'
                            placeholder='Doe'
                            variant='outlined'
                            {...getFieldProps('lastName')}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} md={6} mb={2}>
                        <Grid item xs={3} alignSelf='center'>
                          <Typography>Email :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            size='small'
                            fullWidth
                            placeholder='Eg.abc@gmail.com'
                            variant='outlined'
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} md={6} mb={2}>
                        <Grid item xs={3} alignSelf='center'>
                          <Typography>Phone :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size='small'
                            placeholder='9999999999'
                            type='number'
                            variant='outlined'
                            inputProps={{ maxLength: 10 }}
                            {...getFieldProps('mobileNumber')}
                            error={Boolean(
                              touched.mobileNumber && errors.mobileNumber
                            )}
                            helperText={
                              touched.mobileNumber && errors.mobileNumber
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} md={6} mb={2}>
                        <Grid item xs={3}>
                          <Typography>City :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            fullWidth
                            size='small'
                            placeholder='Ahmedabad'
                            variant='outlined'
                            {...getFieldProps('city')}
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} md={6} mb={2}>
                        <Grid item xs={3} alignSelf='center'>
                          <Typography>Address :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            placeholder='Enter Address'
                            variant='outlined'
                            multiline
                            minRows={3}
                            maxRows={4}
                            fullWidth
                            {...getFieldProps('address')}
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    display='flex'
                    columnGap={2}
                    sx={{ mt: 0, justifyContent: 'center', paddingBottom: '10px'}}
                  >
                    <Button
                      variant='contained'
                      sx={{ placeSelf: 'center', backgroundColor: '#9155FD !important' }}
                      type='submit'
                    >
                      {profileLoader ? (
                        <CircularProgress
                          color='inherit'
                          style={{
                            color: '#fff',
                            height: '25px',
                            width: '25px',
                          }}
                        />
                      ) : (
                        'Update'
                      )}
                    </Button>
                    <Link to='/dashboard'>
                      <Button variant='outlined' sx={{ placeSelf: 'center' }}>
                        Cancel
                      </Button>
                    </Link>
                  </Box>
                </ProfileWrapper>}
            </Form>
          </FormikProvider>
        </StyledTableContainer>
      </WrapperItem>
      <DropdownModal
      loading={deleteImageModal}
        title="Are you sure you want to delete image?"
        isOpen={showImageModal}
        handleClose={hadleCloseImage}
        handleClickYes={handleDeleteModalYes}
      />
    </Container>
  );
}
