import AddEditShopForm from './pages/Shop/AddEdit';
import DefaultLayout from './layout/DefaultLayout';
import Login from './pages/Login';
import ExpenseList from './pages/Expense/TableList';
import AddEditUser from './pages/Employee/AddEdit';
import UserManagement from './pages/Employee/TableList';
import Profile from './pages/User/Profile';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './routes';
import ForgotPassWord from './pages/User/ForgotPassword';
import TechnicianList from './pages/Technician/TableList';
import AddEditTechnician from './pages/Technician/AddEdit';
import ResetUserPassword from './pages/User/ResetPassword';
import ShopList from './pages/Shop/TableList';
import OrderList from './pages/Order/TableList';
import AddEditOrder from './pages/Order/Create/Add';
import OrderDetail from './pages/Order/OrderDetail';
import MeasurementList from './pages/Measurement/TableList';


function App() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path='/' element={<Navigate replace to='/login' />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgotPassword' element={<ForgotPassWord />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/dashboard' element={<DefaultLayout />} />
        <Route path='/reset/password' element={<ResetUserPassword />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/shops' element={<ShopList />} />
        <Route path='/shop/add' element={<AddEditShopForm />} />
        <Route path='/shop/edit/:id' element={<AddEditShopForm />} />
        <Route path='/order/add' element={<AddEditOrder />} />
        <Route path="/order/detail/:id" element={<OrderDetail/>}/>
        <Route path='/expenseList' element={<ExpenseList />} />
        <Route path='/orders' element={<OrderList />} />
        <Route path='/users' element={<UserManagement />} />
        <Route path='/user/add' element={<AddEditUser />} />
        <Route path='/user/edit/:id' element={<AddEditUser />} />
        <Route path='/technicians' element={<TechnicianList />} />
        <Route path='/technician/add' element={<AddEditTechnician />} />
        <Route path='/technician/edit/:id' element={<AddEditTechnician />} />
        <Route path='/measurements' element={<MeasurementList />} />

      </Route>
    </Routes>
  );
}

export default App;
