import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '6px',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  boxShadow: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
}));

const ViewMeasurementModal = ({ handleClose, open, measurement }: any) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              padding: '20px',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <Typography
            gutterBottom
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              textAlign: 'start',
              alignItems: 'flex-start',
              justifyContent: 'center',
              marginTop: -1,
            }}
          >
            Measurement Data
          </Typography>
        </Box>
        <Box sx={{ border: '0.5px solid rgba(0, 0, 0, 0.1)' }}></Box>
        <Box
          sx={{
            display: 'flex',
            width: 310,
            gap: '41px',
            marginTop: 2,
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'start',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            customer Name :
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              textAlign: 'start',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            {measurement?.customerName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: 310,
            gap: '25px',
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'start',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            customer Number :
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              textAlign: 'start',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            {measurement?.customerNumber}
          </Typography>
        </Box>

        <Box
          sx={{
            width: '94%',
            mt: 2,
            borderRadius: 2,
          }}
        >
          <Grid container spacing={3} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Length :</Typography>
                <Typography>{measurement?.measurement.length}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>U.Chest :</Typography>
                <Typography>{measurement?.measurement.uChest}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Chest :</Typography>
                <Typography>{measurement?.measurement.chest}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Waist :</Typography>
                <Typography>{measurement?.measurement.waist}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>L-Waist :</Typography>
                <Typography>{measurement?.measurement.lWaist}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Sheet :</Typography>
                <Typography>{measurement?.measurement.sheet}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Hips :</Typography>
                <Typography>{measurement?.measurement.hips}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Knee :</Typography>
                <Typography>{measurement?.measurement.knee}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Ankle :</Typography>
                <Typography>{measurement?.measurement.ankle}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Shoulder :</Typography>
                <Typography>{measurement?.measurement.shoulder}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Sleeve Length :</Typography>
                <Typography>{measurement?.measurement.sleeveLength}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Armhole :</Typography>
                <Typography>{measurement?.measurement.armhole}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Biceps :</Typography>
                <Typography>{measurement?.measurement.biceps}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Around Elbow :</Typography>
                <Typography>{measurement?.measurement.aroundElbow}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Around Wrist :</Typography>
                <Typography>{measurement?.measurement.aroundWrist}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Front Neck :</Typography>
                <Typography>{measurement?.measurement.frontKneck}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Back Neck :</Typography>
                <Typography>{measurement?.measurement.backKneck}</Typography>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography fontWeight={'bold'}>Bottom Length :</Typography>
                <Typography>{measurement?.measurement.bottomLength}</Typography>
              </Item>
            </Grid>
            <Grid item xs={10.5}>
              <Item>
                <Typography fontWeight={'bold'} fontSize={'17px'}>Notes :</Typography>
                <Typography fontSize={'17px'}>{measurement?.notes}</Typography>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewMeasurementModal;
