import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  addOrderApi,
  addPaymentApi,
  deleteOrder,
  deletePaymentApi,
  editOrderApi,
  editOrderStatusApi,
  editPaymentApi,
  getOrderApi,
  getOrderDetail,
  userDasboardApi,
} from '../../services';
import {
  ADD_CUSTOMER_PAYMENT_FAILURE,
  ADD_CUSTOMER_PAYMENT_SUCCESS,
  ADD_ORDER_FAILURE,
  ADD_ORDER_SUCCESS,
  DELETE_CUSTOMER_PAYMENT_FAILURE,
  DELETE_CUSTOMER_PAYMENT_SUCCESS,
  DELETE_ORDER_FAILURE,
  DELETE_ORDER_SUCCESS,
  EDIT_CUSTOMER_ORDER_FAILURE,
  EDIT_CUSTOMER_ORDER_SUCCESS,
  EDIT_CUSTOMER_PAYMENT_FAILURE,
  EDIT_CUSTOMER_PAYMENT_SUCCESS,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_STATUS_FAILURE,
  EDIT_ORDER_STATUS_SUCCESS,
  EDIT_ORDER_SUCCESS,
  GET_EXPENSE_LIST_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  IS_LOADING,
  ORDER_DETAIL_FAILURE,
  ORDER_DETAIL_SUCCESS,
  USER_DASHBOARD_SUCCESS,
} from '../constant';
import customHistory from '../../routes/history';
import store from '..';


export function* getOrderSaga(action: any): any {
  try {
    const response = yield call(getOrderApi, action.payload);
    yield put({
      type: GET_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_ORDER_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addOrderSaga(action: any): any {
  yield put({
    type: IS_LOADING,
    payload: true,
  });
  try {
    const response = yield call(addOrderApi, action.payload.payload);
    const orderList = store.getState().order;
    const addedOrderList={
      total:orderList?.orderListData?.total,
     data:orderList?.orderListData?.data
   }
     yield put({
         type: GET_ORDER_SUCCESS,
       payload:addedOrderList,
       });
     yield put({
      type: ADD_ORDER_SUCCESS,
      payload: response.data.data,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
    customHistory.push('/orders');
    toast(response.data.message);
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
   
   
  } catch (error: any) {
    yield put({
      type: ADD_ORDER_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editOrderSaga(action: any): any {
  try {
    const response = yield call(editOrderApi, action.payload);
    yield put({
      type: EDIT_ORDER_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload?._id})
    
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
    const orderDataResponse=yield call(getOrderApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10, status: '', paymentMode: '', fromDate: '', toDate: ''
    })
    yield put({
         type: GET_ORDER_SUCCESS,
       payload: orderDataResponse.data,
       });
       const dashboardDataResponse = yield call(userDasboardApi, {
        frequency: 'daily',
      });
      yield put({
        type: USER_DASHBOARD_SUCCESS,
        payload: dashboardDataResponse.data.data,
      });
 
  } catch (error: any) {
    yield put({
      type: EDIT_ORDER_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
   
  }
}

export function* editCustomerOrderSaga(action: any): any {
  try {
    const response = yield call(editOrderApi, action.payload);
    yield put({
      type: EDIT_CUSTOMER_ORDER_SUCCESS,
      payload: response.data.data,
    });
  
    toast(response.data.message);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload?._id})
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
    const orderDataResponse=yield call(getOrderApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10, status: '', paymentMode: '', fromDate: '', toDate: ''
    })
    
    yield put({
      type: GET_ORDER_SUCCESS,
      payload: orderDataResponse.data,
    });
  
  } catch (error: any) {
    yield put({
      type: EDIT_CUSTOMER_ORDER_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}


export function* editOrderStatusSaga(action: any): any {
  try {
    const response = yield call(editOrderStatusApi, action.payload);
    const orderDataResponse=yield call(getOrderApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10,status: '', paymentMode: '', fromDate: '', toDate: ''
    })
       yield put({
         type: GET_ORDER_SUCCESS,
       payload: orderDataResponse.data,
       });
    yield put({
      type: EDIT_ORDER_STATUS_SUCCESS,
      payload: response.data.data,
    });
   
    toast(response.data.message);
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
  } catch (error: any) {
    yield put({
      type: EDIT_ORDER_STATUS_FAILURE,
      payload: error.response.data.message,
    });
    toast(error.response.data.message);
  }
}

export function* deleteOrderSaga(action: any): any {
  try {
    const response = yield call(deleteOrder, action.payload.payload);
    const orderList = store.getState().order;
 
    const deleteOrderData = orderList?.orderListData?.data.filter(
    (item: any) => item._id !== action.payload.payload.id
    );
    const deleteOrderList={
      total:orderList?.orderListData.total,
     data:deleteOrderData
   }
     yield put({
       type: GET_ORDER_SUCCESS,
     payload: deleteOrderList,
     });
     
    const expenseList = store.getState().expenseList;
 
    const deleteExpenseData = expenseList?.expenseData?.data.filter(
    (item: any) => item._id !== action.payload.payload.id
    );
    const deleteExpenseList={
      total:expenseList?.expenseData?.total,
     data:deleteExpenseData
   }
   yield put({
         type: GET_EXPENSE_LIST_SUCCESS,
       payload: deleteExpenseList,
       });
     yield put({
      type: DELETE_ORDER_SUCCESS,
    });
    toast(response.data.message);
       const dashboardDataResponse = yield call(userDasboardApi, {
        frequency: 'daily',
      });
      yield put({
        type: USER_DASHBOARD_SUCCESS,
        payload: dashboardDataResponse.data.data,
      });
   } catch (error: any) {
    yield put({
      type: DELETE_ORDER_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* getOrderDetailSaga(action: any): any {
  try {
    const response = yield call(getOrderDetail, action.payload);
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error: any) {
    yield put({
      type: ORDER_DETAIL_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addPaymentSaga(action: any): any {
  try {
    const response = yield call(addPaymentApi, action.payload.payload);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload?._id})
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
    yield put({
      type: ADD_CUSTOMER_PAYMENT_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    const orderDataResponse=yield call(getOrderApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10, status: '', paymentMode: '', fromDate: '', toDate: ''
    })
    
    yield put({
      type: GET_ORDER_SUCCESS,
      payload: orderDataResponse.data,
    });
  
  } catch (error: any) {
    yield put({
      type: ADD_CUSTOMER_PAYMENT_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editPaymentSaga(action: any): any {
  try {
    const response = yield call(editPaymentApi, action.payload.payload);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload?._id})
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
    yield put({
      type: EDIT_CUSTOMER_PAYMENT_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
  } catch (error: any) {
    yield put({
      type: EDIT_CUSTOMER_PAYMENT_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* deletePaymentSaga(action: any): any {
  try {
    const response = yield call(deletePaymentApi, action.payload.payload);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload?.payload?._id})
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
    
    yield put({
      type: DELETE_CUSTOMER_PAYMENT_SUCCESS,
    });
    toast(response.data.message);
    const orderDataResponse=yield call(getOrderApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10, status: '', paymentMode: '', fromDate: '', toDate: ''
    })
    
    yield put({
      type: GET_ORDER_SUCCESS,
      payload: orderDataResponse.data,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_CUSTOMER_PAYMENT_FAILURE,
      payload: error.response.data.message,
    });
  }
}
