import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const getToken = () => {
  return window.localStorage.getItem('userToken') || '';
};

export const apiClient = async ({
  url,
  headers,
  ...config
}: AxiosRequestConfig): Promise<AxiosPromise | any> => {
  const token = getToken();
  try {
    const res = await axios({
      url: `${BASE_URL}/${url}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      ...config,
    });
    return res;
  } catch (err: any) {
    throw err;
  }
};


export const apiClientOrder = async ({
  url,
  headers,
  ...config
}: AxiosRequestConfig): Promise<AxiosPromise | any> => {
  const token = getToken();
  try {
    const res = await axios({
      url: `${BASE_URL}/${url}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      ...config,
    });
    return res;
  } catch (err: any) {
    throw err;
  }
};
