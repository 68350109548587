import {
  EDIT_SHOP_STATUS_BEGIN,
  EDIT_SHOP_STATUS_FAILURE,
  EDIT_SHOP_STATUS_SUCCESS,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
editShopStatusData:[],
   error: null,
   isLoading: false,
 };
 
 const editShopStatusReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case EDIT_SHOP_STATUS_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case EDIT_SHOP_STATUS_SUCCESS:
       return { ...state, loading: false, editShopStatusData:action.payload};

     case EDIT_SHOP_STATUS_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default editShopStatusReducer;