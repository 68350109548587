import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import styled from '@emotion/styled';

ChartJS.register(ArcElement, Tooltip, Legend);
const Article = styled('article')({
  height:'100%', paddingBottom:'10px',
  '@media (max-width:1024px)': {    
    height:'100%',
    paddingLeft:'10px',
    width:'auto' ,
  },
});

const PeiChart = (props: any) => {
  return (
    <Article className="canvas-container1">
      <Doughnut data={props.data}/>
   </Article>
  );
};

export default PeiChart;
