export const monthLabel = (id: number) => {
  if (id === 1) {
    return 'Jan';
  }
  if (id === 2) {
    return 'Feb';
  }
  if (id === 3) {
    return 'Mar';
  }
  if (id === 4) {
    return 'Apr';
  }
  if (id === 5) {
    return 'May';
  }
  if (id === 6) {
    return 'Jun';
  }
  if (id === 7) {
    return 'Jul';
  }
  if (id === 8) {
    return 'Aug';
  }
  if (id === 9) {
    return 'Sep';
  }
  if (id === 10) {
    return 'Oct';
  }
  if (id === 11) {
    return 'Nov';
  }
  if (id === 12) {
    return 'Dec';
  }
};
