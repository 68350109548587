import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MeasurementSearchModal from './MeasureMentSearch';

export default function MeasurementConfirmationModal(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openSearch, setOpenSearch] = React.useState(false);
  const handleCloseSearch = () => {
    setOpenSearch(false);
  }
  return (
    <React.Fragment>
      <Dialog
        sx={{ alignContent: 'center' }}
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby='confirmation-dialog-title'
      >
        <DialogTitle
          sx={{
            m: 3,
            p: 3,
          }}
          style={{ outline: 'none', marginBottom: '5px' }}
        >
          {'Do you want to Add measurements?'}
          <IconButton
            aria-label='close'
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
            right: 8,
            top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogActions
          style={{  
            marginTop: 'auto',
            outline: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '5px'
          }}
        >
          <Button
            sx={{ fontSize: 15, fontWeight: 'bold' }}
            variant='contained'
            autoFocus
            style={{ backgroundColor: '#9155FD' }}
            onClick={() => { setOpenSearch(true) }}
          >
            Add
          </Button>
          <Button
            variant='outlined'
            sx={{ fontSize: 15, fontWeight: 'bold' }}
            style={{ borderColor: '#9155FD', color: '#9155FD' }}
            onClick={() => {
              props.handleClose();
              props.handleSubmit();
            }}           
            autoFocus
          >
            Create Order
          </Button>
        </DialogActions>
      </Dialog>
      <MeasurementSearchModal
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        handleCloseSearch={handleCloseSearch}
        handleSubmitCallback={props.handleSubmitCallback}
      />
    </React.Fragment>
  );
}
