import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useFormik, FormikProvider } from 'formik';
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Modal,
  IconButton,
  TextareaAutosize,
  Divider,
} from '@mui/material';
import {
  AddEditMeasurementValidationSchema,
} from '../../utils/validations';
import { CircularProgress } from '@mui/material';
import { deleteImageAction } from '../../store/image/action';
import DropdownModal from '../../components/ConfirmationModal';
import CloseIcon from '@mui/icons-material/Close';
import {
  createMeasurement,
  editMeasurement,
} from '../../store/mesurement/action';
import { editOrder } from '../../store/order/action';

const Orderheading = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const StyledTableContainer = styled(Box)(({ theme }) => ({
  marginTop: '16px',
  height: '70vh',
  overflowY: 'auto !important' as 'auto',
}));
const CardWrapper = styled(Box)({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: '77vh',
});
const ButtonWrapper = styled(Box)({
  display: 'flex',
  paddingBottom: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '10px',
  marginTop: '20px',
});
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};
const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const TextareaElement = styled(TextareaAutosize, {
  shouldForwardProp: (prop) =>
    !['ownerState', 'minRows={3}', 'maxRows'].includes(prop.toString()),
})(
  ({ theme }) => `
  width: 80%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px 8px 0 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  &:hover {
    border-color: ${blue[400]};
  }
  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[700] : blue[200]
    };
  }
  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const AddEditMeasurement = (props: any) => {
  const [profileImage, setProfileImage] = useState<any>({
    file: null,
    preview: '',
  });
  const [measurementDate, setMeasuremnetDate] = useState();
  const mesurementList = useSelector(
    (state: any) => state.mesurementList?.measurementData?.data
  );
  const [showImageModal, setShowImageModal] = useState(false);
  const auth = useSelector((state: any) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const isLoader = useSelector((state: any) => state?.mesurementList.isLoading);
  const updateLoader = useSelector(
    (state: any) => state?.editMeasurement.isLoading
  );
  const deleteImageModal = useSelector(
    (state: any) => state.deleteImage?.loading
  );

  const formik = useFormik({
    initialValues: {
      customerName: '',
      customerNumber: '',
      length: '',
      uchest: '',
      chest: '',
      waist: '',
      lwaist: '',
      sheet: '',
      hips: '',
      knee: '',
      ankle: '',
      shoulder: '',
      sleeveLength: '',
      armhole: '',
      biceps: '',
      aroundElbow: '',
      aroundWrist: '',
      frontNeck: '',
      backNeck: '',
      bottomLength: '',
      notes: '',
    },
    validationSchema: AddEditMeasurementValidationSchema,
    onSubmit: async (values) => {
      var customerData = {
        customerName: values.customerName,
        customerNumber: values.customerNumber,
        measurements: {
          length: values.length,
          uChest: values.uchest,
          chest: values.chest,
          waist: values.waist,
          lWaist: values.lwaist,
          sheet: values.sheet,
          hips: values.hips,
          knee: values.knee,
          ankle: values.ankle,
          shoulder: values.shoulder,
          sleeveLength: values.sleeveLength,
          armhole: values.armhole,
          biceps: values.biceps,
          aroundElbow: values.aroundElbow,
          aroundWrist: values.aroundWrist,
          frontKneck: values.frontNeck,
          backKneck: values.backNeck,
          bottomLength: values.bottomLength,
          date: measurementDate,
        },

        notes: values.notes,
      };

      const formData = new FormData();

      if (props.editedData) {
        formData.append('customerName', customerData.customerName);
        formData.append('customerNumber', customerData.customerNumber);
        formData.append('notes', customerData.notes);
        formData.append(
          'measurements',
          JSON.stringify(customerData.measurements)
        );
        formData.append('measurementId', props.editedData._id);

        dispatch(editMeasurement(formData));
        setUpdateButtonClicked(true);
      } else {
        formData.append('customerName', customerData.customerName);
        formData.append('customerNumber', customerData.customerNumber);
        formData.append('notes', customerData.notes);
        formData.append(
          'measurements',
          JSON.stringify(customerData.measurements)
        );
        dispatch(
          createMeasurement({ payload: formData, measurementId: auth.data._id })
        );
        setSubmitButtonClicked(true);
      }
    },
  });

  const { touched, errors, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  const hadleCloseImage = () => {
    setShowImageModal(false);
  };

  useEffect(() => {
    const formData = new FormData();
    if (
      submitButtonClicked &&
      !isLoader &&
      location.state !== null &&
      mesurementList &&
      mesurementList.length > 0
    ) {
      formData.append('measurementId', mesurementList[0]._id);
      dispatch(editOrder(formData, auth.data._id, location.state));
    }
  }, [!isLoader, location.state]);
  useEffect(() => {
    if (!isLoader && location.state && typeof props.handleCloseSearch === 'function' && props.addCliked) {
      props.handleCloseSearch(true);
    }
  }, [isLoader, location.state, props.handleCloseSearch]);
  useEffect(() => {
    if (
      !updateLoader &&
      updateButtonClicked &&
      location.state !== null &&
      mesurementList &&
      mesurementList.length > 0
    ) {
      const formData = new FormData();
      dispatch(editOrder(formData, auth.data._id, location.state));
    }
  }, [!updateLoader, props.location?.state, updateButtonClicked]);

  const handleDeleteModalYes = () => {
    dispatch(deleteImageAction({ deleteId: location.state._id }));
    setProfileImage({ file: null, preview: '' });
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ffffff !important',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    width: '45%',
    minHeight: '70%',
  };

  useEffect(() => {
    if (props?.editedData) {
      const EditedData = props.editedData;
      const measurement = EditedData?.measurement;
      setFieldValue('customerName', EditedData.customerName);
      setFieldValue('customerNumber', EditedData.customerNumber);
      setFieldValue('notes', EditedData.notes);
      setFieldValue('length', measurement?.length);
      setFieldValue('uchest', measurement?.uChest);
      setFieldValue('chest', measurement?.chest);
      setFieldValue('waist', measurement?.waist);
      setFieldValue('lwaist', measurement?.lWaist);
      setFieldValue('sheet', measurement?.sheet);
      setFieldValue('hips', measurement?.hips);
      setFieldValue('knee', measurement?.knee);
      setFieldValue('ankle', measurement?.ankle);
      setFieldValue('shoulder', measurement?.shoulder);
      setFieldValue('sleeveLength', measurement?.sleeveLength);
      setFieldValue('armhole', measurement?.armhole);
      setFieldValue('biceps', measurement?.biceps);
      setFieldValue('aroundElbow', measurement?.aroundElbow);
      setFieldValue('aroundWrist', measurement?.aroundWrist);
      setFieldValue('frontNeck', measurement?.frontKneck);
      setFieldValue('backNeck', measurement?.backKneck);
      setFieldValue('bottomLength', measurement?.bottomLength);
    }
  }, []);

  const handleChangeInput = (event: any) => {
    setFieldValue(`notes`, event.target.value);
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style}>
        <Orderheading>
          <IconButton
            aria-label='close'
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              padding: '20px',
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant='h5' sx={{ color: '#9155FD', fontWeight: 600 }}>
            {props.editedData ? 'Edit Measurements' : 'Add Measurements'}
          </Typography>
        </Orderheading>
        <StyledTableContainer>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <CardWrapper>
                <Box>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ sm: 2, md: 5 }}
                  >
                    <Grid container item xs={12} md={12} mb={2}>
                      <Grid item xs={5} md={5} alignSelf='center'>
                        <TextField
                          fullWidth
                          size='small'
                          placeholder='Customer Name'
                          variant='outlined'
                          {...getFieldProps('customerName')}
                          error={Boolean(
                            touched.customerName && errors.customerName
                          )}
                          helperText={
                            touched.customerName && errors.customerName
                          }
                        />
                      </Grid>

                      <Grid
                        sx={{ marginLeft: 10 }}
                        item
                        xs={5}
                        md={5}
                        alignSelf='center'
                      >
                        <TextField
                          fullWidth
                          type='number'
                          inputProps={{ maxLength: 10 }}
                          size='small'
                          placeholder='Customer Number'
                          variant='outlined'
                          {...getFieldProps('customerNumber')}
                          error={Boolean(
                            touched.customerNumber && errors.customerNumber
                          )}
                          helperText={
                            touched.customerNumber && errors.customerNumber
                          }
                        />
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        borderColor: 'rgba(58, 53, 65, 0.30)',
                        width: '100%',
                      }}
                    />
                    <Grid container item xs={12} md={12}>
                      <Typography sx={{ fontWeight: '650' }}>
                        Enter a measurements :
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Length :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='length'
                          type='number'
                          variant='outlined'
                          {...getFieldProps('length')}
                          error={Boolean(touched.length && errors.length)}
                          helperText={touched.length && errors.length}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>U.Chest :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          type='number'
                          placeholder='U.Chest'
                          variant='outlined'
                          {...getFieldProps('uchest')}
                          error={Boolean(touched.uchest && errors.uchest)}
                          helperText={touched.uchest && errors.uchest}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Chest :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Chest'
                          type='number'
                          variant='outlined'
                          {...getFieldProps('chest')}
                          error={Boolean(touched.chest && errors.chest)}
                          helperText={touched.chest && errors.chest}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Waist :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          type='number'
                          placeholder='Waist'
                          variant='outlined'
                          {...getFieldProps('waist')}
                          error={Boolean(touched.waist && errors.waist)}
                          helperText={touched.waist && errors.waist}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>L-Waist :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='L-Waist'
                          variant='outlined'
                          type='number'
                          {...getFieldProps('lwaist')}
                          error={Boolean(touched.lwaist && errors.lwaist)}
                          helperText={touched.lwaist && errors.lwaist}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Sheet :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Sheet'
                          variant='outlined'
                          type='number'
                          {...getFieldProps('sheet')}
                          error={Boolean(touched.sheet && errors.sheet)}
                          helperText={touched.sheet && errors.sheet}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Hips :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Hips'
                          variant='outlined'
                          type='number'
                          {...getFieldProps('hips')}
                          error={Boolean(touched.hips && errors.hips)}
                          helperText={touched.hips && errors.hips}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Knee :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Knee'
                          variant='outlined'
                          type='number'
                          {...getFieldProps('knee')}
                          error={Boolean(touched.knee && errors.knee)}
                          helperText={touched.knee && errors.knee}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Ankal :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Ankle'
                          variant='outlined'
                          type='number'
                          {...getFieldProps('ankle')}
                          error={Boolean(touched.ankle && errors.ankle)}
                          helperText={touched.ankle && errors.ankle}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Shoulder :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Shoulder'
                          variant='outlined'
                          {...getFieldProps('shoulder')}
                          type='number'
                          error={Boolean(touched.shoulder && errors.shoulder)}
                          helperText={touched.shoulder && errors.shoulder}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Sleeve Length :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Sleeve Length'
                          variant='outlined'
                          {...getFieldProps('sleeveLength')}
                          type='number'
                          error={Boolean(
                            touched.sleeveLength && errors.sleeveLength
                          )}
                          helperText={
                            touched.sleeveLength && errors.sleeveLength
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Armhole :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Armhole'
                          variant='outlined'
                          type='number'
                          {...getFieldProps('armhole')}
                          error={Boolean(touched.armhole && errors.armhole)}
                          helperText={touched.armhole && errors.armhole}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Biceps :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Biceps'
                          variant='outlined'
                          type='number'
                          {...getFieldProps('biceps')}
                          error={Boolean(touched.biceps && errors.biceps)}
                          helperText={touched.biceps && errors.biceps}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Around Elbow :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Around Elbow'
                          variant='outlined'
                          {...getFieldProps('aroundElbow')}
                          type='number'
                          error={Boolean(
                            touched.aroundElbow && errors.aroundElbow
                          )}
                          helperText={touched.aroundElbow && errors.aroundElbow}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Around Wrist :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Around Wrist'
                          variant='outlined'
                          {...getFieldProps('aroundWrist')}
                          type='number'
                          error={Boolean(
                            touched.aroundWrist && errors.aroundWrist
                          )}
                          helperText={touched.aroundWrist && errors.aroundWrist}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Front Neck :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Front Neck'
                          variant='outlined'
                          {...getFieldProps('frontNeck')}
                          type='number'
                          error={Boolean(touched.frontNeck && errors.frontNeck)}
                          helperText={touched.frontNeck && errors.frontNeck}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5} alignSelf='center'>
                        <Typography>Back Neck :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Back Neck'
                          type='number'
                          variant='outlined'
                          {...getFieldProps('backNeck')}
                          error={Boolean(touched.backNeck && errors.backNeck)}
                          helperText={touched.backNeck && errors.backNeck}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={5}>
                        <Typography>Bottom Length :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          size='small'
                          placeholder='Bottom Length'
                          variant='outlined'
                          {...getFieldProps('bottomLength')}
                          type='number'
                          error={Boolean(
                            touched.bottomLength && errors.bottomLength
                          )}
                          helperText={
                            touched.bottomLength && errors.bottomLength
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      md={12}
                      lg={6}
                      container
                      alignItems={'center'}
                    >
                      <Grid item={true} xs={3} lg={5} alignItems={'center'}>
                        <Typography>Notes :</Typography>
                      </Grid>
                      <Grid item={true} xs={6} lg={7} alignItems={'center'}>
                        <TextareaElement
                          aria-label='Demo input'
                          minRows={3}
                          required={false}
                          placeholder='Notes…'
                          {...getFieldProps('notes')}
                          onChange={(e) => handleChangeInput(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <ButtonWrapper>
                  <Button
                    variant='contained'
                    sx={{
                      backgroundColor: '#9155FD !important',
                      width: '80px',
                    }}
                    type='submit'
                  >
                    {props.editedData ? (
                      updateLoader ? (
                        <CircularProgress size={23} color='inherit' />
                      ) : (
                        'Update'
                      )
                    ) : isLoader ? (
                      <CircularProgress size={23} color='inherit' />
                    ) : (
                      'Submit'
                    )}
                  </Button>

                  <Button variant='outlined' onClick={props.onClose}>
                    Cancel
                  </Button>
                </ButtonWrapper>
              </CardWrapper>
            </Form>
          </FormikProvider>
        </StyledTableContainer>

        <DropdownModal
          loading={deleteImageModal}
          title='Are you sure you want to delete image?'
          isOpen={showImageModal}
          handleClose={hadleCloseImage}
          handleClickYes={handleDeleteModalYes}
        />
      </Box>
    </Modal>
  );
};

export default AddEditMeasurement;
