import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import logo from '../../assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { forgotPasswordAction } from '../../store/auth/action';
import { ForgotPasswordValidationSchema } from '../../utils/validations';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const Container = styled('div')({
  backgroundColor: '#F4F5FA',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex'
});

const CardContent = styled('div')({
  width: '370px',
  backgroundColor: 'white',
  boxShadow: '0px 2px 10px 0px rgb(58 53 65 / 10%)',
  borderRadius: '8px',
  padding: '40px',
  color: 'rgba(58, 53, 65, 0.87)'
});

const Title = styled('div')({
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const StudioAppTitle=styled(Typography)({
  marginLeft: '10px !important',
    fontWeight: '600 !important',
    fontSize: '1.5rem !important'
})

const ButtonStyle=styled(Button)({
  backgroundColor: '#9155FD !important'
})


const ForgotPassWord = () => {
  const dispatch = useDispatch();
  const forgotPasswordLoader = useSelector((state:any)=>state?.auth.loading)
  const initialValues = {
    email: ''
  };
  const handleSubmitLogin = (values: any) => {
    dispatch(forgotPasswordAction(values));
  };
  return (
    <Container>
      <CardContent>
        <Title>
          <img src={logo} alt='' />
          <StudioAppTitle variant='h6'>
            STUDIO APP
          </StudioAppTitle>
        </Title>
        <Formik initialValues={initialValues} onSubmit={handleSubmitLogin} validationSchema={ForgotPasswordValidationSchema}>

          {(props) => (
            <Form>
              <Field
                as={TextField}
                fullWidth
                name='email'
                label='Enter your register email address'
                helperText={<ErrorMessage name='email' />}
                sx={{ mb: 3, color: 'red',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: props?.touched?.email && props?.errors?.email ? 'red' : undefined,
                }, '& .MuiFormHelperText-root': {
                  color:'red',
                 },
               }}
              />
              <Box>
                {!forgotPasswordLoader ? (
                  <ButtonStyle
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Submit
                  </ButtonStyle>
                ) : (
                  <Button fullWidth size='large' type='submit'>
                    <CircularProgress
                      style={{
                        color: '#9155FD',
                        height: '25px',
                        width: '25px'
                      }}
                    />
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Container>
  );
};

export default ForgotPassWord;
