import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { ADD_TRANSACTION_FAILURE, ADD_TRANSACTION_SUCCESS, DELETE_TRANSACTION_FAILURE, DELETE_TRANSACTION_SUCCESS, EDIT_TRANSACTION_FAILURE, EDIT_TRANSACTION_SUCCESS, GET_TRANSACTION_FAILURE, GET_TRANSACTION_SUCCESS } from '../constant';
import { addTransactionApi, deleteTransaction, editTransactionApi, getTransactionApi } from '../../services';
import customHistory from '../../routes/history';
import store from '..';


export function* getTransactionSaga(action: any): any {
    try {
      const response = yield call(getTransactionApi, action.payload);
      yield put({
        type: GET_TRANSACTION_SUCCESS,
        payload: response.data.data,
      });
    } catch (error: any) {
      yield put({
        type: GET_TRANSACTION_FAILURE,
        payload: error.response.data.message,
      });
    }
}
  
export function* addTransactionSaga(action: any): any {
  try {
    const response = yield call(addTransactionApi, action.payload);
    yield put({
      type: ADD_TRANSACTION_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    customHistory.push('/transaction');
  } catch (error: any) {
    yield put({
      type: ADD_TRANSACTION_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* editTransactionSaga(action: any): any {
  try {
    const response = yield call(editTransactionApi, action.payload);
    yield put({
      type: EDIT_TRANSACTION_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    customHistory.push('/transaction');
  } catch (error: any) {
    yield put({
      type: EDIT_TRANSACTION_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* deleteTransactionSaga(action: any): any {
  try {
    const response = yield call(deleteTransaction, action.payload);
    yield put({
      type: DELETE_TRANSACTION_SUCCESS,
    });
    window.location.reload()
    toast(response.data.message);
    const orderList = store.getState().order?.orders;
    
    const orderListAfterDelete = orderList.filter(
      (item: any) => item._id !== action.payload.id
      );
    yield put({
      type: GET_TRANSACTION_SUCCESS,
      payload: orderListAfterDelete,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_TRANSACTION_FAILURE,
      payload: error.response.data.message,
    });
  }
}

