import {
  Box,
  TextField,
  Grid,
  Button,
  CircularProgress,
  FormControl,
  styled,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import DatePickers from '../../../../../components/DatePickers';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #ffffff !important',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  width: '30%',
  height: 'auto',
};

const options = [
  { value: 'cash', label: 'Cash' },
  { value: 'online', label: 'Online' },
];

const ButtonContainer=styled(Button)({
  backgroundColor: '#9155FD !important',
  marginTop: '20px',
  size: 'medium',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
})

const ClearButtonContainer=styled(Button)({
  marginLeft: '3px',
  marginTop: '20px',
  size: 'medium',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
})

const ErrorStyle=styled('div')({
  marginTop: '10px',
  fontSize: '15px',
  color: '#d32f2f',
})


const TechnicianPaymentModal = (props: any) => {
  const location = useLocation();

  const isLoading = useSelector((state:any)=>state?.addTechnicianPayment?.loading);
  const [amount, setAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [remaningAmount, setRemaningAmount] = useState<any>('');
  const [paymentDate, setPaymentDate] = useState('');
  const [amountError, setAmountError] = useState('');
  const [paymentDateError, setPaymentDateError] = useState('');
  const [paymentModeError, setPaymentModeError] = useState('');


  const onSaveData = () => {
    let paymentData = {
      technicianId: props.technicianPaymentData.technicianId,
      orderId: location?.state,
      amount: amount,
      paymentDate: paymentDate,
      paymentMode: paymentMode,
      description:props.technicianPaymentData.description,
      settled:remaningAmount===0?true:false
    };
    let lastData=props.technicianPaymentData.technicianHistory[props.technicianPaymentData.technicianHistory.length - 1];
    if (paymentData.amount === '') {
      setAmountError('Please Enter Amount');
    }
    if (paymentData.paymentMode === '') {
      setPaymentModeError('Please Select PaymentMode');
    }
    if (paymentData.paymentDate === '') {
      setPaymentDateError('Please Select Date');
    }
    if (
      paymentData.amount &&
      parseInt(paymentData.amount) <=lastData.remainingPayment &&
      paymentData.paymentMode &&
      paymentData.paymentDate
    ) {
      props.handleSave(paymentData);
   
  };
}


useEffect(()=>{
  let lastData=props.technicianPaymentData.technicianHistory[props.technicianPaymentData.technicianHistory.length - 1];
  setRemaningAmount(lastData?.remainingPayment)
},[props.technicianPaymentData])


  const onCloseData = () => {
    props.handleClose();
  };

  const onChangeAmount = (event: any) => {
    setAmount(event?.target.value);
    setAmountError('');
    let lastData=props.technicianPaymentData.technicianHistory[props.technicianPaymentData.technicianHistory.length - 1];
    if (event?.target.value === '') {
      setAmountError('Please Enter Amount');
    } else if (
      parseInt(event?.target.value) > lastData?.remainingPayment

    ) {
      setAmountError('Amount should be less than remaning amount');
    }
  
    setRemaningAmount(lastData?.remainingPayment

      - event?.target.value)
  };

  const onChangePaymentMode = (event: any) => {
    setPaymentMode(event.target.value);
    setPaymentModeError('');
    if (event.target.value === '') {
      setPaymentModeError('Please Select PaymentMode');
    }
  };

  const onChangePaymentDate = (value: any) => {
    setPaymentDate(value);
    setPaymentDateError('');
    if (value === '') {
      setPaymentDateError('Please Select Date');
    }
  };

  return (
    <>
      <Modal open={props.open} onClose={props.handleClose}>
        <Box>
        
          <Box sx={style}>
            <TextField
              fullWidth
              size="small"
              placeholder="Amount"
              variant="outlined"
              type="number"
              value={amount}
              onChange={onChangeAmount}
            />

            <ErrorStyle >{amountError}</ErrorStyle>
            <Grid sx={{ marginTop: 2 }}>
              <FormControl fullWidth size='small'>
                <InputLabel>Payment Mode</InputLabel>
                <Select
                  placeholder="payment mode"
                  value={paymentMode}
                  label="PaymentMode"
                  onChange={onChangePaymentMode}
                >
                  {options.map((item, value) => {
                    return <MenuItem value={item.value}>{item.label}</MenuItem>;
                  })}
                </Select>
                <ErrorStyle>{paymentModeError}</ErrorStyle>
              </FormControl>
            </Grid>
            <Grid sx={{ marginTop: 2 }}>
              <DatePickers
              placeholder={'Select date'}
                value={paymentDate}
                onChange={onChangePaymentDate}
              />
              <ErrorStyle>{paymentDateError}</ErrorStyle>
            </Grid>
            <Grid sx={{ marginTop: 2 }}>
              <TextField
                fullWidth
                size='small'
                label='Remaning Amount'
                disabled={true}
                value={remaningAmount}
                placeholder='Remaning Amount'
                variant='outlined'
                type='number'
              />
            </Grid>
            <div>
        </div>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 2, mb: 2, marginBottom: '3px' }}
            >
             
              <ButtonContainer
                onClick={onSaveData}
                type='submit'
                variant='contained'
                color='primary'
              >
                {isLoading ? (
                  <CircularProgress
                    color='inherit'
                    style={{
                      color: '#FFFFFF',
                      height: '25px',
                      width: '25px',
                    }}
                  ></CircularProgress>
                ) : 
                'save'
                }
                </ButtonContainer>
                
              <Box sx={{ marginLeft: 1 }}>
                <ClearButtonContainer
                  onClick={onCloseData}
                  variant='outlined'
                  color='primary'
                >
                  Cancel
                </ClearButtonContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TechnicianPaymentModal;
