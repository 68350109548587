import {
  EDIT_CUSTOMER_ORDER_BEGIN,
  EDIT_CUSTOMER_ORDER_FAILURE,
  EDIT_CUSTOMER_ORDER_SUCCESS,
    IS_LOADING
  } from '../constant';
  
  const initialState: any = {
    loading: false,
    customerdata:[],
    error: null,
    isLoading: false,
    isSuccess:false
  };
  
  const EditCustomerOrderReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case EDIT_CUSTOMER_ORDER_BEGIN:
        return {
          ...state,
          loading: true,
          isSuccess:false
        };
      case EDIT_CUSTOMER_ORDER_SUCCESS:
        return { ...state, loading: false, customerdata:action.payload,isSuccess:true};
     
      case EDIT_CUSTOMER_ORDER_FAILURE:
        return { ...state, error: action.payload };

        case IS_LOADING:
        return {
          ...state,
          loading: false,
          isLoading: action.payload,
          customerdata:action.payload
        };
      default:
        return state;
    }
  };
  
  export default EditCustomerOrderReducer;