import { combineReducers } from 'redux';
import AuthReducer from './auth/reducer';
import { LOGOUT } from './constant';
import orderReducer from './order/reducer';
import shopReducer from './shop/reducer';
import usersReducer from './users/reducer';
import transactionReducer from './transaction/reducer';
import employeesReducer from './employee/reducer';
import envoiceReducer from './receipt/reducer';
import expenseReducer from './expenses/reducer';
import technicianReducer from './technician/reducer';
import expenseOptionReducer from './expenses/expenseOptionReducer';
import expenseListReducer from './expenses/expenseListReducer';
import technicianNameReducer from './technician/technicianNameReducer';
import orderDetailReducer from './order/orderDetailReducer';
import editOrderReducer from './order/EditOrderReducer';
import addCustomerPaymentReducer from './order/addPaymentReducer';
import editCustomerPaymentReducer from './order/editPaymentReducer';
import addTechnicianPaymentReducer from './technician/addTechnicianPaymentReducer';
import editTechnicianPaymentReducer from './technician/editTechnicianPaymentReducer';
import addExpenseReducer from './expenses/addExpenseReducer';
import EditCustomerOrderReducer from './order/EditCustomerOrderReducer';
import dashboardReducer from './users/dashobardReducer';
import editOrderStatusReducer from './order/editOrderStatusReducer';
import editTechnicianStatusReducer from './technician/editTechnicinaStatusReducer';
import editEmployeeStatusReducer from './employee/editEmployeeStatusReducer';
import editExpenseStatusReducer from './expenses/editExpenseStatusReducer';
import editShopStatusReducer from './shop/editShopStatusReducer';
import deleteEmployeeReducer from './employee/deleteEmployeeReducer';
import deleteTechnicianReducer from './technician/deleteTechnicianReducer';
import deleteOrderReducer from './order/deleteOrderReducer';
import deleteShopReducer from './shop/deleteShopReducer';
import deletePaymentReducer from './order/deletePaymentReducer';
import deleteTechnicianPaymentReducer from './order/deleteTechnicianPaymentReducer';
import deleteOrderExpenseReducer from './expenses/deleteExpenseReducer';
import deleteImageReducer from './image/deleteImageReducer';
import deleteOrderImageReducer from './image/deleteOrderImageReducer';
import deleteShopImageReducer from './image/deleteShopImageReducer';
import measurementReducer from './mesurement/reducer';
import editMeasurementReducer from './mesurement/editMeasurementreducer';
import deleteMeasurementReducer from './mesurement/deleteMeasurementreducer';


const appReducer = combineReducers({
  auth: AuthReducer,
  shop: shopReducer,
  users: usersReducer,
  employees: employeesReducer,
  order: orderReducer,
  transaction: transactionReducer,
  envoice: envoiceReducer,
  expense: expenseReducer,
  technician: technicianReducer,
  technicianName:technicianNameReducer,
  expenseOption: expenseOptionReducer,
  expenseList: expenseListReducer,
  orderDetail:orderDetailReducer,
  editOrderDetails:editOrderReducer,
  addCustomerPayment:addCustomerPaymentReducer,
  editCustomerPayment:editCustomerPaymentReducer,
  addTechnicianPayment:addTechnicianPaymentReducer,
  editTechnicianPayment:editTechnicianPaymentReducer,
  addExpense:addExpenseReducer,
  editcustomerOrderDetails:EditCustomerOrderReducer,
  dashboardDetails:dashboardReducer,
  editOrderStatus:editOrderStatusReducer,
  editTechnicianStatus:editTechnicianStatusReducer,
  editEmployeeStatus:editEmployeeStatusReducer,
  editExpenseStatus:editExpenseStatusReducer,
  editShopStatus:editShopStatusReducer,
  deleteEmployeeData:deleteEmployeeReducer,
  deleteTechnician:deleteTechnicianReducer,
  deleteOrderData:deleteOrderReducer,
  deleteShopData:deleteShopReducer,
  deleteCustomerPayment:deletePaymentReducer,
  deleteTechnicianPayment:deleteTechnicianPaymentReducer,
  deleteOrderExpense:deleteOrderExpenseReducer,
  deleteImage:deleteImageReducer,
  deleteOrderImage:deleteOrderImageReducer,
  deleteShopImage:deleteShopImageReducer,
  mesurementList:measurementReducer,
  deleteMeasurement:deleteMeasurementReducer,
  editMeasurement:editMeasurementReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
