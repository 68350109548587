import {
  DELETE_SHOP_BEGIN,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAILURE,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
deleteShopData:[],
   error: null,
   isLoading: false,
 };
 
 const deleteShopReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case DELETE_SHOP_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case DELETE_SHOP_SUCCESS:
       return { ...state, loading: false, deleteShopData:action.payload};

     case DELETE_SHOP_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default deleteShopReducer;