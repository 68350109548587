import {
  IS_LOADING,
  ADD_EXPENSES_BEGIN,
  ADD_EXPENSES_SUCCESS,
  ADD_EXPENSES_FAILURE
} from '../constant';

const initialState: any = {
  loading: false,
  addExpenseData: [],
  error: null,
  isLoading: false,
  isSuccess: false,
};

const addExpenseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_EXPENSES_BEGIN:
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };
    case ADD_EXPENSES_SUCCESS:
      return { ...state, loading: false, addExpenseData:action.payload, isSuccess: true};
    case ADD_EXPENSES_FAILURE:
      return { ...state, error: action.payload };
    case IS_LOADING:
      return {
        ...state,
        loading: false,
        addPaymentData: action.payload,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default addExpenseReducer;
