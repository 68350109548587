import {
  ORDER_DETAIL_BEGIN,
  ORDER_DETAIL_FAILURE,
  ORDER_DETAIL_SUCCESS,
  IS_LOADING
} from '../constant';

const initialState: any = {
  loading: false,
  orderDetailData: [],
  error: null,
  isLoading: false
};

const orderDetailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ORDER_DETAIL_BEGIN:
      return {
        ...state,
        loading: true
      };
    case ORDER_DETAIL_SUCCESS:
      return { ...state, loading: false, orderDetailData:action.payload};
    case ORDER_DETAIL_FAILURE:
      return { ...state, error: action.payload };
    case IS_LOADING:
      return {
        ...state,
        loading: false,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default orderDetailReducer;
