import {
  IS_LOADING,
  GET_EXPENSE_OPTION_BEGIN,
  GET_EXPENSE_OPTION_SUCCESS,
  GET_EXPENSE_OPTION_FAILURE,
} from '../constant';

const initialState: any = {
  loading: false,
  expenseOption: [],
  error: null,
  isLoading: false,
};

const expenseOptionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EXPENSE_OPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_EXPENSE_OPTION_SUCCESS:
      return { ...state, loading: false, expenseOption: action.payload };
    case GET_EXPENSE_OPTION_FAILURE:
      return { ...state, error: action.payload };

    case IS_LOADING:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default expenseOptionReducer;
