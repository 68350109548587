import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ onChange, options, value,placeholder }: any) => {
  const defaultValue = (options:any, selectedValue: any) => {
    const defaultOption = options ? options.find((option: any) => {
      return option.label == selectedValue;
    }) : null;
    return defaultOption
  }; 
  return (
    <div>
      <Select 
       value={defaultValue(options, value)}
       onChange={(selectedOption: any) => onChange(selectedOption)}
        options={options}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomSelect;
