import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

const DatePickers = ({ value, onChange ,placeholder,minDate,style}: any) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
        minDate={minDate}
         label={placeholder} 
         inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}

          renderInput={(params) => (
            <TextField
            style={style}
              fullWidth
              {...params}
              size='small'
              className='myDatePicker'
              InputLabelProps={{
                sx: { color: "black", "&.Mui-focused": { color: "black" }, "&.Mui-error": { color: "black",opacity:'0.5' } },
              }}
              variant="outlined"
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePickers;
