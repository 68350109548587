import { call, put } from 'redux-saga/effects';
import store from '..';
import customHistory from '../../routes/history';
import {
  ADD_USERS_FAILURE,
  ADD_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
  DELETE_USERS_SUCCESS,
  EDIT_USERS_FAILURE,
  EDIT_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  IS_LOADING,
  USER_DASHBOARD_FAILURE,
  USER_DASHBOARD_SUCCESS
} from '../constant';
import {
  addUserApi,
  deleteUserApi,
  editUserApi,
  getUsersApi,
  userDasboardApi
} from '../../services';
import { toast } from 'react-toastify';

export function* getUsersSaga(): any {
  try {
    const response = yield call(getUsersApi);
    yield put({
      type: GET_USERS_SUCCESS,
      payload: response.data
    });
  } catch (error: any) {
    yield put({
      type: GET_USERS_FAILURE,
      payload: error.response.data.message
    });
  }
}

export function* addUserSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true
    });
    const response = yield call(addUserApi, action.payload);
    const getUserResponse=yield call(getUsersApi)
       yield put({
         type: GET_USERS_SUCCESS,
       payload: getUserResponse.data,
       });
    yield put({
      type: ADD_USERS_SUCCESS,
      payload: response.data.data
    });
    toast(response.data.message);
    yield put({
      type: IS_LOADING,
      payload: false
    });
    customHistory.push('/users');
  } catch (error: any) {
    yield put({
      type: ADD_USERS_FAILURE,
      payload: error.response.data.message
    });
    yield put({
      type: IS_LOADING,
      payload: false
    });
  }
}
export function* UserDashboardSaga(action: any): any {
  try {
    const response = yield call(userDasboardApi, action.payload);
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: response.data.data
    });
  } catch (error: any) {
    yield put({
      type: USER_DASHBOARD_FAILURE,
      payload: error.response.data.message
    });
  }
}

export function* editUserSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true
    });
    const response = yield call(editUserApi, action.payload);
    const getUserResponse=yield call(getUsersApi)
    yield put({
      type: GET_USERS_SUCCESS,
    payload: getUserResponse.data,
    });
    yield put({
      type: EDIT_USERS_SUCCESS,
      payload: response.data.data
    });
    toast(response.data.message);
    customHistory.push('/dashboard');
    yield put({
      type: IS_LOADING,
      payload: false
    });
  } catch (error: any) {
    yield put({
      type: EDIT_USERS_FAILURE,
      payload: error.response.data.message
    });
    yield put({
      type: IS_LOADING,
      payload: false
    });
  }
}

export function* editUserStatusSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: false
    });
    const response = yield call(editUserApi, action.payload);
    yield put({
      type: EDIT_USERS_SUCCESS,
      payload: response.data.data
    });
    toast(response.data.message);
    window.location.reload();
    yield put({
      type: IS_LOADING,
      payload: false
    });
    customHistory.push('/users');
  } catch (error: any) {
    yield put({
      type: EDIT_USERS_FAILURE,
      payload: error.response.data.message
    });
    yield put({
      type: IS_LOADING,
      payload: false
    });
  }
}

export function* deleteUserSaga(action: any): any {
  try {
    const response = yield call(deleteUserApi, action.payload);
    yield put({
      type: DELETE_USERS_SUCCESS
    });
    window.location.reload();
    toast(response.data.message);
    const users = store.getState().users.data;
    const ItemsAfterDelete = users.filter(
      (item: any) => item._id !== action.payload.id
    );
    yield put({
      type: GET_USERS_SUCCESS,
      payload: ItemsAfterDelete
    });
  } catch (error: any) {
    yield put({
      type: DELETE_USERS_FAILURE,
      payload: error.response.data.message
    });
  }
}
