import {
  ADD_EMPLOYEE_BEGIN,
  DELETE_EMPLOYEE_BEGIN,
  EDIT_EMPLOYEE_BEGIN,
  EDIT_EMPLOYEE_STATUS_BEGIN,
  GET_EMPLOYEE_BEGIN,
  IS_LOADING,
} from '../constant';

export const getEmployees = (payload: any) => ({
  type: GET_EMPLOYEE_BEGIN,
  payload,
});

export const addEmployee = (payload: any) => ({
  type: ADD_EMPLOYEE_BEGIN,
  payload,
});

export const editEmployee = (emp: any, id: any) => ({
  type: EDIT_EMPLOYEE_BEGIN,
  payload: { emp, id },
});
export const editEmployeeStatus = (payload: any) => ({
  type: EDIT_EMPLOYEE_STATUS_BEGIN,
  payload,
});

export const deleteEmployee = (payload: any) => ({
  type: DELETE_EMPLOYEE_BEGIN,
  payload,
});
export const isLoading = (payload: any) => ({
  type: IS_LOADING,
  payload,
});
