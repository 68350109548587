import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Typography,
} from '@mui/material';
import AddEditMeasurement from '../pages/Measurement/AddEditMeasurement';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { editOrder } from '../store/order/action';
import { useDispatch } from 'react-redux';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'left',
  display: 'flex',
  boxShadow: 'none',
  justifyContent: 'space-between',
}));

export default function RowAndColumnSpacing(props: any) {
  const measurement = props.mesurementList;
  const [showFullDetails, setShowFullDetails] = React.useState(false);
  const [isAddModal, setIsModal] = React.useState(false);
  const [addCliked, setAddCliked] = React.useState(false);
  const [handleAddMeasurement, setHandleAddMeasurement] = React.useState(false);
  const isLoader = useSelector((state: any) => state?.mesurementList.isLoading);
  const [selectedMeasurementIndex, setSelectedMeasurementIndex] =
    React.useState(0);
  let name: any = [];
  measurement.map((item: any) => {
    name = item;
  });

  React.useEffect(() => {
    if (!isLoader) {
      setIsModal(false);
    }
  }, [!isLoader]);
  const handleRadioChange = (index: number) => {
    setSelectedMeasurementIndex(index);
  };
  const mesurementList = useSelector(
    (state: any) => state.mesurementList?.measurementData?.data
  );
  const auth = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const isUpdateData = useSelector((state: any) => state?.order.isLoading);
  const isOrderUpdated = useSelector(
    (state: any) => state.editOrderDetails.loading
  );
  const location = useLocation();
  const handleUpdateCallback = () => {
    const formData = new FormData();
    if (!isLoader && location.state !== null) {
      formData.append(
        'measurementId',
        mesurementList[selectedMeasurementIndex]._id
      );
      dispatch(editOrder(formData, auth.data._id, location.state));
      
    }
    setHandleAddMeasurement(true);

  };
  React.useEffect(() => {
    if (!isOrderUpdated && handleAddMeasurement) {
      props.handleCloseSearch(true)
    }
  }, [isOrderUpdated]);
  return (
    <>
      <Box>
        <Typography
          gutterBottom
          sx={{
            fontSize: 17,
            fontWeight: 'bold',
            textAlign: 'start',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          Measurement Data
        </Typography>
      </Box>
      <DialogContentText>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: 2,
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'start',
              alignItems: 'flex-start',
              mr:2,
              justifyContent: 'center',
            }}
          >
            customer Name:
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'start',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            {name.customerName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '60%',
            justifyContent: 'space-between',
            marginTop: 2,
          }}
        >
            <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'start',
              mr:2,
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            customer Number:
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'start',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            {name.customerNumber}
          </Typography>
       
        </Box>
      </DialogContentText>
      {measurement.map((measurement: any, index: any) => {
        const { measurement: CustomerData } = measurement;
        const { date, ...filteredCustomerData } = CustomerData;
        const isSectionExpanded =
          index === selectedMeasurementIndex && showFullDetails;
        return (
          <div key={index} onClick={() => handleRadioChange(index)}>
            <Box key={index} sx={{ width: '100%', mt: 2, mb: 2 }}>
              <Box
                sx={{
                  width: '100%',
                  boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
                  padding: 2,
                  borderRadius: 2,
                }}
              >
                <Grid item xs={12}>
                  <input
                    type='radio'
                    name='selectMeasurement'
                    checked={selectedMeasurementIndex === index}
                    onChange={() => handleRadioChange(index)}
                  />
                </Grid>
                <Box sx={{ width: '100%', mt: 2 }}>
                  <Grid
                    container
                    spacing={3}
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {Object.entries(filteredCustomerData)
                      .slice(0, 5)
                      .map(([key, value]) => (
                        <Grid item xs={6} key={key}>
                          <Item>
                            <Typography sx={{fontWeight:600}}>{key} :</Typography>
                            <Typography>
                              {' '}
                              {typeof value === 'string' ||
                              (typeof value === 'number' && !isNaN(value))
                                ? value
                                : ''}
                            </Typography>
                          </Item>
                        </Grid>
                      ))}

                    {isSectionExpanded &&
                      Object.entries(filteredCustomerData)
                        .slice(10)
                        .map(([key, value]) => (
                          <Grid item xs={6} key={key}>
                            <Item>
                              <Typography sx={{fontWeight:600}}>{key} :</Typography>
                              <Typography>
                                {' '}
                                {typeof value === 'string' ||
                                (typeof value === 'number' && !isNaN(value))
                                  ? value
                                  : ''}
                              </Typography>
                            </Item>
                          </Grid>
                        ))}
                  </Grid>
                  {Object.entries(filteredCustomerData).length > 10 && (
                    <Grid item xs={12}>
                      <Button
                        onClick={() => setShowFullDetails(!isSectionExpanded)}
                        sx={{ color: '#9155FD', fontWeight: 'bold' }}
                      >
                        {isSectionExpanded ? 'See Less' : 'See More'}
                      </Button>
                    </Grid>
                  )}
                </Box>
              </Box>
            </Box>
          </div>
        );
      })}
      <DialogActions
        style={{
          margin: 'auto',
          outline: 'none',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{ fontSize: 15, fontWeight: 'bold' }}
          variant='contained'
          autoFocus
          style={{ backgroundColor: '#9155FD' }}
          onClick={() => {
            if (!location.state) {
              props.handleSubmitCallback(measurement[selectedMeasurementIndex]?._id);
            } else {
              handleUpdateCallback()
            }
          }}
        >
          {isUpdateData || isOrderUpdated ? (
            <CircularProgress
              color='inherit'
              style={{
                color: '#FFFFFF',
                height: '25px',
                width: '25px',
              }}
            />
          ) : (
            ' Add Current'
          )}
         
        </Button>
        <Button
          variant='outlined'
          sx={{ fontSize: 15, fontWeight: 'bold' }}
          style={{ borderColor: '#9155FD', color: '#9155FD' }}
          autoFocus
          onClick={() => {
            setAddCliked(true);
            setIsModal(true);
          }}
        >
          Add New
        </Button>
      </DialogActions>
      <AddEditMeasurement
        open={isAddModal}
        handleCloseSearch= {props.handleCloseSearch}
        addCliked={addCliked}
        onClose={() => {
          setIsModal(false);
        }}
      />
    </>
  );
}
