import * as Yup from 'yup';

export const LoginValidationSchema = Yup.object({
  email: Yup.string().email('Enter valid email').required('Required'),
  password: Yup.string()
    .min(6, 'Password minimum length should be 6')
    .required('Required'),
});
export const ForgotPasswordValidationSchema = Yup.object({
  email: Yup.string().email('Enter valid email').required('Required'),
});

export const ResetPassWordValidationScheme = Yup.object({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  setNewPassWord: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match with new password')
    .required('Required'),
});

export const ExpenseValidationScheme = Yup.object({
  amount: Yup.string().required('Required'),
});

export const ResetValidationSchema = Yup.object({
  password: Yup.string()
    .min(6, 'Password minimum length should be 6')
    .required('Required'),
});

export const AddEditShopValidationSchema = Yup.object().shape({
  shopName: Yup.string().required('Required'),
  shopEmail: Yup.string()
    .email('Enter a valid email')
    .required('Required'),
  shopAddress: Yup.string().required('Required'),
  shopCity: Yup.string().required('Required'),
  shopNumber: Yup.string().min(10).max(10).required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Required'),
  mobileNumber: Yup.string()
    .min(10)
    .max(10)
    .required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
});

export const AddOrderValidationSchema = Yup.object().shape({
  customerName: Yup.string().required('Required'),
  customerNumber: Yup.string()
    .min(10)
    .max(10)
    .required('Required'),
  customerAddress: Yup.string().required('Required'),
  finalAmount: Yup.string().required('Required'),

  advancePayment: Yup.number()
    .min(0, 'Advance Payment cannot be negative')
    .max(Yup.ref('finalAmount'), 'cannot exceed final amount'),

  remainingPayment: Yup.string().required('Required'),
});
export const EditOrderValidationSchema = Yup.object().shape({
  
  finalAmount: Yup.string().required('Required'),

  advancePayment: Yup.number()
    .min(0, 'Advance Payment cannot be negative')
    .max(Yup.ref('finalAmount'), 'cannot exceed final amount'),

  remainingPayment: Yup.string().required('Required'),
});
export const EditCustomerOrderValidationSchema = Yup.object().shape({
  customerName: Yup.string().required('Required'),
  customerNumber: Yup.string()
    .min(10)
    .max(10)
    .required('Required'),
  customerAddress: Yup.string().required('Required'),
});
export const AddEditExpenseValidationSchema = Yup.object().shape({
  expenseDetail: Yup.array().of(
    Yup.object().shape({
      advancePayment: Yup.number()
        .min(0, 'Advance Payment cannot be negative')
        .max(Yup.ref('amount'), 'cannot exceed final Amount'),
    })
  ),
});
export const AddEditUserValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required.'),
  lastName: Yup.string().required('Required.'),
  email: Yup.string().email().required('Required.'),
  mobileNumber: Yup.string()
    .min(10)
    .max(10)
    .required('required'),
  address: Yup.string().required('Required.'),
  city: Yup.string().required('Required.'),
});

export const AddEditMeasurementValidationSchema=Yup.object().shape({
  customerName: Yup.string().required('Enter a customer name.'),
  customerNumber: Yup.string()
  .min(10)
  .max(10)
  .required('Enter customer number'),
})

export const AddEditTechnicianrValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required.'),
  lastName: Yup.string().required('Required.'),
  mobileNumber: Yup.string()
    .min(10)
    .max(10)
    .required('required'),
  address: Yup.string().required('Required.'),
  city: Yup.string().required('Required.'),
});
