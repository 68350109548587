import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface Props {
  handleOpenDeleteModal: () => void;
}

const DeleteButton: React.FC<Props> = ({ handleOpenDeleteModal }) => {
  return (
    <DeleteOutlineOutlinedIcon
      sx={{ pl: '3px', color: 'red', cursor: 'pointer' }}
      onClick={handleOpenDeleteModal}
    />
  );
};

export default DeleteButton;
