export const isLogin = () => {
  const token = localStorage.getItem('userToken');
  if (token) {
    return true;
  }

  return false;
};
export const getUserId = () => {
  const userId = localStorage.getItem('userId');

  if (!userId) {
    return '';
  }
  return userId;
};

export const getShopId = async () => {
  const shopId = await localStorage.getItem('shopId');

  if (!shopId) {
    return '';
  }
  return shopId;
};
