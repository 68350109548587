import { call, put } from 'redux-saga/effects';
import { deleteImageApi } from '../../services';
import { DELETE_IMAGE_FAILURE, DELETE_IMAGE_SUCCESS, DELETE_ORDER_IMAGE_FAILURE, DELETE_ORDER_IMAGE_SUCCESS, SHOP_DELETE_IMAGE_FAILURE, SHOP_DELETE_IMAGE_SUCCESS } from '../constant';

export function* deleteImageSaga(action:any): any {
  try {
    const response = yield call(deleteImageApi,action.payload);
      yield put({
      type: DELETE_IMAGE_SUCCESS,
    });
   } catch (error: any) {
    yield put({
      type: DELETE_IMAGE_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* deleteOrderImageSaga(action:any): any {
  try {
      yield put({
      type: DELETE_ORDER_IMAGE_SUCCESS,
    });
   } catch (error: any) {
    yield put({
      type: DELETE_ORDER_IMAGE_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* deleteShopImageSaga(action:any): any {
  try {
      yield put({
      type: SHOP_DELETE_IMAGE_SUCCESS,
    });
   } catch (error: any) {
    yield put({
      type: SHOP_DELETE_IMAGE_FAILURE,
      payload: error.response.data.message,
    });
  }
}


