import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Menu,
  Typography,
  TextField,
  styled,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DatePickers from './DatePickers';
import { useSelector } from 'react-redux';
import { getOrder } from '../store/order/action';
import { useDispatch } from 'react-redux';
import { getExpenseList } from '../store/expenses/action';

const StatusOption = [
  { value: 'placed', label: 'Placed' },
  { value: 'inProgress', label: 'In Progress' },
  { value: 'delayed', label: 'Delayed' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
];

const PaymentOption = [
  { value: 'cash', label: 'Cash' },
  { value: 'online', label: 'Online/Card' },
];

const IconStyle=styled('div')({
  marginLeft: -10,
  width: 30,
  height: 25,
})

const FilterContainer=styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: 330,
  marginTop: 10,
})

const CheckBoxContainer=styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

const ButtonContainer=styled(Button)({
  marginTop: '20px',
  size: 'medium',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3) !important',
})

const ClearButtonContainer=styled(Button)({
    marginTop: '20px',
    size: 'medium',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
  })

const DotStyle=styled('div')({
  backgroundColor: 'white',
  height: 9,
  width: 9,
  borderRadius: 10,
  position: 'absolute',
  top: 3,
  right: 3,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)'
})




const FilterModal = (props: any) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [fromDate, setfromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [payment, setPayment] = React.useState<any[]>([]);
  const [status, setStatus] = React.useState<any[]>([]);
  const [minExpense, setMinExpense] = React.useState('');
  const [maxExpense, setMaxExpense] = React.useState('');
  const [filterActive, setFilterActive] = React.useState(false);
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  useEffect(() => {
    updateIsButtonDisabled();
  }, [status, payment, fromDate, toDate, minExpense, maxExpense]);
  const updateIsButtonDisabled = () => {
    const isDisabled =
      status.length === 0 &&
      payment.length === 0 &&
      fromDate === null &&
      toDate === null &&
      minExpense === '' &&
      maxExpense === '';

    setIsButtonDisabled(isDisabled);
  };
  useEffect(() => {
    const storedFilterValues = localStorage.getItem('filterValues');
    if (storedFilterValues) {
      const parsedFilterValues = JSON.parse(storedFilterValues);
      setStatus(parsedFilterValues.status || []);
      setfromDate(parsedFilterValues.fromDate || null);
      setToDate(parsedFilterValues.toDate || null);
      setPayment(parsedFilterValues.payment || []);
    }
  }, []);
  useEffect(() => {
    const filterValuesToStore = { status, fromDate, toDate, payment };
    localStorage.setItem('filterValues', JSON.stringify(filterValuesToStore));
  }, [status, fromDate, toDate, payment]);
  const auth = useSelector((state: any) => state.auth);
  const handleStatusChange = (event: any) => {
    const { value, checked } = event.target;
   
    if (checked) {
      setStatus((prevValues) => [...prevValues, value]);

    } else {
      setStatus((prevValues) => prevValues.filter((item) => item !== value));
    }
    updateIsButtonDisabled();
  };


  const handlePaymentChange = (event: any) => {
    const { value, checked } = event.target;
    if (checked) {
      setPayment((prevValues) => [...prevValues, value]);
    } else {
      setPayment((prevValues) => prevValues.filter((item) => item !== value));
    }
    updateIsButtonDisabled();
  };

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElUser(null);
  };

  const onFilterData = () => {
    updateIsButtonDisabled();
    let filterData = {};
    if (window.location.pathname === '/orders') {
      filterData = {
        paymentMode: payment,
        status: status,
        fromDate: fromDate ? fromDate : null,
        toDate: toDate ? toDate : null,
      };
    } else {
      filterData = {
        minExpense: minExpense,
        maxExpense: maxExpense,
        status: status,
        fromDate: fromDate ? fromDate : null,
        toDate: toDate ? toDate : null,
      };
    }
    props.parentCallback(filterData);
    if (
      status.length === 0 &&
      payment.length === 0 &&
      fromDate === null &&
      toDate === null &&
      minExpense === '' &&
      maxExpense === ''
    ) {
      setFilterActive(false);
    } else {
      setFilterActive(true);
    }
    setAnchorElUser(null);
  };

  const onClearData = () => {
    setAnchorElUser(null);
    setfromDate(null);
    setToDate(null);
    setPayment([]);
    setStatus([]);
    setMinExpense('');
    setMaxExpense('');
    updateIsButtonDisabled();
    setFilterActive(false);
    if (window.location.pathname === '/orders') {
      dispatch(
        getOrder({
          id: auth.data._id,
          page: 1,
          searchTerm: '',
          rowsPerPage: 10,
          status: '',
          paymentMode: '',
          fromDate: '',
          toDate: '',
        })
      );
    } else {
      dispatch(
        getExpenseList({
          id: auth.data._id,
          page: 1,
          searchTerm: '',
          rowsPerPage: 10,
          status: '',
          fromDate: '',
          toDate: '',
          minExpense: '',
          maxExpense: '',
        })
      );
    }
    localStorage.removeItem('filterValues');
  };

  return (
    <Box sx={{ minWidth: 100 }}>
      <FormControl fullWidth>
        <Box sx={{ flexGrow: 0 }}>
          <Button
            onClick={handleOpenUserMenu}
            sx={{ backgroundColor: '#9155FD !important', fontSize: '16px','@media (max-width: 1076px)': { marginTop:'10px' }  }}
            variant="contained"
          >
            <IconStyle>
            <FilterAltIcon
              style={{ color: 'white' }}
            />
            </IconStyle>
            Filter {filterActive && <DotStyle  />}
          </Button>

          <Menu
            sx={{ mt: '45px' }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleClose}
          >
            <Box>
              <Box sx={{ width: '380px', padding: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold', color: 'rgba(58, 53, 65, 0.87)' }}
                >
                  Date
                </Typography>
                <FilterContainer>
                  <DatePickers
                    placeholder={'From Date'}
                    value={fromDate}
                    onChange={(date: any) => setfromDate(date)}
                  />
                  <span style={{ marginRight: 5 }} />
                  <DatePickers
                    minDate={fromDate}
                    placeholder={'To Date'}
                    value={toDate}
                    onChange={(date: any) => setToDate(date)}
                  />
                </FilterContainer>
                {window.location.pathname === '/orders' ? (
                  <Box>
                    <Typography
                      sx={{
                        marginTop: 2,
                        fontWeight: 'bold',
                        color: 'rgba(58, 53, 65, 0.87)',
                      }}
                    >
                      Payment Mode
                    </Typography>
                    <CheckBoxContainer>
                      {PaymentOption.map((item: any, index: number) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item.value}
                              checked={payment.includes(item.value)}
                              onChange={handlePaymentChange}
                            />
                          }
                          label={item.label}
                          key={index}
                        />
                      ))}
                    </CheckBoxContainer>
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        marginTop: 2,
                        fontWeight: 'bold',
                        color: 'rgba(58, 53, 65, 0.87)',
                      }}
                    >
                      Expense
                    </Typography>
                    <FilterContainer>
                      <TextField
                        size={'small'}
                        value={minExpense}
                        onChange={(event: any) =>
                          setMinExpense(event?.target.value)
                        }
                        type="number"
                        placeholder="Min"
                        sx={{ height: 20 }}
                        id="outlined-basic"
                        label="Min"
                        variant="outlined"
                      />
                      <span style={{ marginRight: 5 }} />
                      <TextField
                        size={'small'}
                        value={maxExpense}
                        onChange={(event: any) =>
                          setMaxExpense(event.target.value)
                        }
                        type="number"
                        placeholder="Max"
                        id="outlined-basic"
                        label="Max"
                        variant="outlined"
                      />
                    </FilterContainer>
                  </Box>
                )}
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: 'rgba(58, 53, 65, 0.87)',
                    marginTop: 1,
                  }}
                >
                  Status
                </Typography>
                <CheckBoxContainer>
                  {StatusOption.map((item: any, index: number) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item.value}
                          checked={status.includes(item.value)}
                          onChange={handleStatusChange}
                        />
                      }
                      label={item.label}
                      key={index}
                    />
                  ))}
                </CheckBoxContainer>
                <Box sx={{ justifyContent: 'center', display: 'flex', mt: 1 }}>
                  <ButtonContainer
                    variant="contained"
                    sx={{
                      backgroundColor: isButtonDisabled ? 'grey' : '#9155FD !important',
                      color: 'white',
                    }}
                    onClick={onFilterData}
                    disabled={isButtonDisabled}
                  >
                    Apply
                  </ButtonContainer>
                  <ClearButtonContainer
                    sx={{ ml: 1 }}
                    variant="outlined"
                    color="primary"
                    onClick={onClearData}
                    disabled={isButtonDisabled}
                  >
                    Clear
                  </ClearButtonContainer>
                </Box>
              </Box>
            </Box>
          </Menu>
        </Box>
      </FormControl>
    </Box>
  );
};
export default FilterModal;
