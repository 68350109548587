import { call, put } from 'redux-saga/effects';
import customHistory from '../../routes/history';
import {
  ADD_EMPLOYEE_FAILURE,
  ADD_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_STATUS_SUCCESS,
  EDIT_EMPLOYEE_STATUS_FAILURE,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_SUCCESS,
  IS_LOADING,
  USER_DASHBOARD_SUCCESS,
} from '../constant';
import {
  addEmployeeApi,
  deleteEmployeeApi,
  editEmployeeApi,
  getEmployeesApi,
  editEmployeeStatusApi,
  userDasboardApi,
} from '../../services';
import { toast } from 'react-toastify';
import store from '..';

export function* getEmployeesSaga(action: any): any {
  try {
    const response = yield call(getEmployeesApi,action.payload);
    yield put({
      type: GET_EMPLOYEE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_EMPLOYEE_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addEmployeeSaga(action: any): any {
  yield put({
    type: IS_LOADING,
    payload: true,
  });
  try {
    const response = yield call(addEmployeeApi, action.payload.payload);
    const EmployeeDataResponse=yield call(getEmployeesApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
   yield put({
       type: GET_EMPLOYEE_SUCCESS,
     payload: EmployeeDataResponse.data,
     });
      yield put({
      type: ADD_EMPLOYEE_SUCCESS,
      payload: response.data.data,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
    customHistory.push('/users');
    toast(response.data.message);
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
 } catch (error: any) {
    yield put({
      type: ADD_EMPLOYEE_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editEmployeeSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true,
    });
    const response = yield call(editEmployeeApi, action.payload);
    const EmployeeDataResponse=yield call(getEmployeesApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
       yield put({
         type: GET_EMPLOYEE_SUCCESS,
       payload: EmployeeDataResponse.data,
       });
      yield put({
      type: EDIT_EMPLOYEE_SUCCESS,
      payload: response.data.data,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
    customHistory.push('/users');
    toast(response.data.message);
    } catch (error: any) {
    yield put({
      type: EDIT_EMPLOYEE_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editEmployeeStatusSaga(action: any): any {
  try {
    const response = yield call(editEmployeeStatusApi, action.payload);
    const employeeList = store.getState().employees;
    const newList = employeeList?.employeeData?.data.map((item:any) => {
      if (item._id === response?.data?.data._id) {
        const updatedItem = {
          ...response?.data?.data,
        };
        return updatedItem;
      }
      return item;
    });
    const updatedEmployeeList={
       total:employeeList?.employeeData.total,
      data:newList
    }
       yield put({
       type: GET_EMPLOYEE_SUCCESS,
       payload: updatedEmployeeList,
       });
      
     yield put({
      type: EDIT_EMPLOYEE_STATUS_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
  } catch (error: any) {
    yield put({
      type: EDIT_EMPLOYEE_STATUS_FAILURE,
      payload: error.response.data.message,
    });
    toast(error.response.data.message);
  }
}

export function* deleteEmployeeSaga(action: any): any {
    try {
    const response = yield call(deleteEmployeeApi, action.payload.payload);
     const employeeDataResponse=yield call(getEmployeesApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
     
       yield put({
         type: GET_EMPLOYEE_SUCCESS,
       payload: employeeDataResponse.data,
       });
    yield put({
      type: DELETE_EMPLOYEE_SUCCESS,
    });
    toast(response.data.message);
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
   } catch (error: any) {
    yield put({
      type: DELETE_EMPLOYEE_FAILURE,
      payload: error.response.data.message,
    });
  }
}
