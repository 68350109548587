import {
  IS_LOADING,
  EDIT_TECHNICIAN_PAYMENT_BEGIN,
  EDIT_TECHNICIAN_PAYMENT_SUCCESS,
  EDIT_TECHNICIAN_PAYMENT_FAILURE
} from '../constant';

const initialState: any = {
  loading: false,
  editTechnicianPayment: [],
  error: null,
  isLoading: false,
  isSuccess: false,
};

const editTechnicianPaymentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case EDIT_TECHNICIAN_PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };
    case EDIT_TECHNICIAN_PAYMENT_SUCCESS:
      return { ...state, loading: false, editTechnicianPayment:action.payload, isSuccess: true};
    case EDIT_TECHNICIAN_PAYMENT_FAILURE:
      return { ...state, error: action.payload };
    case IS_LOADING:
      return {
        ...state,
        loading: false,
        editTechnicianPayment: action.payload,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default editTechnicianPaymentReducer;
