import {
  EDIT_EMPLOYEE_STATUS_BEGIN,
  EDIT_EMPLOYEE_STATUS_FAILURE,
  EDIT_EMPLOYEE_STATUS_SUCCESS,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
editEmployeeStatusData:[],
   error: null,
   isLoading: false,
 };
 
 const editEmployeeStatusReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case EDIT_EMPLOYEE_STATUS_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case EDIT_EMPLOYEE_STATUS_SUCCESS:
       return { ...state, loading: false, editEmployeeStatusData:action.payload};

     case EDIT_EMPLOYEE_STATUS_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default editEmployeeStatusReducer;