import { CREATE_MEASUREMENT_BEGIN, DELETE_MEASUREMENT_BEGIN, EDIT_MEASUREMENT_BEGIN, GET_MEASUREMENT_BEGIN } from "../constant";


export const createMeasurement=(payload:any)=>(
  {
  type:CREATE_MEASUREMENT_BEGIN,
  payload
})

export const getMesurementList = (payload:any) => (
  {
  type: GET_MEASUREMENT_BEGIN,
  payload
});

export const deleteMeasurement=(payload:any)=>({
 type: DELETE_MEASUREMENT_BEGIN,
 payload,
})

export const editMeasurement= (data: any) => (
  {
  type: EDIT_MEASUREMENT_BEGIN,
  payload: data
});