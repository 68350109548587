import {
  GET_EXPENSES_BEGIN,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_FAILURE,
  IS_LOADING
} from '../constant';

const initialState: any = {
  loading: false,
  data: [],
  error: null
};

const expenseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EXPENSES_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_EXPENSES_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_EXPENSES_FAILURE:
      return { ...state, error: action.payload };
      case IS_LOADING:
        return {
          ...state,
          loading: false,
          data: action.payload,
          isLoading: action.payload
        };
    default:
      return state;
  }
};

export default expenseReducer;
