import { DELETE_IMAGE_BEGIN, DELETE_ORDER_IMAGE_BEGIN, SHOP_DELETE_IMAGE_BEGIN } from "../constant";

export const deleteImageAction = (payload:any) => ({
  type: DELETE_IMAGE_BEGIN,
  payload
});

export const deleteOrderImageAction = (payload:any) => ({
  type: DELETE_ORDER_IMAGE_BEGIN,
  payload
});

export const deleteProfileImageAction = () => ({
  type: DELETE_IMAGE_BEGIN
})

export const shopImageDeleteAction=(payload:any)=>({
  type: SHOP_DELETE_IMAGE_BEGIN ,
  payload
})