
import { Box, styled } from '@mui/material';
import nodata from '../assets/images/nodata.webp'


const ImageStyle=styled('img')({
  width:'400px',
  objectFit:'cover',
  
})

const NoDataText=styled('div')({
  fontSize: '30px',
})

const ImageContainer=styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'absolute',
  alignItems: 'center',
  height: '60%',
  width: '-webkit-fill-available',
  flexDirection:'column'
})


const NoDataImage = () => {
  return (
    <ImageContainer>
 <ImageStyle src={nodata}  alt="no image found" />
 <NoDataText>No data found</NoDataText> 
 </ImageContainer>
  );
};

export default NoDataImage;
document.head.innerHTML += `
<link rel="preload" href="${nodata}" as="image" />
`;