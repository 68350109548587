import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/styles';
import { deleteOrder } from '../../store/order/action';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, FormControl, MenuItem } from '@mui/material';
import { Box, TableContainer, Paper } from '@mui/material';
import DropdownModal from '../../components/ConfirmationModal';
import { expenseStatusChange } from '../../store/expenses/action';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NoDataImage from '../../components/NoDataImage';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { formatIndianCurrency } from '../../utils/validations/Helpler';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px',
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
  fontSize: '16px',
  textWrap: 'nowrap'
}));

const TableDataWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'absolute',
  alignItems: 'center',
  height: '60%',
  width: '-webkit-fill-available',
});

const ExpenseListTable = ({
  expenseList,
  expenseListHeader,
  expenseDataLoader,
}: any) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [showDropdownConfirmation, setShowDropdownConfirmation] =
    useState(false);
  const [dropdownConfirmationItemId, setDropdownConfirmationItemId] =
    useState('');
  const [dropdownConfirmationValue, setDropdownConfirmationValue] =
    useState('');
  const [dropdownModalValues, setDropdownModalValues] = useState<any>({
    e: '',
    item: '',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchData = useSelector((state: any) => state.expenseList);
  const searchLoading = useSelector(
    (state: any) => state?.expenseList?.loading
  );

  const editExpenseStatusLoader = useSelector(
    (state: any) => state.editExpenseStatus.loading
  );

  useEffect(() => {
    if (!editExpenseStatusLoader) {
      setShowDropdownConfirmation(false);
    }
  }, [!editExpenseStatusLoader]);

  const options = [
    { value: 'placed', label: 'Placed' },
    { value: 'inProgress', label: 'In Progress' },
    { value: 'delayed', label: 'Delayed' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
  ];

  const handleCloseDropdownConfirmationModal = () => {
    setDropdownConfirmationItemId('');
    setDropdownConfirmationValue('');
    setShowDropdownConfirmation(false);
  };


  const handleChange = (event: SelectChangeEvent, item: any) => {
    let data = {
      expenseId:item.expenseId,
      technicianId: item.technicianId,
      status: event.target.value,
      description:item.description
    }
    dispatch(expenseStatusChange(data));
  };

  const handleDropdownConfirmationAction = (e: any, item: any) => {
    setDropdownModalValues({ e, item });
    setShowDropdownConfirmation(true);
  };

  const handleDeleteModalYes = () => {
    dispatch(deleteOrder({ id: deleteItemId }));
    handleClose();
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId('');
  };


  const navToEditOrder = (data: any) => {
    navigate(`/order/detail/${data.orderId}`, { state: data.orderId });
  };


  return (
    <>
      <Paper sx={{ overflow: "hidden", mt: 2 }}>
        <StyledTableContainer>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                {expenseListHeader?.map((item: string, index: number) => (
                  <StyledTableHeadCell
                    key={index}
                    align='center'
                  >
                    {item}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!searchLoading ? (
                expenseList?.length === 0 ? (
                  <TableRow>
                    <TableCell>
                     <NoDataImage/>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow/>
                )
              ) : (
                <TableDataWrapper>
                  <CircularProgress color='inherit' />
                </TableDataWrapper>
              )}
              {!expenseDataLoader ? (
                !searchData.loading &&
                expenseList?.map((item: any, index: number) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align='center' style={{cursor:'pointer'}} onClick={()=>navToEditOrder(item)}>{item.orderUniqueId?.toUpperCase()}</TableCell>
                      <TableCell align='center'>
                        {item?.technicianName}
                      </TableCell>
                      <TableCell align='center'>
                        {item?.description}
                      </TableCell>
                      <TableCell align='center'>
                        {formatIndianCurrency(item.amount)}
                      </TableCell>
                      <TableCell align='center'>
                        {formatIndianCurrency(item.advancePayment)}
                      </TableCell>
                      <TableCell align='center'>
                        {formatIndianCurrency(item.remainingPayment)}
                      </TableCell>
                      <TableCell align='center'>
                      {item.deliveryDate===null?'-':
                          dayjs(item?.deliveryDate).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell sx={{ minWidth: 20, align: 'center', textAlign: 'center' }}>
                        <FormControl sx={{ m: 0, minWidth: 100, align: 'center', textAlign: 'center' }} size="small">
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            sx={{ align: 'center', textAlign: 'center',minWidth:'130px' }}
                            value={item.status}
                            label='status'
                            onChange={(e) => handleDropdownConfirmationAction(e, item)}
                          >
                            {options.map((data: any, index: any) => (
                              <MenuItem value={data.value} key={index}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableDataWrapper>
                  <CircularProgress color='inherit' />
                </TableDataWrapper>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
      <DropdownModal
        title=' Are you sure you want to Delete ?'
        isOpen={showDeleteModal}
        handleClose={handleClose}
        handleClickYes={handleDeleteModalYes}
      />
      <DropdownModal
      loading={editExpenseStatusLoader}
        title=' Are you sure you want to change the status?'
        isOpen={showDropdownConfirmation}
        handleClose={handleCloseDropdownConfirmationModal}
        handleClickYes={handleChange}
        dropdownModalValues={dropdownModalValues}
      />
    </>
  );
};

export default ExpenseListTable;
