import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputAdornment,
  Table,
  TableContainer,
  styled,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

const Headers = [
  '',
  'Order Items',
  'Quantity',
  'Price',
  'Discount',
  'CGST',
  'SGST',
  'Display Price',
  '',
];

const TableHeadStyle = styled(TableHead)({
  '& .MuiTableCell-root': {
    backgroundColor: 'rgb(249, 250, 252)',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
});

const TableContain = styled(TableContainer)({
  maxHeight: 200,
});

const TableText = styled(TableCell)({
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
  padding: '12px 4px !important',
  fontSize: '16px',
});

const TableItemHeader = styled(TableCell)({
  padding: '8px 4px !important',
});

const OrderItems = ({ items, getFieldProps }: any) => {
  const { values, setFieldValue }: any = useFormikContext();
  const bottomRef = useRef<any>(null);

  const calculateBilling = () => {
    const totalSum = values?.orderDetails.reduce((acc: any, item: any) => {
      return acc + item.displayPrice;
    }, 0);
    const totalSumRound = Math.round(totalSum);
    setFieldValue(`grandTotal`, Math.round(totalSumRound));

    const Amount =
      values?.grandTotal -
      (values?.grandTotal * Number(values?.totalDiscount)) / 100;
    const remainingPayment = Amount - values.advancePayment;
    setFieldValue(`finalAmount`, Math.round(Amount));
    setFieldValue(`remainingPayment`, Math.round(remainingPayment));
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoViewIfNeeded({
      behavior: 'smooth',
      block: 'start',
    });
  }, [values.orderDetails]);

  useEffect(() => {
    calculateBilling();
  }, [values?.grandTotal, values?.advancePayment, values?.discountOnTotal]);

  const handleRemoveItem = (currentIndex: number) => {
    const removeItem = values?.orderDetails?.filter(
      (item: any, index: number) => index !== currentIndex
    );
    setFieldValue('orderDetails', removeItem);
    setFieldValue(
      `grandTotal`,
      values?.grandTotal -
        Math.round(values?.orderDetails[currentIndex].displayPrice)
    );
  };

  const getTotalPrice = (qty: number, price: number, index: number) => {
    setFieldValue(
      `orderDetails[${index}].displayPrice`,
      Math.round(qty * price)
    );
  };

  const handleChangeQuantity = (event: any, index: number) => {
    const price = values?.orderDetails[index].originalPrice;
    const discount = values?.orderDetails[index].discount;
    const cgst = values?.orderDetails[index].cgst;
    const sgst = values?.orderDetails[index].sgst;
    Number(event.target.value) === 0
      ? setFieldValue(`orderDetails[${index}].quantity`, '')
      : setFieldValue(
          `orderDetails[${index}].quantity`,
          Number(event.target.value)
        );
    const totalDiscountCalculate: any = (
      (Number(event.target.value) * price * discount) /
      100
    ).toFixed(2);
    const totalAfterDiscount: any =
      Number(event.target.value) * price - totalDiscountCalculate;
    const totalTax = cgst + sgst;
    const displayPrice = (totalAfterDiscount * totalTax) / 100;
    setFieldValue(
      `orderDetails[${index}].displayPrice`,
      Math.round(displayPrice + totalAfterDiscount)
    );
    setFieldValue(`finalAmount`, Math.round(displayPrice + totalAfterDiscount));
    setFieldValue(`grandTotal`, Math.round(displayPrice + totalAfterDiscount));
    setFieldValue(
      `remainingPayment`,
      Math.round(displayPrice + totalAfterDiscount)
    );
  };

  const handleChangePrice = (event: any, index: number) => {
    const qty = values?.orderDetails[index].quantity;
    const discount = values?.orderDetails[index].discount;
    const cgst = values?.orderDetails[index].cgst;
    const sgst = values?.orderDetails[index].sgst;

    Number(event.target.value) === 0
      ? setFieldValue(`orderDetails[${index}].originalPrice`, '')
      : setFieldValue(
          `orderDetails[${index}].originalPrice`,
          Number(event.target.value)
        );

    const totalDiscountCalculate: any = (
      (qty * Number(event.target.value) * discount) /
      100
    ).toFixed(2);
    const totalAfterDiscount: any =
      qty * Number(event.target.value) - totalDiscountCalculate;
    const totalTax = cgst + sgst;
    const displayPrice = (totalAfterDiscount * totalTax) / 100;
    getTotalPrice(qty, event.target.value, index);
    setFieldValue(
      `orderDetails[${index}].displayPrice`,
      Math.round(displayPrice + totalAfterDiscount)
    );

    setFieldValue(`finalAmount`, Math.round(displayPrice + totalAfterDiscount));
    setFieldValue(`grandTotal`, Math.round(displayPrice + totalAfterDiscount));

    setFieldValue(
      `remainingPayment`,
      Math.round(displayPrice + totalAfterDiscount)
    );
  };

  const handleChangeDiscount = (event: any, index: number) => {
    const price = values?.orderDetails[index].originalPrice;
    const qty = values?.orderDetails[index].quantity;
    Number(event.target.value) === 0
      ? setFieldValue(`orderDetails[${index}].discount`, '')
      : setFieldValue(
          `orderDetails[${index}].discount`,
          Number(event.target.value)
        );
    const totalPriceCalculate: any = (
      (qty * price * event.target.value) /
      100
    ).toFixed(2);
    setFieldValue(
      `orderDetails[${index}].displayPrice`,
      Math.round(qty * price - totalPriceCalculate)
    );
    setFieldValue(`finalAmount`, Math.round(qty * price - totalPriceCalculate));
    setFieldValue(
      `remainingPayment`,
      Math.round(qty * price - totalPriceCalculate)
    );
    setFieldValue(`grandTotal`, Math.round(qty * price - totalPriceCalculate));
  };

  const handleChangeCgst = (event: any, index: number) => {
    Number(event.target.value) === 0
      ? setFieldValue(`orderDetails[${index}].cgst`, '')
      : setFieldValue(
          `orderDetails[${index}].cgst`,
          Number(event.target.value)
        );
  };
  const handleChangeSgst = (event: any, index: number) => {
    const qty = values?.orderDetails[index].quantity;
    const price = values?.orderDetails[index].originalPrice;
    const discount = values?.orderDetails[index].discount;
    const cgst = values?.orderDetails[index].cgst;
    const totalDiscountCalculate: any = (
      (qty * price * discount) /
      100
    ).toFixed(2);
    Number(event.target.value) === 0
      ? setFieldValue(`orderDetails[${index}].sgst`, '')
      : setFieldValue(
          `orderDetails[${index}].sgst`,
          Number(event.target.value)
        );
    const totalAfterDiscount: any = qty * price - totalDiscountCalculate;
    const totalTax = cgst + Number(event.target.value);
    const displayPrice = (totalAfterDiscount * totalTax) / 100;
    setFieldValue(
      `orderDetails[${index}].displayPrice`,
      Math.round(displayPrice + totalAfterDiscount)
    );
    setFieldValue(`grandTotal`, Math.round(displayPrice + totalAfterDiscount));
  };
  const handleChangeName = (event: any, index: number) => {
    setFieldValue(`itemName`, event.target.value);
  };

  return (
    <Box>
      <TableContain>
        <Table stickyHeader aria-label='sticky table'>
          <TableHeadStyle>
            <TableRow>
              {Headers.map((item, index: number) => (
                <TableText key={index}>{item}</TableText>
              ))}
            </TableRow>
          </TableHeadStyle>
          <TableBody>
            {items?.map((_: any, index: number) => (
              <TableRow key={index}>
                <TableCell style={{ width: 10 }}>{index + 1}</TableCell>
                <TableItemHeader style={{ minWidth: '200px' }}>
                  <TextField
                    fullWidth
                    size='small'
                    placeholder='Description of Product'
                    onBlur={(e) => handleChangeName(e, index)}
                    {...getFieldProps(`orderDetails[${index}].itemName`)}
                  />
                </TableItemHeader>
                <TableItemHeader width={80}>
                  <TextField
                    size='small'
                    placeholder='0'
                    type='number'
                    {...getFieldProps(`orderDetails[${index}].quantity`)}
                    InputProps={{ inputProps: { min: 1 } }}
                    onBlur={(e) => handleChangeQuantity(e, index)}
                  />
                </TableItemHeader>
                <TableItemHeader width={140}>
                  <TextField
                    size='small'
                    placeholder='0'
                    type='number'
                    {...getFieldProps(`orderDetails[${index}].originalPrice`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>₹</InputAdornment>
                      ),
                    }}
                    onBlur={(e) => handleChangePrice(e, index)}
                  />
                </TableItemHeader>
                <TableItemHeader width={120}>
                  <TextField
                    size='small'
                    placeholder='0'
                    type='number'
                    {...getFieldProps(`orderDetails[${index}].discount`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onBlur={(e) => handleChangeDiscount(e, index)}
                  />
                </TableItemHeader>
                <TableItemHeader width={90}>
                  <TextField
                    size='small'
                    placeholder='0'
                    type='number'
                    {...getFieldProps(`orderDetails[${index}].cgst`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onBlur={(e) => handleChangeCgst(e, index)}
                  />
                </TableItemHeader>
                <TableItemHeader width={90}>
                  <TextField
                    size='small'
                    placeholder='0'
                    type='number'
                    {...getFieldProps(`orderDetails[${index}].sgst`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    onBlur={(e) => handleChangeSgst(e, index)}
                  />
                </TableItemHeader>
                <TableItemHeader width={200}>
                  <TextField
                    size='small'
                    placeholder='Total Price'
                    type='number'
                    autoFocus={false}
                    disabled
                    {...getFieldProps(`orderDetails[${index}].displayPrice`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>₹</InputAdornment>
                      ),
                      readOnly: true,
                    }}
                  />
                </TableItemHeader>
                {values.orderDetails.length > 1 && (
                  <TableItemHeader width={1}>
                    <IconButton onClick={() => handleRemoveItem(index)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableItemHeader>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div ref={bottomRef} />
      </TableContain>
    </Box>
  );
};

export default OrderItems;
