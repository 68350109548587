import {
  DELETE_MEASUREMENT_BEGIN,
  DELETE_MEASUREMENT_SUCCESS,
  DELETE_MEASUREMENT_FAILURE,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
deleteMeasurementData:[],
   error: null,
   isLoading: false,
 };
 
 const deleteMeasurementReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case DELETE_MEASUREMENT_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case DELETE_MEASUREMENT_SUCCESS:
       return { ...state, loading: false, deleteMeasurementData:action.payload}; 
      case DELETE_MEASUREMENT_FAILURE:
       return { ...state, loading: false, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default deleteMeasurementReducer;