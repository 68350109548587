
import {
  ADD_USERS_BEGIN,
  DELETE_USERS_BEGIN,
  EDIT_USERS_BEGIN,
  EDIT_USERS_STATUS_BEGIN,
  GET_USERS_BEGIN,
  IS_LOADING,
  USER_DASHBOARD_BEGIN
} from '../constant';

export const getUsers = () => ({
  type: GET_USERS_BEGIN,
});

export const addUser = (payload: any) => ({
  type: ADD_USERS_BEGIN,
  payload
});

export const editUser = (payload: any) => ({
  type: EDIT_USERS_BEGIN,
  payload,
});
export const editUserStatus = (payload: any) => ({
  type: EDIT_USERS_STATUS_BEGIN,
  payload
});

export const deleteUser = (payload: any) => ({
  type: DELETE_USERS_BEGIN,
  payload
});

export const addDashboard = (payload: any) => ({
  type: USER_DASHBOARD_BEGIN,
  payload
});
export const isLoading = (payload: any) => ({
  type: IS_LOADING,
  payload
});
