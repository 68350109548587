import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import styled from '@emotion/styled';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Article = styled('article')({
  height:'100%', paddingBottom:'10px',
  '@media (max-width:1024px)': {    
    height:'100%',
    paddingLeft:'10px',
  },
});
const LineChart = (props: any) => {
  return (
    <Article className="canvas-container">
      <Line data={props.data} options={props.options} />
      </Article>
  );
};

export default LineChart;
