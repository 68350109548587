import {
   EDIT_ORDER_BEGIN,
    EDIT_ORDER_FAILURE,
    EDIT_ORDER_SUCCESS,
    IS_LOADING
  } from '../constant';
  
  const initialState: any = {
    loading: false,
    data: [],
    orderdata:[],
    error: null,
    isLoading: false,
  };
  
  const editOrderReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case EDIT_ORDER_BEGIN:
        return {
          ...state,
          loading: true
        };
        
      case EDIT_ORDER_SUCCESS:
        return { ...state, loading: false, orderdata:action.payload};

      case EDIT_ORDER_FAILURE:
        return { ...state, error: action.payload };
      case IS_LOADING:
        return {
          ...state,
          loading: false,
          isLoading: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default editOrderReducer;