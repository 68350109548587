import {
  ADD_SHOP_BEGIN,
  GET_SHOP_BEGIN,
  EDIT_SHOP_BEGIN,
  DELETE_SHOP_BEGIN,
  IS_LOADING,
  EDIT_SHOP_STATUS_BEGIN,
} from '../constant';

export const getShopAction = (payload: any) => ({
  type: GET_SHOP_BEGIN,
  payload,
});

export const addShopAction = (payload: any) => ({
  type: ADD_SHOP_BEGIN,
  payload,
});

export const editShopAction = (editData: any, id: any) => ({
  type: EDIT_SHOP_BEGIN,
  payload: { editData, id },
});

export const editShopStatusAction = (payload: any) => ({
  type: EDIT_SHOP_STATUS_BEGIN,
  payload,
});

export const deleteShopAction = (payload: any) => ({
  type: DELETE_SHOP_BEGIN,
  payload,
});

export const isLoading = (payload: any) => ({
  type: IS_LOADING,
  payload,
});
