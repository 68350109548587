import {
  IS_LOADING,
  ADD_TECHNICIAN_PAYMENT_BEGIN,
  ADD_TECHNICIAN_PAYMENT_SUCCESS,
  ADD_TECHNICIAN_PAYMENT_FAILURE
} from '../constant';

const initialState: any = {
  loading: false,
  addTechnicianPayment: [],
  error: null,
  isLoading: false,
  isSuccess:false
};

const addTechnicianPaymentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_TECHNICIAN_PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
        isSuccess:false
      };
    case ADD_TECHNICIAN_PAYMENT_SUCCESS:
      return { ...state, loading: false, addTechnicianPayment: action.payload,isSuccess:true };
    case ADD_TECHNICIAN_PAYMENT_FAILURE:
      return { ...state, error: action.payload };

    case IS_LOADING:
      return {
        ...state,
        loading: false,
        addTechnicianPayment: action.payload,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default addTechnicianPaymentReducer;
