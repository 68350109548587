import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Button,
  Box,
  Grid,
  Card,
  Typography,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { EditOrderValidationSchema } from '../../../../utils/validations';
import {
  editOrder,
} from '../../../../store/order/action';
import OrderItems from '../../Create/OrderItems';
import CustomSelect from '../../../../components/CustomSelect';
import PaymentHistory from './CustomerPayment/PaymentHistory';
import CancelIcon from '@mui/icons-material/Cancel';

const ProfileWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

const MainContainer = styled(Box)({
  width: '100%',
});

const CardWrapper = styled(Card)({
  boxShadow: '0px 2px 10px 0px rgb(58 53 65 / 10%) !important',
  borderRadius: '6px !important',
  padding: '20px',
  height: '65vh',
  overflowY: 'auto !important' as 'auto',
});

const Paper = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px !important',
  marginTop: '10px',
});

const ButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alginItems: 'center',
  alignSelf: 'center',
  position: 'relative',
  marginTop: '20px',
  paddingBottom: '20px',
});

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '200px',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  position: 'relative',
}));


const TextareaElement = styled(TextareaAutosize, {
  shouldForwardProp: (prop) =>
    !['ownerState', 'minRows={3}', 'maxRows'].includes(prop.toString()),
})(
  ({ theme }) => `
  width: 90%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px 8px 0 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[700] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

interface ImageObject {
  file: File;
  preview: string;
}

const EditOrder = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.auth);
  const orderDetail = useSelector((state: any) => state?.orderDetail);
  
  const isUpdateData = useSelector(
    (state: any) => state?.editOrderDetails?.loading
  );
  const orderDetaiLoader = useSelector(
    (state: any) => state?.orderDetail?.loading
  );

  const [newItemError, setNewItemError] = useState('');
  const [selectedImages, setSelectedImages] = useState<ImageObject[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleImageUpload = (e: any) => {
    const files = e?.target?.files;

    if (files) {
      const newFiles: File[] = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newFiles]);
      const newImages = Array.from(files).map((file: any) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setSelectedImages([...selectedImages, ...newImages]);
    }
  };

  const formik = useFormik({
    initialValues: {
      orderDetails: [
        {
          itemName: '',
          quantity: '',
          originalPrice: '',
          discount: '',
          cgst: '',
          sgst: '',
          displayPrice: '',
        },
      ],
      grandTotal: '',
      totalDiscount: '',
      finalAmount: '',
      advancePayment: '',
      remainingPayment: '',
      paymentMode: 'cash',
      notes: '',
    },
    validationSchema: EditOrderValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (isNewAddAllowed) {
        if (location.state) {
          const formData = new FormData();
          const orderDetailsArray = values.orderDetails.map((item) => ({
            itemName: item.itemName,
            quantity: item.quantity,
            originalPrice: item.originalPrice,
            discount: item.discount,
            cgst: item.cgst,
            sgst: item.sgst,
            displayPrice: item.displayPrice,
          }));
          formData.append('orderDetails', JSON.stringify(orderDetailsArray));
          formData.append('grandTotal', values.grandTotal);
          formData.append('totalDiscount', values.totalDiscount);     
          formData.append('finalAmount', values.finalAmount);
          formData.append('advancePayment', values.advancePayment);
          formData.append('remainingPayment', values.remainingPayment);
          formData.append('paymentMode', values.paymentMode);
          formData.append('notes', values.notes);
          const dataToSend = selectedImages.map(({ file }) => ({ file }));
          let ImagesData: any = dataToSend;
          formData.append('images', ImagesData);

          const filteredImages = selectedImages.filter(
            (image: any) => image.file
          );
          if (auth?.data) {
            dispatch(editOrder(formData, auth.data._id, location.state));
          }
        }
      }
    },
  });

  
  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = formik;

  useEffect(
    (index?: any) => {
      if (orderDetail?.orderDetailData?.orderDetails) {
        const state = orderDetail?.orderDetailData;
        setFieldValue(`orderDetails`, [...state?.orderDetails]);
        setFieldValue('grandTotal', state.grandTotal);
        setFieldValue('totalDiscount', state.totalDiscount);
        setFieldValue('finalAmount', state.finalAmount);
        setFieldValue('advancePayment', state.advancePayment);
        setFieldValue('remainingPayment', state.remainingPayment);
        setFieldValue('paymentMode', state.paymentMode);
        setFieldValue('notes', state.notes);
        setFieldValue('images', selectedImages);
      }
    },
    [orderDetail?.orderDetailData?.orderDetails]
  );
  const handleChangeInput = (event: any) => {
    setFieldValue(`notes`, event.target.value);
  };
  const handleAddItems = () => {
    if (isNewAddAllowed) {
      setFieldValue('orderDetails', [
        ...values.orderDetails,
        {
          itemName: '',
          quantity: '',
          originalPrice: '',
          discount: '',
          cgst: '',
          sgst: '',
          displayPrice: '',
        },
      ]);
    } else {
      setNewItemError('Please fill the description');
    }
  };

  const options = [
    { value: 'cash', label: 'cash' },
    { value: 'online', label: 'online' },
  ];

  const handleChangeDiscountedTotal = (event: any) => {
    Number(event.target.value) === 0
      ? setFieldValue(`totalDiscount`, '')
      : setFieldValue(`totalDiscount`, Number(event.target.value));
    const grandTotal = Number(values.grandTotal);
    const totalDiscount = Number(event.target.value);
    const finalAmount = grandTotal - (grandTotal * totalDiscount) / 100;
    const remainingPayment = finalAmount - Number(values.advancePayment);
    setFieldValue(`finalAmount`, finalAmount);
    setFieldValue(`grandTotal`, finalAmount);
    Number(event.target.value) === 0
      ? setFieldValue(`remainingPayment`, '')
      : setFieldValue(`remainingPayment`, remainingPayment);
  };

  let isNewAddAllowed = true;

  for (let i = 0; i < values.orderDetails.length; i++) {
    let data = values.orderDetails[i];
    let t = Object.values(data).some((val) => val);
    if (!t) {
      isNewAddAllowed = false;
    }
  }

  const deleteImage = (image: any, index: number) => {
    let indexToRemove = index;
    let newArray = selectedImages.filter(
      (_:any, index:number) => index !== indexToRemove
    );
    setSelectedImages(newArray);
  };

  useEffect(() => {
    setNewItemError('');
  }, [isNewAddAllowed]);

  return (
    <>
      <MainContainer>
        <CardWrapper>
          {orderDetaiLoader ? (
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '55%',
              }}
            >
              <CircularProgress color='inherit' />
            </Box>
          ) : (
            <Box>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  <ProfileWrapper>
                    <OrderItems
                      items={values.orderDetails}
                      getFieldProps={getFieldProps}
                    />
                   
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Box sx={{ mt: 2 }}>
                        <div style={{ marginBottom: 10, color: 'red' }}>
                          {newItemError}
                        </div>
                        <Button
                          variant='contained'
                          sx={{ backgroundColor: '#9155FD !important' }}
                          onClick={handleAddItems}
                        >
                          Add Item
                        </Button>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: 20 }}>
                          <span style={{ fontWeight: 'bold', marginRight: 10 }}>
                            Total Items:
                          </span>
                          {values.orderDetails.length}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        mb: 1,
                        mt: 2,
                        '@media (max-width: 899px)': {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                        '@media only screen and (min-width: 900px) and (max-width: 1200px) ':
                          {
                            display: 'flex',

                            flexDirection: 'column',
                          },
                      }}
                    >
                      <Paper
                        item={true}
                        xs={12}
                        md={12}
                        lg={4}
                        container
                        sx={{ mr: 3 }}
                      >
                        <Grid item={true} xs={6}>
                          <Typography>Grand Total :</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <TextField
                            placeholder='0'
                            variant='outlined'
                            fullWidth
                            size='small'
                            type='text'
                            value={
                              values.grandTotal && values.grandTotal !== '0'
                                ? values.grandTotal
                                : ''
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Paper>
                      <Paper
                        item={true}
                        xs={12}
                        md={12}
                        lg={4}
                        container
                        sx={{ mr: 3 }}
                      >
                        <Grid item={true} xs={6}>
                          <Typography>Discount On Total(%) :</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <TextField
                            placeholder='0'
                            variant='outlined'
                            fullWidth
                            size='small'
                            type='number'
                            {...getFieldProps('totalDiscount')}
                            error={Boolean(
                              touched.totalDiscount && errors.totalDiscount
                            )}
                            helperText={
                              touched.totalDiscount && errors.totalDiscount
                            }
                            onBlur={(e) => handleChangeDiscountedTotal(e)}
                          />
                        </Grid>
                      </Paper>
                      <Paper item={true} xs={12} md={12} lg={4} container>
                        <Grid item={true} xs={6}>
                          <Typography>Final Amount:</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <TextField
                            placeholder='0'
                            variant='outlined'
                            fullWidth
                            size='small'
                            type='text'
                            value={
                              values.finalAmount && values.finalAmount !== '0'
                                ? values.finalAmount
                                : ''
                            }
                            error={Boolean(
                              touched.finalAmount && errors.finalAmount
                            )}
                            helperText={
                              touched.finalAmount && errors.finalAmount
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Paper>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        mb: 1,
                        mt: 2,
                        '@media (max-width: 899px)': {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                        '@media only screen and (min-width: 900px) and (max-width: 1200px) ':
                          {
                            display: 'flex',

                            flexDirection: 'column',
                          },
                      }}
                    >
                      <Paper
                        item={true}
                        xs={12}
                        md={12}
                        lg={4}
                        container
                        sx={{ mr: 3 }}
                      >
                        <Grid item={true} xs={6}>
                          <Typography>Advance Payment :</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <TextField
                            placeholder='0'
                            variant='outlined'
                            fullWidth
                            size='small'
                            type='number'
                            {...getFieldProps('advancePayment')}
                            error={Boolean(
                              touched.advancePayment && errors.advancePayment
                            )}
                            helperText={
                              touched.advancePayment && errors.advancePayment
                            }
                          />
                        </Grid>
                      </Paper>
                      <Paper
                        item={true}
                        xs={12}
                        md={12}
                        lg={4}
                        container
                        sx={{ mr: 3 }}
                      >
                        <Grid item={true} xs={6}>
                          <Typography>Remaining Payment:</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <TextField
                            placeholder='0'
                            variant='outlined'
                            fullWidth
                            size='small'
                            type='text'
                            {...getFieldProps('remainingPayment')}
                            error={Boolean(
                              touched.remainingPayment &&
                                errors.remainingPayment
                            )}
                            helperText={
                              touched.remainingPayment &&
                              errors.remainingPayment
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Paper>
                      <Paper item={true} xs={12} md={12} lg={4} container>
                        <Grid item={true} xs={6}>
                          <Typography>Payment Mode :</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                          <CustomSelect
                            options={options}
                            value={formik.values.paymentMode}
                            onChange={(value: any) =>
                              formik.setFieldValue('paymentMode', value.value)
                            }
                          />
                        </Grid>
                      </Paper>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        '@media (max-width: 899px)': {
                          display: 'flex',
                          flexDirection: 'column',
                        },
                        '@media only screen and (min-width: 900px) and (max-width: 1200px) ':
                          {
                            flexDirection: 'column',
                          },
                        mb: 1,
                      }}
                    >
                      <Grid
                        item={true}
                        xs={12}
                        md={12}
                        lg={6}
                        container
                        alignItems={'center'}
                      >
                        <Grid item={true} xs={6} lg={4} alignItems={'center'}>
                          <Typography>Notes :</Typography>
                        </Grid>
                        <Grid item={true} xs={6} lg={8} alignItems={'center'}>
                          <TextareaElement
                            aria-label='Demo input'
                            minRows={3}
                            placeholder='Notes…'
                            {...getFieldProps('notes')}
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item={true}
                        xs={12}
                        md={12}
                        lg={6}
                        container
                        alignItems={'center'}
                      >
                        <Grid item xs={6} md={6} lg={3}>
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: '#9155FD !important',
                            }}
                            component='label'
                          >
                            Upload
                            <input
                              hidden
                              multiple
                              accept='image/*'
                              type='file'
                              onChange={handleImageUpload}
                            />
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={6} lg={7}>
                          {selectedImages.map((image: any, index: number) => (
                            <ImageContainer>
                              <CancelIcon
                                onClick={() => deleteImage(image, index)}
                                style={{
                                  position: 'absolute',
                                  zIndex: 1,
                                  right: '-15px',
                                  marginTop: '10px',
                                  color: '#7149C6',
                                  cursor: 'pointer',
                                }}
                              />
                              <img
                                key={index}
                                src={image.preview}
                                alt={`Selected Image ${index + 1}`}
                                style={{
                                  width: '140px',
                                  height: '100px',
                                  marginLeft: '20px',
                                  marginTop: '15px',
                                }}
                              />
                            </ImageContainer>
                          ))}
                        </Grid>
                      </Grid>
                    </Box>
                    <ButtonWrapper>
                      <Button
                        size='medium'
                        sx={{
                          placeSelf: 'center',
                          backgroundColor: '#9155FD !important',
                          marginRight: '8px',
                          width: '80px',
                        }}
                        variant='contained'
                        type='submit'
                      >
                        {isUpdateData ? (
                          <CircularProgress
                            color='inherit'
                            style={{
                              color: '#FFFFFF',
                              height: '25px',
                              width: '25px',
                            }}
                          />
                        ) : location.state ? (
                          'Update'
                        ) : (
                          'Save'
                        )}
                      </Button>
                      <Link to='/orders'>
                        <Button variant='outlined' sx={{ placeSelf: 'center' }}>
                          Cancel
                        </Button>
                      </Link>
                    </ButtonWrapper>
                    <Paper item={true} xs={12} md={4}>
                      <Grid item={true} xs={6}></Grid>
                    </Paper>
                  </ProfileWrapper>
                </Form>
              </FormikProvider>
              <Box>
                <PaymentHistory />
              </Box>
            </Box>
          )}
        </CardWrapper>
      </MainContainer>
    </>
  );
};

export default EditOrder;
