import {
  DELETE_ORDER_IMAGE_BEGIN,
  DELETE_ORDER_IMAGE_SUCCESS,
  DELETE_ORDER_IMAGE_FAILURE,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
deleteImageOrderData:[],
   error: null,
   isLoading: false,
 };
 
 const deleteOrderImageReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case DELETE_ORDER_IMAGE_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case DELETE_ORDER_IMAGE_SUCCESS:
       return { ...state, loading: false, deleteImageOrderData:action.payload};

     case DELETE_ORDER_IMAGE_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default deleteOrderImageReducer;