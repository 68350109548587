import {
  GET_EMPLOYEE_BEGIN,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAILURE,
  IS_LOADING
  } from '../constant';

const initialState: any = {
  loading: false,
  employeeData: [],
  error: null,
  isLoading: false
};

const employeesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EMPLOYEE_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_EMPLOYEE_SUCCESS:
      return { ...state, loading: false, employeeData: action.payload };
    case GET_EMPLOYEE_FAILURE:
      return { ...state, loading: false,error: action.payload };

    case IS_LOADING:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default employeesReducer;
