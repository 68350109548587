import {
  ADD_TECHNICIAN_BEGIN,
  ADD_TECHNICIAN_PAYMENT_BEGIN,
  DELETE_TECHNICIAN_BEGIN,
  DELETE_TECHNICIAN_PAYMENT_BEGIN,
  EDIT_TECHNICIAN_BEGIN,
  EDIT_TECHNICIAN_PAYMENT_BEGIN,
  EDIT_TECHNICIAN_STATUS_BEGIN,
  GET_TECHNICIAN_BEGIN,
  GET_TECHNICIAN_NAMES_BEGIN,
  IS_LOADING,
} from '../constant';

export const getTechnician = (payload: any) => ({
  type: GET_TECHNICIAN_BEGIN,
  payload,
});

export const addTechnician = (payload: any) => ({
  type: ADD_TECHNICIAN_BEGIN,
  payload,
});

export const editTechnician = (technician: any, id: any) => ({
  type: EDIT_TECHNICIAN_BEGIN,
  payload: { technician, id },
});

export const editTechnicianStatus = (payload: any) => ({
  type: EDIT_TECHNICIAN_STATUS_BEGIN,
  payload,
});

export const deleteTechnician = (payload: any) => ({
  type: DELETE_TECHNICIAN_BEGIN,
  payload,
});
export const isLoading = (payload: any) => ({
  type: IS_LOADING,
  payload,
});


export const getTechnicianName = () => ({
  type: GET_TECHNICIAN_NAMES_BEGIN,
});

export const addTechnicianPayment = (payload: any) => ({
  type: ADD_TECHNICIAN_PAYMENT_BEGIN,
  payload,
});

export const deleteTechnicianPayment=(payload:any)=>({
  type:DELETE_TECHNICIAN_PAYMENT_BEGIN,
  payload
})


export const editTechnicianPayment=(payload:any)=>({
  type:EDIT_TECHNICIAN_PAYMENT_BEGIN,
  payload
})


