import {
  DELETE_TECHNICIAN_PAYMENT_BEGIN,
  DELETE_TECHNICIAN_PAYMENT_SUCCESS,
  DELETE_TECHNICIAN_PAYMENT_FAILURE,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
deleteTechnicianPaymentData:[],
   error: null,
   isLoading: false,
 };
 
 const deleteTechnicianPaymentReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case DELETE_TECHNICIAN_PAYMENT_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case DELETE_TECHNICIAN_PAYMENT_SUCCESS:
       return { ...state, loading: false, deleteTechnicianPaymentData:action.payload};

     case DELETE_TECHNICIAN_PAYMENT_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default deleteTechnicianPaymentReducer;