import {
    GET_TECHNICIAN_BEGIN,
    GET_TECHNICIAN_SUCCESS,
    GET_TECHNICIAN_FAILURE,
    IS_LOADING
  } from '../constant';
  
  const initialState: any = {
    loading: false,
    technicianData: [],
    error: null,
    isLoading: false
  };
  
  const technicianReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case GET_TECHNICIAN_BEGIN:
        return {
          ...state,
          loading: true
        };
      case GET_TECHNICIAN_SUCCESS:
        return { ...state, loading: false, technicianData: action.payload };
      case GET_TECHNICIAN_FAILURE:
        return { ...state, error: action.payload };
  
      case IS_LOADING:
        return {
          ...state,
          loading: false,
          data: action.payload,
          isLoading: action.payload
        };
      default:
        return state;
    }
  };
  
  export default technicianReducer;
  