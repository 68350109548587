import {
  GET_SHOP_BEGIN,
  GET_SHOP_FAILURE,
  GET_SHOP_SUCCESS,
  IS_LOADING
} from '../constant';

const initialState: any = {
  loading: false,
  shopData: [],
  error: null,
  isLoading: false
};
const shopReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SHOP_BEGIN:
      return {
        ...state,
        loading: true
      };
    case GET_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        shopData: action.payload
      };
    case GET_SHOP_FAILURE:
      return { ...state, error: action.payload };
    case IS_LOADING:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isLoading: action.payload
      };
    default:
      return state;
  }
};

export default shopReducer;
