import { CREATE_MEASUREMENT_SUCCESS, CREATE_MEASUREMENT_FAILURE, GET_MEASUREMENT_SUCCESS, GET_MEASUREMENT_FAILURE, DELETE_MEASUREMENT_FAILURE, DELETE_MEASUREMENT_SUCCESS, EDIT_MEASUREMENT_SUCCESS, EDIT_MEASUREMENT_FAILURE} from './../constant';
import { call, put} from 'redux-saga/effects';
import { IS_LOADING } from '../constant';
import { addMeasurementApi, deleteMeasurementApi, editMeasurementApi, getMesurementDetail } from '../../services';
import { toast } from 'react-toastify';

export function* createMeasurementSaga(action: any): any {
  yield put({
    type: IS_LOADING,
    payload: true,
  });

  try {
    const response = yield call(addMeasurementApi, action.payload.payload);
    const MeasurementDataResponse=yield call(getMesurementDetail,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
    yield put({
      type: GET_MEASUREMENT_SUCCESS,
      payload: MeasurementDataResponse.data,
    });

    yield put({
      type: CREATE_MEASUREMENT_SUCCESS,
      payload: response.data.data,
    });

    yield put({
      type: IS_LOADING,
      payload: false,
    });
  } catch (error: any) {
    yield put({
      type: CREATE_MEASUREMENT_FAILURE,
      payload: error.response?.data?.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}



export function* getMeasurementSaga(action: any): any {
  try {
    const response = yield call(getMesurementDetail, action.payload);
    yield put({
      type: GET_MEASUREMENT_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_MEASUREMENT_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
}

export function* editMeasurementSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true,
    });
    const response = yield call(editMeasurementApi, action.payload);

    const MeasurementDataResponse=yield call(getMesurementDetail,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
    yield put({
      type: GET_MEASUREMENT_SUCCESS,
      payload: MeasurementDataResponse.data,
    });
    yield put({
      type: EDIT_MEASUREMENT_SUCCESS,
      payload: response?.data?.data,
    });

    toast(response.data.message)

    yield put({
      type: IS_LOADING,
      payload: false,
    });

  } catch (error: any) {
    yield put({
      type: EDIT_MEASUREMENT_FAILURE,
      payload: error?.response?.data?.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* deleteMeasurementSaga(action: any): any {
  try {
    const response = yield call(deleteMeasurementApi, action.payload.payload);
    const MeasurementDataResponse=yield call(getMesurementDetail,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
    yield put({
      type: GET_MEASUREMENT_SUCCESS,
      payload: MeasurementDataResponse.data,
    });
    yield put({
      type: DELETE_MEASUREMENT_SUCCESS,
    });
    toast(response.data.message);
  } catch (error: any) {
    yield put({
      type: DELETE_MEASUREMENT_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
}
