import React from 'react';
import { Provider } from 'react-redux';
import * as ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import App from './App';
import store, { persistor } from './store';
import Toaster from './components/Toaster';
import { CustomRouter, history } from './routes';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <CustomRouter history={history}>
        <App />
        <Toaster />
      </CustomRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
