import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    height: '50%',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3, 3),

    border: 'none',
  },
  '& .MuiDialogActions-root': {

    padding: theme.spacing(2, 2),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
export interface ConfirmationModalProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  handleClickYes: (e: any, item: any) => void;
  dropdownModalValues?: { e: any; item: any };
  loading?:boolean;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
      }}
      {...other}
      style={{ outline: 'none' }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const DropdownModal = ({
  title,
  isOpen,
  handleClose,
  handleClickYes,
  dropdownModalValues,
  loading
}: ConfirmationModalProps) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Fragment>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        open={isOpen}
      >
        <Box sx={{ width: 450 }}>
          <StyledDialogTitle
            id='confirmation-dialog-title'
            onClose={handleClose}
          >
            <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}></Typography>
          </StyledDialogTitle>
          <DialogContent dividers>
            <Typography
              gutterBottom
              sx={{
                fontSize: 18,
                fontWeight: 'bold',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {title}
            </Typography>
          </DialogContent>
          <DialogActions
            style={{
              margin: 'auto',
              outline: 'none',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            
            {!loading?(
            <Button
              sx={{ fontSize: 15, fontWeight: 'bold' }}
              variant='contained'
              autoFocus
              style={{ backgroundColor: '#9155FD' }}
              onClick={() =>
                handleClickYes(
                  dropdownModalValues?.e,
                  dropdownModalValues?.item
                )
              }
            >
              OK
            </Button>
            ):(
              <Button size='small' type='submit'>
              <CircularProgress
                style={{
                  color: '#9155FD',
                  height: '25px',
                  width: '25px'
                }}
              />
            </Button> 
            )}
            <Button
              variant='outlined'
              sx={{ fontSize: 15, fontWeight: 'bold' }}
              style={{ borderColor: '#9155FD', color: '#9155FD' }}
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </StyledDialog>
    </Fragment>
  );
};
export default DropdownModal;
