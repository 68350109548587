import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  InputLabel,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  ListSubheader,
  Table,
  TableContainer,
  FormControl,
  InputAdornment,
  styled,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import DatePickers from '../../../../components/DatePickers';
import { useSelector } from 'react-redux';

const Headers = [
  'Technician Name',
  'Description',
  'Amount',
  'Delivery Date',
  'Advance Payment',
  'Remaning Payment',
  '',
];

const TableHeader = styled(TableHead)({
  '& .MuiTableCell-root': {
    backgroundColor: 'rgb(249, 250, 252)',
  },
  whiteSpace: 'nowrap'
});

const TableText = styled(TableCell)({
  fontWeight: 'bold',
  padding: '10px 4px !important',
  color: 'rgba(58, 53, 65, 0.87)',
  fontSize: '16px',
});

const TableItemHeader = styled(TableCell)({
  padding: '20px 5px 15px 10px !important',
});

const TableDataBox=styled(Box)({
  display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      position: 'absolute',
      alignItems: 'center',
      height: '60%',
      width: '-webkit-fill-available',
});

const AddExpenseButton=styled(Button)({
  backgroundColor: '#9155FD !important',
  marginTop: '8px !important',
  marginLeft: '8px !important'
});


const AddExpensesTable = ({
  items,
  handleChange,
  handleAddItems,
  editedData,
  isEdit,
}: any) => {
  const [technicianData, setTechnicianData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [searchExpenseData, setSearchExpenseData] = useState([]);
  const [searchTechnicianData, setSearchTechnicianData] = useState([]);

 
  const { values, setFieldValue }: any = useFormikContext();

  const technicianNames = useSelector((state: any) => state?.technicianName);
  const expanseItems = useSelector((state: any) => state?.expense);
  const expenseOption = useSelector((state: any) => state?.expenseOption);
  const expenseDataLoader = useSelector((state: any) => state?.expense?.loading);

  const containsText = (text: any, searchText: any) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  useEffect(() => {
    if (!technicianNames.loading && technicianNames?.techniciannames?.length > 0) {
      setTechnicianData(technicianNames?.techniciannames);
    }
  }, [!technicianNames.loading && technicianNames?.techniciannames?.length > 0]);

  useEffect(() => {
    if (!expenseOption.loading && expenseOption?.expenseOption?.length > 0) {
      setExpenseData(expenseOption?.expenseOption);
    }
  }, [!expenseOption.loading && expenseOption?.expenseOption?.length > 0]);

  const handleRemoveItem = (currentIndex: number) => {
    const removeItem = values?.expenseDetail?.filter(
      (item: any, index: number) => index !== currentIndex
    );
    setFieldValue('expenseDetail', removeItem);
  };

  useEffect(() => {
    if (isEdit) {
      values.expenseDetail.map((item: any, index: number) => {
        setFieldValue(`expenseDetail.${index}.${'amount'}`, editedData.amount);
        setFieldValue(
          `expenseDetail.${index}.${'description'}`,
          editedData.description
        );
        setFieldValue(
          `expenseDetail.${index}.${'technicianName'}`,
          editedData.technicianName
        );
        setFieldValue(
          `expenseDetail.${index}.${'technicianId'}`,
          editedData.technicianId
        );
        setFieldValue(
          `expenseDetail.${index}.${'deliveryDate'}`,
          editedData.deliveryDate
        );
        setFieldValue(
          `expenseDetail.${index}.${'remainingPayment'}`,
          editedData.remainingPayment
        );
        setFieldValue(
          `expenseDetail.${index}.${'advancePayment'}`,
          editedData.advancePayment
        );
      });
    }
    else {
      values.expenseDetail.map((item: any, index: number) => {
        setFieldValue(`expenseDetail.${index}.${'amount'}`, editedData.amount);
        setFieldValue(
          `expenseDetail.${index}.${'description'}`,
          ''
        );
        setFieldValue(
          `expenseDetail.${index}.${'advancePayment'}`,
          ''
        );
        setFieldValue(
          `expenseDetail.${index}.${'technicianName'}`,
          ''
        );
        setFieldValue(
          `expenseDetail.${index}.${'technicianId'}`,
          ''
        );
        setFieldValue(
          `expenseDetail.${index}.${'deliveryDate'}`,
          null
        );
        setFieldValue(
          `expenseDetail.${index}.${'remainingPayment'}`,
          ''
        );
        setFieldValue(
          `expenseDetail.${index}.${'amount'}`,
          ''
        );
      });
    }
  }, [isEdit]);

  const getFormikStates = (
    field: string,
    index: number,
    type: string = 'string'
  ) => {
    return {
      value: items[index][field],
      onChange: (e: any) => {
        let value = e?.target?.value;
        if (type === 'date') {
          value = new Date(e).toString();
        }
        handleChange(`expenseDetail.${index}.${field}`)(value);
        if (field === 'advancePayment') {
          const remainingPayment = items[index]['amount'] - value;
          setFieldValue(
            `expenseDetail.${index}.${'remainingPayment'}`,
            remainingPayment
          );
        }
        if (field === 'amount') {
          const remainingPayment = value-items[index]['advancePayment'];
          setFieldValue(
            `expenseDetail.${index}.${'remainingPayment'}`,
            remainingPayment
          );
        }
        if (field === 'searchTechnician') {
          if (value.length) {
            let data = technicianData.filter((option: any) =>
              containsText(option.technicianName, value)
            );
            setSearchTechnicianData(data);
          } else {
            setTechnicianData(technicianNames?.data);
          }
        }
        if (field === 'searchExpense') {
          if (value.length) {
            let data = expenseData.filter((option: any) =>
              containsText(option, value)
            );
            setSearchExpenseData(data);
          } else {
            setExpenseData(expenseOption?.data);
          }
        }
        if (field === 'technicianId') {
          if (!technicianNames.loading && technicianNames?.techniciannames?.length > 0) {
            let obj: any | undefined = {};
            obj = technicianData.find((e: any) => e?._id === value);
            setFieldValue(
              `expenseDetail.${index}.${'technicianName'}`,
              obj.technicianName
            );
          }
        }
      },
    };
  };

  return (
    <Box sx={{ mt: 1 }}>
      <TableContainer sx={{ maxHeight: 350 }}>
        <Table stickyHeader>
          <TableHeader>
            <TableRow>
              {Headers.map((item, index: number) => (
                <TableText align='center' key={index} >
                  {item}
                </TableText>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {!expenseDataLoader ? (
              expanseItems?.length === 0 ? (
                <TableDataBox></TableDataBox>
              ) : (
                ''
              )
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  position: 'absolute',
                  alignItems: 'center',
                  height: '60%',
                  width: '-webkit-fill-available',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            {!expenseDataLoader &&
              !technicianNames.loading &&
              !expenseOption.loading ? (
              items &&
              items?.map((item: any, index: number) => (
                <TableRow key={index} sx={{ verticalAlign: 'top' }}>
                  <TableItemHeader
                    style={{ minWidth: '100px', paddingInline: '4px' }}
                   
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        style={{ marginTop: -8 }}
                        id="demo-simple-select-autowidth-label"
                      >
                        Name
                      </InputLabel>
                      <Select
                        autoWidth={true}
                        style={{
                          height: 40,
                        }}
                        disabled={isEdit ? true : false}
                        MenuProps={{ autoFocus: false }}
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        label="Name"
                        {...getFormikStates('technicianId', index)}
                        onClose={() =>
                          setFieldValue(
                            `expenseDetail.${index}.${'searchTechnician'}`,
                            ''
                          )
                        }
                      >
                        <ListSubheader>
                          <TextField
                            sx={{
                              width: { sm: 200, md: 300 },
                            }}
                            size="small"
                            autoFocus
                            placeholder="Type to search..."
                            {...getFormikStates('searchTechnician', index)}
                            onKeyDown={(e) => {
                              if (e.key !== 'Escape') {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {item.searchTechnician
                          ? searchTechnicianData.map((option: any, i: any) => (
                            <MenuItem key={i} value={option._id}>
                              {option.technicianName}
                            </MenuItem>
                          ))
                          : technicianData?.map((option: any, i: any) => (
                            <MenuItem key={i} value={option._id}>
                              {option.technicianName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableItemHeader>
                  <TableItemHeader
                    style={{ minWidth: '100px' }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        style={{
                          marginTop: -8,
                        }}
                        id="search-select-label"
                      >
                        Description
                      </InputLabel>
                      <Select
                        style={{
                          height: 40,
                        }}
                        autoWidth={true}
                        defaultValue="sfsdf"
                        size="small"
                        disabled={isEdit ? true : false}
                        MenuProps={{ autoFocus: false }}
                        label="Description"
                        id="search-select"
                        onClose={() =>
                          setFieldValue(
                            `expenseDetail.${index}.${'searchExpense'}`,
                            ''
                          )
                        }
                        {...getFormikStates('description', index)}
                      >
                        <ListSubheader>
                          <TextField
                            sx={{
                              width: { sm: 150, md: 250 },
                            }}
                            size="small"
                            autoFocus
                            placeholder="Type to search..."
                            {...getFormikStates('searchExpense', index)}
                            onKeyDown={(e) => {
                              if (e.key !== 'Escape') {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {item.searchExpense
                          ? searchExpenseData.map((option: any, i: any) => (
                            <MenuItem key={i} value={option}>
                              {option}
                            </MenuItem>
                          ))
                          : expenseData?.map((option: any, i: any) => (
                            <MenuItem key={i} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </TableItemHeader>
                  <TableItemHeader width={120} >
                    <TextField
                      size="small"
                      type="number"
                      placeholder="0"
                      {...getFormikStates('amount', index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₹</InputAdornment>
                        ),
                      }}
                    />
                  </TableItemHeader>
                  <TableItemHeader width={170}>
                    <DatePickers
                      {...getFormikStates('deliveryDate', index, 'date')}
                    />
                  </TableItemHeader>
                  <TableItemHeader width={120}>
                    <TextField
                      size="small"
                      type="number"
                      placeholder="0"
                      {...getFormikStates('advancePayment', index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₹</InputAdornment>
                        ),
                      }}
                    />
                  </TableItemHeader>
                  <TableItemHeader width={150} >
                    <TextField
                      size="small"
                      placeholder="0"
                      type="number"
                      {...getFormikStates('remainingPayment', index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">₹</InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </TableItemHeader>
                  {values.expenseDetail.length > 1 ? (
                    <TableItemHeader align='center' width={1} >
                      <IconButton>
                        <DeleteOutlineOutlinedIcon
                          onClick={() => handleRemoveItem(index)}
                        />
                      </IconButton>
                    </TableItemHeader>
                  ) : (
                    ''
                  )}
                </TableRow>
              ))
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  position: 'absolute',
                  alignItems: 'center',
                  height: '60%',
                  width: '-webkit-fill-available',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isEdit &&
        <AddExpenseButton
          variant="contained"
      
          onClick={handleAddItems}
        >
          Add Expense
        </AddExpenseButton>}
    </Box>
  );
};

export default AddExpensesTable;
