import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { Avatar, CircularProgress } from '@mui/material';
import { Box, TableContainer, Paper } from '@mui/material';
import ShopAdminModal from './AdminDetailModal';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteButton from '../../components/DeleteButton';
import EditButton from '../../components/EditButton';
import DefaultAvatar from '../../assets/images/avtar.png';
import DropdownModal from '../../components/ConfirmationModal';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import NoDataImage from '../../components/NoDataImage';
import { editShopStatusAction } from '../../store/shop/action';
import { useDispatch } from 'react-redux';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
  '@media (max-width: 820px)': {
    width: '100%',
  },
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  background: "rgb(249, 250, 252)",
  color: "rgba(58, 53, 65, 0.87)",
  fontWeight: "bold",
  fontSize: "16px",
  textWrap: "nowrap",
}));

const TableDataWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  position: "absolute",
  alignItems: "center",
  height: "60%",
  width: "-webkit-fill-available",
});

const options = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'InActive' },
];

const ShopListTable = ({
  shopListData,
  shopHeader,
  handleClose,
  handleOpenDeleteModal,
  handleDeleteModalYes,
  showDeleteModal,
  deleteShopLoading
}: any) => {
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [shopAdminData, setShopAdminData] = useState<any>([]);
  const [dropdownValues, setDropdownValues] = useState<any>({});
  const [showDropdownConfirmation, setShowDropdownConfirmation] =
    useState(false);
  const [dropdownConfirmationItemId, setDropdownConfirmationItemId] =
    useState("");
  const [dropdownConfirmationValue, setDropdownConfirmationValue] =
    useState("");
  const [dropdownModalValues, setDropdownModalValues] = useState<any>({
    e: "",
    item: "",
  });

  const navigate = useNavigate();
  const searchData = useSelector((state: any) => state.shop);
  const searchLoading = useSelector((state: any) => state?.shop.loading);
  const shopListLoader = useSelector((state: any) => state?.shop.loading);

  const dispatch = useDispatch();

  
  const editShopStatusLoader = useSelector(
    (state: any) => state.editShopStatus.loading
  );

  useEffect(() => {
    if (!editShopStatusLoader) {
      setShowDropdownConfirmation(false);
    }
  }, [!editShopStatusLoader]);



  const handleCloseDropdownConfirmationModal = () => {
    setDropdownConfirmationItemId('');
    setDropdownConfirmationValue('');
    setShowDropdownConfirmation(false);
  };

  const handleOpenViewModal = (item: any) => {
    setShopAdminData(item?.shopAdmin);
    setShowViewModal(true);
  };

  const handleCloseModal = () => {
    setShowViewModal(false);
  };
  const onClose = () => {
    setShowViewModal(false);
  };

  const switchHandler = (event: any, item: any) => {
    setShowDropdownConfirmation(true);

    const updatedValues = { ...dropdownValues, [item._id]: event.target.value };
    setDropdownValues(updatedValues);
    dispatch(
      editShopStatusAction({ status: event.target.value,shopId:item._id })
    );
  };

  const handleDropdownConfirmationAction = (e: any, item: any) => {
    setDropdownModalValues({ e, item });
    setShowDropdownConfirmation(true);
  };

  const navigateToEdit = (data: any) => {
    navigate(`/shop/edit/${data?._id}`, { state: data });
  };

  return (
    <>
      <Paper sx={{ overflow: "hidden", mt: 2 }}>
        <StyledTableContainer>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow sx={{ align: "center" }}>
                {shopHeader?.map((item: string, index: number) => (
                  <StyledTableHeadCell key={index} align="center">
                    {item}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!searchLoading ? (
                searchData?.data?.data?.length === 0 ? (
                  <NoDataImage />
                ) : (
                  ""
                )
              ) : (
                <TableDataWrapper>
                  <CircularProgress color="inherit" />
                </TableDataWrapper>
              )}
              {!shopListLoader && !searchData.loading ? (
                shopListData?.map((item: any, index: number) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <Avatar
                        src={item.shopLogo || DefaultAvatar}
                        alt="profile-pic"
                      ></Avatar>
                    </TableCell>
                    <TableCell align="center">{item.shopName}</TableCell>
                    <TableCell align="center">{item.shopEmail}</TableCell>
                    <TableCell align="center">{item.shopCity}</TableCell>
                    <TableCell align="center">{item.shopAddress}</TableCell>
                    <TableCell align="center">{item.shopNumber}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ align: "center", textAlign: "center" }}
                    >
                      <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          sx={{ align: "center",minWidth:'130px' }}
                          value={dropdownValues[item._id] || item.status}
                          label="status"
                          onChange={(e) => {
                            handleDropdownConfirmationAction(e, item);
                          }}
                        >
                          {options.map((data: any, index: any) => (
                            <MenuItem value={data.value} key={index}>
                              {data.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", alignItems: "center" }}
                    >
                      <EditButton navigateToEdit={() => navigateToEdit(item)} />
                      <DeleteButton
                        handleOpenDeleteModal={() =>
                          handleOpenDeleteModal(item._id)
                        }
                      />
                      <VisibilityIcon
                        sx={{ pl: "6px" }}
                        onClick={() => handleOpenViewModal(item)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableDataWrapper />
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
      <DropdownModal
      loading={editShopStatusLoader}
        title=" Are you sure you want to change the status?"
        isOpen={showDropdownConfirmation}
        handleClose={handleCloseDropdownConfirmationModal}
        handleClickYes={switchHandler}
        dropdownModalValues={dropdownModalValues}
      />
      <DropdownModal
      loading={deleteShopLoading}
        title=" Are you sure you want to Delete ?"
        isOpen={showDeleteModal}
        handleClose={handleClose}
        handleClickYes={handleDeleteModalYes}
      />
      <ShopAdminModal
        isOpen={showViewModal}
        handleClose={handleCloseModal}
        shopAdminData={shopAdminData}
        onClose={onClose}
      />
    </>
  );
};

export default ShopListTable;
