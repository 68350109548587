import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Typography,
  Box,
  TableBody,
  TableCell,
  TableRow,
  styled,
  TableContainer,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { Tabs, tableCellClasses } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';


const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #ffffff !important',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  width: '600px',
};
const TableHeadList = styled(TableHead)({
  whiteSpace: 'nowrap',
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4F5FA',
    color: 'black',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export interface ConfirmationModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  onClose: any;
  ModalTechnicianData: any;
}

const TechnicianModal = ({
  isOpen,
  handleClose,
  onClose,
  ModalTechnicianData,
}: 
ConfirmationModalProps) => {
  if (!isOpen) {
    return null;
  }

  const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box padding={'0 24px 24px 24px'}>{children}</Box>}
      </div>
    );
  };

  const MyTabs = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event: any, newValue: any) => {
      setValue(newValue);
    };
    
    return (
      <div>
        <Box sx={style}>
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              padding: '20px',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant='h5'
            sx={{ color: '#9155FD', marginLeft: '20px ', marginBottom: '8px' }}
          >
            Technician Expenses:
          </Typography>
          <TabPanel sx={{ padding: '0 !important' }} value={value} index={0}>
            <div>
              <Tabs
                sx={{ margin: '2px', padding: '0px', fontWeight: 'bold' }}
                value={value}
                onChange={handleChange}
                centered
              >
              </Tabs>
              <TableContainer sx={{ maxHeight: 300 , overflowX: 'auto'}}>

              <Table
                sx={{ minWidth: 650, }}
                stickyHeader
                aria-label='simple table'
              >
                <TableHeadList>
                  <TableRow>
                    <StyledTableCell align='center'>order Id</StyledTableCell>
                    <StyledTableCell align='center'>description</StyledTableCell>
                    <StyledTableCell align='center'>amount</StyledTableCell>
                    <StyledTableCell align='center'>
                      remaining Payment
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      advance Payment
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      delivery Date
                    </StyledTableCell>
                  </TableRow>
                </TableHeadList>
                <TableBody>
                  {ModalTechnicianData?.expenses?.map((item: any) => (
                    <TableRow
                      key={item._id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell align='center' component='th' scope='row'>
                        {item.orderId}
                      </TableCell>
                      <TableCell align='center' component='th' scope='row'>
                        {item.desription}
                      </TableCell>
                      <TableCell align='center'>{item.amount}</TableCell>
                      <TableCell align='center'>
                        {item.remainingPayment}
                      </TableCell>
                      <TableCell align='center'>
                        {item.advancePayment}
                      </TableCell>
                      <TableCell align='center'>
                      {item.deliveryDate===null?'-':
                          dayjs(item?.deliveryDate).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </TableContainer>
            </div>
          </TabPanel>
        </Box>
      </div>
    );
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <MyTabs />
      </Modal>
    </>
  );
};
export default TechnicianModal;
