import {
  DELETE_TECHNICIAN_BEGIN,
  DELETE_TECHNICIAN_SUCCESS,
  DELETE_TECHNICIAN_FAILURE,
   IS_LOADING
 } from '../constant';
 
 const initialState: any = {
   loading: false,
deleteTechnicianData:[],
   error: null,
   isLoading: false,
 };
 
 const deleteTechnicianReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case DELETE_TECHNICIAN_BEGIN:
       return {
         ...state,
         loading: true
       };
       
     case DELETE_TECHNICIAN_SUCCESS:
       return { ...state, loading: false, deleteTechnicianData:action.payload};

     case DELETE_TECHNICIAN_FAILURE:
       return { ...state, error: action.payload };
     case IS_LOADING:
       return {
         ...state,
         loading: false,
         isLoading: action.payload,
       };
       
     default:
       return state;
   }
 };
 
 export default deleteTechnicianReducer;