import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useFormik, FormikProvider } from 'formik';
import {
  Box,
  Card,
  TextField,
  Typography,
  Button,
  Stack,
  Grid,
} from '@mui/material';
import Sidebar from '../../components/Sidebar';
import { AddEditUserValidationSchema } from '../../utils/validations';
import DefaultAvatar from '../../assets/images/avtar.png';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { editEmployee, addEmployee } from '../../store/employee/action';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { deleteImageAction } from '../../store/image/action';
import DropdownModal from '../../components/ConfirmationModal';

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});
const BackButtonWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const Orderheading = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA',
});

const StyledTableContainer = styled(Card)(({ theme }) => ({
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: theme.spacing(1),
  marginTop: '16px',
  height: '77vh',
  overflowY: 'auto !important' as 'auto',
  padding: theme.spacing(3),
}));

const CardWrapper = styled(Box)({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: '77vh',
});

const Image = styled('img')(({ theme }) => ({
  height: 120,
  width: 120,
  borderRadius: theme.spacing(1),
  border: '1px solid lightgray',
  objectFit: 'contain',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  //  width: '10%',
  width: '135px',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  position: 'relative',
}));

const ButtonWrapper = styled(Box)({
  display: 'flex',
  paddingBottom: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '10px',
});

const AddEditUser = () => {
  const [profileImage, setProfileImage] = useState<any>({
    file: null,
    preview: '',
  });

  const [showImageModal,setShowImageModal]=useState(false)
const [showImage,setShowImage]=useState(false)
  const auth = useSelector((state: any) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoader = useSelector((state: any) => state?.users.isLoading);
  const deleteImageModal = useSelector((state: any) => state.deleteImage?.loading);

  
  const formik = useFormik({
    initialValues: {
      profilePic: profileImage.preview,
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      address: '',
      city: '',
    },
    validationSchema: AddEditUserValidationSchema,
    onSubmit: async (values) => {
      const form = new FormData();
      if (profileImage.file) {
        form.append('profilePic', profileImage.file);
      }
      form.append('firstName', values.firstName);
      form.append('lastName', values.lastName);
      form.append('email', values.email);
      form.append('mobileNumber', values.mobileNumber);
      form.append('address', values.address);
      form.append('city', values.city);
      if (location.state) {
        dispatch(editEmployee(form, location.state._id));
        } else {
        dispatch(addEmployee({ payload: form, id: auth.data._id }));
      }
    },
  });

  const { touched, errors, handleSubmit, getFieldProps, setFieldValue } =
    formik;

 const hadleCloseImage=()=>{
  setShowImageModal(false)
 }  

  const handleDeleteModalYes=()=>{
  dispatch(deleteImageAction({deleteId:location.state._id}));
  setProfileImage({ file: null, preview: '' })
 }

 const removeImage=()=>{
  if(!showImage){
    setShowImageModal(true)
     }else{
    setShowImageModal(false)
    setProfileImage({ file: null, preview: '' })
  }
  }

  const handleProfileImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      const preview = URL.createObjectURL(file!);
      setProfileImage({ file, preview });
      setFieldValue('profilePicture', preview);
    }
    setShowImage(true)
  };


  useEffect(() => {
    if (location.state) {
      const { state } = location;
      setProfileImage({file:null,preview:state.profilePic})
      setFieldValue('profilePic', state.profilePic);
      setFieldValue('firstName', state.firstName);
      setFieldValue('lastName', state.lastName);
      setFieldValue('email', state.email);
      setFieldValue('mobileNumber', state.mobileNumber);
      setFieldValue('address', state.address);
      setFieldValue('city', state.city);
    }
  }, []);

  useEffect(() => {
   if(!deleteImageModal){
setShowImageModal(false)
   }
  }, [!deleteImageModal]);



    return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Orderheading>
          <BackButtonWrapper>
            <Link to="/users">
              <ArrowBackRoundedIcon
                sx={{ fontSize: 30, marginRight: '20px', color: 'black' }}
              />
            </Link>
          </BackButtonWrapper>
          <Typography variant="h5" sx={{ color: '#9155FD', fontWeight: 600 }}>
            {location.state ? 'Edit Employee' : 'Add Employee'}
          </Typography>
        </Orderheading>
        <StyledTableContainer>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <CardWrapper>
                <Box>
                  <ImageContainer>
                    {profileImage.preview&&                 
                    <CancelIcon
                      onClick={removeImage}
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        right: '2px',
                        marginTop: '-7px',
                        color: '#7149C6',
                        cursor: 'pointer',
                      }}
                    />}
                    <Image
                      src={
                        profileImage.preview
                          ? profileImage.preview
                          : DefaultAvatar
                      }
                    />
                    <Stack direction="row" gap={2} mt={2}>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: '#9155FD !important' }}
                        component="label"
                      >
                        Upload
                        <input
                          hidden
                          type="file"
                          onChange={handleProfileImageUpload}
                        />
                      </Button>
                    </Stack>
                  </ImageContainer>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ sm: 2, md: 5 }}
                  >
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={3} alignSelf="center">
                        <Typography>First Name :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="John"
                          variant="outlined"
                          {...getFieldProps('firstName')}
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={3} alignSelf="center">
                        <Typography>Last Name :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Doe"
                          variant="outlined"
                          {...getFieldProps('lastName')}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={3} alignSelf="center">
                        <Typography>Email :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="Eg.abc@gmail.com"
                          variant="outlined"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={3} alignSelf="center">
                        <Typography>Phone :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="9999999999"
                          type="number"
                          variant="outlined"
                          inputProps={{ maxLength: 10 }}
                          {...getFieldProps('mobileNumber')}
                          error={Boolean(
                            touched.mobileNumber && errors.mobileNumber
                          )}
                          helperText={
                            touched.mobileNumber && errors.mobileNumber
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={3}>
                        <Typography>City :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Ahmedabad"
                          variant="outlined"
                          {...getFieldProps('city')}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} mb={2}>
                      <Grid item xs={3} alignSelf="center">
                        <Typography>Address :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          placeholder="Enter Address"
                          variant="outlined"
                          multiline
                          minRows={3}
                          maxRows={4}
                          fullWidth
                          {...getFieldProps('address')}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <ButtonWrapper>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#9155FD !important',
                      width: '80px',
                    }}
                    type="submit"
                  >
                    {isLoader ? (
                      <CircularProgress size={23} color="inherit" />
                    ) : location.state ? (
                      'Update'
                    ) : (
                      'Submit'
                    )}
                  </Button>
                  <Link to="/users">
                    <Button variant="outlined">Cancel</Button>
                  </Link>
                </ButtonWrapper>
              </CardWrapper>
            </Form>
          </FormikProvider>
        </StyledTableContainer>
      </WrapperItem>
      <DropdownModal
        loading={deleteImageModal}
        title="Are you sure you want to delete image?"
        isOpen={showImageModal}
        handleClose={hadleCloseImage}
        handleClickYes={handleDeleteModalYes}
      />
    </Container>
  );
};

export default AddEditUser;
