import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import styled from '@emotion/styled';

const CardDesign = styled(Box)({
  alignItems: 'center',
  borderRadius: '6px',
  marginTop: '30px',
  backgroundColor: '#7149C6 !important',
  color: 'white',
  padding: '10px',
  boxShadow: '0 5px 8px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  justifyContent: 'space-between',
});
const CardTypo = styled(Typography)({
  textAlign: 'center',
  font: 'bold',
  fontWeight:600 ,
  fontSize: 22,
});
const ContentTypo = styled(Typography)({
  font: 'bold',
  fontSize: 18,
});
const ContentCard = styled(Box)({
  backgroundColor:'white',
  borderRadius:'100px',
  padding:'6px'
});


const DashboardCard: React.FC<DashboardCardProps> = ({ image, count, type,onClick }) => {
  return (
    <CardDesign onClick={onClick} style={{cursor:'pointer'}}>
      <ContentCard>
        <CardMedia
          component="img"
          height="30"
          width="30"
          sx={{alignItems:'center',filter: 'invert(40%)', width: 'max-content' }}
          image={String(image)}
          alt=""
        />
      </ContentCard>
      <Box>
        <CardTypo>{count}</CardTypo>
        <ContentTypo> {type} </ContentTypo>
      </Box>
    </CardDesign>
  );
};

interface DashboardCardProps {
  image?: string | React.ReactNode;
  count?: number;
  type?: string | undefined;
  onClick?:any
}

export default DashboardCard;