import { ADD_EXPENSES_BEGIN, DELETE_ORDER_EXPENSE_BEGIN, EXPENSE_STATUS_BEGIN, GET_EXPENSES_BEGIN, GET_EXPENSE_LIST_BEGIN, GET_EXPENSE_OPTION_BEGIN } from '../constant';


export const getExpenses = (payload: any) => ({
  type: GET_EXPENSES_BEGIN,
  payload,
});

export const addExpenses = (payload: any) => ({
  type: ADD_EXPENSES_BEGIN,
  payload,
});

export const getExpenseOption = () => ({
  type: GET_EXPENSE_OPTION_BEGIN,
});

export const getExpenseList = (payload: any) => ({
  type: GET_EXPENSE_LIST_BEGIN,
  payload,
});

export const expenseStatusChange = (payload: any) => ({
  type:EXPENSE_STATUS_BEGIN ,
  payload,
});

export const deleteOrderExpense=(payload:any)=>({
  type:DELETE_ORDER_EXPENSE_BEGIN,
  payload
})
